import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import { AddCustomerFNC, UpdateCustomerDetailsFNC } from '../Api';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';

import { Button, Modal, Form } from 'react-bootstrap';
var timer

class CreateNewCustomalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerCode: '',
      CustomerComponyName: '',
      Tel: '',
      AttnName: '',
      CompanyAddress: '',
      CompanyAddress2: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      CustomerCodeLine: false,
      CustomerNameLine: false,
      TelLine: false,
      CompanyAddressLine: false,
      PaymentLine: false,
      AttnNameLine: false,
      SelectedID: '',
      CreatedBy: '',
      CreatedDate: '',
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditCustomer') {
      // console.log('CustomerDetails', this.props.CustomerDetails)
      this.setState({ 
        CustomerCode: this.props.CustomerDetails.CustomerCode,
        CustomerComponyName: this.props.CustomerDetails.CustomerComponyName,
        Tel: this.props.CustomerDetails.Tel,
        CompanyAddress: this.props.CustomerDetails.CompanyAddress,
        AttnName: this.props.CustomerDetails.AttnName,
        PaymentTerms: this.props.CustomerDetails.PaymentTerms,
        SelectedID: this.props.CustomerDetails.ID,
        CreatedBy: this.props.CustomerDetails.Createdby,
        CreatedDate: this.props.CustomerDetails.CreatedDate,
      })
    }
    // console.log('CreatedDate', this.state.CreatedDate)
  }

  async CreateUser(Type) {
    const CustomerCode = this.state.CustomerCode
    const CustomerComponyName = this.state.CustomerComponyName
    const Tel = this.state.Tel
    const AttnName = this.state.AttnName
    const CompanyAddress = `${this.state.CompanyAddress} ${this.state.CompanyAddress2}`
    const PaymentTerms = this.state.PaymentTerms

    if (CustomerCode === '') {
      this.DisplayErrorMEssage('Please Insert Customer Code', 'CustomerCodeLine')
    }  else if (CustomerComponyName === '') {
      this.DisplayErrorMEssage('Please Insert Company Name', 'CustomerNameLine')
    } else if (Tel.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Tel', 'TelLine')
    } else if (AttnName.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Annt Name', 'AttnNameLine')
    } else if (CompanyAddress.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert CompanyAddress', 'CompanyAddressLine')
    } else if (PaymentTerms.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert PaymentTerms', 'PaymentLine')
    } else {
      if (Type === 'EditCustomer') {
        let FinalPassValue = {
          CustomerCode: CustomerCode,
          CompanyName: CustomerComponyName,
          Tel: Tel,
          AttnName: AttnName,
          CompanyAddress: CompanyAddress,
          PaymentTerms: PaymentTerms,
        }
        const UpdateCustomer = await UpdateCustomerDetailsFNC([FinalPassValue], this.state.SelectedID)
        if (UpdateCustomer.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Update Customer ${CustomerComponyName}`)
        }
      } else {
        let FinalPassValue = {
          CustomerCode: CustomerCode,
          CompanyName: CustomerComponyName,
          Tel: Tel,
          AttnName: AttnName,
          CompanyAddress: CompanyAddress,
          PaymentTerms: PaymentTerms,
          Createdby: this.props.username,
          CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        }
    
        const AddCustomer = await AddCustomerFNC([FinalPassValue]);
        if (AddCustomer.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Customer ${CustomerComponyName}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 3000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
      this.props.CallApi();
    }, 2000);
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayCusterAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditCustomer') ? `✎ Edit Customer` : '👥 Create New Customer'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
            }
            {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ marginBottom: 10, color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
            } */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Customer Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.CustomerCodeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CustomerCode} onChange={(e) => this.setState({ CustomerCode: e.target.value })} placeholder='Customer Code'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Customer Company Name</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.CustomerNameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CustomerComponyName} onChange={(e) => this.setState({ CustomerComponyName: e.target.value })} placeholder='Company Name'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Tel</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.TelLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Tel} onChange={(e) => this.setState({ Tel: e.target.value })} placeholder='Tel'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Attn Name</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.AttnNameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.AttnName} onChange={(e) => this.setState({ AttnName: e.target.value })} placeholder='Attn Name'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Company Address</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <Form.Control style={{ width: 'calc(100%/2)'}} value={this.state.CompanyAddress} onChange={(e) => this.setState({ CompanyAddress: e.target.value })} placeholder='Company Address'/> */}
                <textarea
                  style={{ width: 'calc(100%/1.5)', border: (this.state.CompanyAddressLine === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  // id="exampleFormControlTextarea1"
                  value={this.state.CompanyAddress}
                  onChange={(e) => this.setState({ CompanyAddress: e.target.value })}
                  rows="5"
                />
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)'}}></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)'}} value={this.state.CompanyAddress2} onChange={(e) => this.setState({ CompanyAddress2: e.target.value })} placeholder='Company Address'/>
              </div>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Payment Terms</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.PaymentLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.PaymentTerms} onChange={(e) => this.setState({ PaymentTerms: e.target.value })} placeholder='Payment Terms'/>
              </div>
            </div>
            {this.props.OpenModalType === 'EditCustomer' && 
              <>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
                  <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Created Date</div></div>
                  <div style={{ width: 'calc(100%/1)'}}>
                    <Form.Control style={{ width: 'calc(100%/1.5)' }} value={this.state.CreatedDate} readOnly />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
                  <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>CreatedBy</div></div>
                  <div style={{ width: 'calc(100%/1)'}}>
                    <Form.Control style={{ width: 'calc(100%/1.5)' }} value={this.state.CreatedBy} readOnly />
                  </div>
                </div>
              </>
            }
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateUser(this.props.OpenModalType)}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCustomalModal);
