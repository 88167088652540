import xlsx from 'xlsx-js-style'
import { language } from './language';
import moment from 'moment-timezone'
import { saveAs } from "file-saver";

function styling(value, color, type, username) {
  let colorBackground = {}
  if (color === 'g') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "dddddd"},
        bgColor: {rgb: "dddddd"}
      }
    }
  } else if (color === 'grey') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "CECECE"},
        bgColor: {rgb: "CECECE"}
      }
    }
  } else if (color === 'p') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ccbaf8"},
        bgColor: {rgb: "ccbaf8"}
      }
    }
  } else if (color === 'ye') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F9EAA3"},
        bgColor: {rgb: "F9EAA3"}
      }
    }
  } else if (color === 'Toto') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "FE7F7F"},
        bgColor: {rgb: "FE7F7F"}
      } 
    }
  } else if (color === 'DMC') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "9FA7FF"},
        bgColor: {rgb: "9FA7FF"}
      }
    }
  } else if (color === 'sin') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "68D6F8"},
        bgColor: {rgb: "68D6F8"}
      }
    }
  } else if (color === 'WW') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "70E570"},
        bgColor: {rgb: "70E570"}
      }
    }
  } else if (color === 'Sarawak') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F1CD4A"},
        bgColor: {rgb: "F1CD4A"}
      }
    }
  } else if (color === 'Sabah') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "F58065"},
        bgColor: {rgb: "F58065"}
      }
    }
  } else if (color === '88') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "DCADAD"},
        bgColor: {rgb: "DCADAD"}
      }
    }
  } else if (color === 'gr') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "90ed92"},
        bgColor: {rgb: "90ed92"}
      }
    }
  } else if (color === 'o') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ffac1c"},
        bgColor: {rgb: "ffac1c"}
      }
    }
  } else if (color === 'pi') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ffbfcb"},
        bgColor: {rgb: "ffbfcb"}
      }
    }
  } else if (color === 'color8') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "8a4949"},
        bgColor: {rgb: "8a4949"}
      }
    }
  } else if (color === 'color7') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f1cd4a"},
        bgColor: {rgb: "f1cd4a"}
      }
    }
  } else if (color === 'color6') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f58065"},
        bgColor: {rgb: "f58065"}
      }
    }
  } else if (color === 'color5') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "3c943c"},
        bgColor: {rgb: "3c943c"}
      }
    }
  } else if (color === 'color4') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "68d6f8"},
        bgColor: {rgb: "68d6f8"}
      }
    }
  } else if (color === 'color3') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f55e5e"},
        bgColor: {rgb: "f55e5e"}
      }
    }
  } else if (color === 'color2') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "8c94f0"},
        bgColor: {rgb: "8c94f0"}
      }
    }
  } else if (color === 'color1') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f0e876"},
        bgColor: {rgb: "f0e876"}
      }
    }
  }

  return {v: username ? `[${value}]` : value, t: type === "n" ? "n" : "s", s: {
    border: {
      top: {style: 'thin', color:{rgb: "000"}}, 
      bottom: {style: 'thin', color:{rgb: "000"}}, 
      left: {style: 'thin', color:{rgb: "000"}}, 
      right: {style: 'thin', color:{rgb: "000"}}
    },
    alignment: {
      horizontal: 'center'
    },
    ...colorBackground,
  }}
}

// export function getLaiCalculateExcel(MinDate, MaxDate, NewMinDate, NewMaxDate, SelfData, DownlineData, CurrentLanguage) {
//   const merge = [
//     { s: { r: 6, c: 0 }, e: { r: 6, c: 5 } }
//   ];
  
//   let ws = xlsx.utils.json_to_sheet([{A: language[CurrentLanguage].ZhonglaiCalculate }],
//     {header: ['A'], skipHeader: true})
//   xlsx.utils.sheet_add_json(ws, [{A: `${language[CurrentLanguage].Come}`, B: `${language[CurrentLanguage].From}`, C: moment(MinDate).format('YYYY-MM-DD'), D: `${language[CurrentLanguage].to}`, E: moment(MaxDate).format('YYYY-MM-DD') }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
//   xlsx.utils.sheet_add_json(ws, [{A: `${language[CurrentLanguage].NewCome}`, B: `${language[CurrentLanguage].From}`, C: moment(NewMinDate).format('YYYY-MM-DD'), D: `${language[CurrentLanguage].to}`, E: moment(NewMaxDate).format('YYYY-MM-DD') }], { header: ['A', 'B', 'C', 'D', 'E'], skipHeader: true, origin: -1 })
//   xlsx.utils.sheet_add_json(ws, [{A: `` }], { header: ['A'], skipHeader: true, origin: -1 })
    
//   xlsx.utils.sheet_add_json(ws, [{
//     A: styling(`${language[CurrentLanguage].usernameLanguage}`), 
//     B: styling(language[CurrentLanguage].ReportLai), 
//     C: styling(language[CurrentLanguage].usernameLanguage), 
//     D: styling(language[CurrentLanguage].NewCome), 
//     E: styling('+/-'),
//     F: styling('%'),
//   }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
//   xlsx.utils.sheet_add_json(ws, [{
//     A: styling(`${SelfData[0].Name}`), 
//     B: styling(`${Number(SelfData[0].Lai).toFixed(2)}`), 
//     C: styling(`${SelfData[0].DownUsername}`), 
//     D: styling(`${Number(SelfData[0].NewLai).toFixed(2)}`), 
//     E: styling(`${Number(SelfData[0].TotalPlusMinus).toFixed(2)}`),
//     F: styling(`${Number(SelfData[0].TotalPersent).toFixed(2)} %`),
//   }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
//   ws["!merges"] = merge;
//   xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[CurrentLanguage].Downline}`, 'grey') }], { header: ['A'], skipHeader: true, origin: -1})
//   for (var i = 0; i < DownlineData.length; i += 1) {
//     xlsx.utils.sheet_add_json(ws, [{
//       A: styling(`${DownlineData[i].Name}`), 
//       B: styling(`${Number(DownlineData[i].Lai).toFixed(2)}`), 
//       C: styling(`${DownlineData[i].DownUsername}`), 
//       D: styling(`${Number(DownlineData[i].NewLai).toFixed(2)}`), 
//       E: styling(`${Number(DownlineData[i].TotalPlusMinus).toFixed(2)}`),
//       F: styling(`${Number(DownlineData[i].TotalPersent).toFixed(2)} %`),
//     }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
//   }
  
//   ws['!cols'] = [{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150},{wpx: 150}];
//   let wb = { SheetNames: [], Sheets: {} }
//   wb.SheetNames.push(`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`)
//   wb.Sheets[`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`] = ws
//   xlsx.writeFile(wb, `${language[CurrentLanguage].ZhonglaiCalculate}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
// }

export function ExportAllReportExcel(alldata, startDate, endDate, current, Currentupline) {
  let ws = xlsx.utils.json_to_sheet([{A: `${language[current].Date}`, B: `${startDate}`, C: `${endDate}`}],
    {header: ['A', 'B', 'C'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling(`${language[current].USerLoginAcc} ID`), B: styling(`${language[current].Name}/${language[current].Code}`), C: styling(`${language[current].Come}`), D: styling(`${language[current].DownlineEat}`), E: styling(`${language[current].selfBet}`), F: styling(`${language[current].selfWater}`), G: styling(`${language[current].selfWin}`), H: styling(`${language[current].downlineBet}`), I: styling(`${language[current].downlineWin}`), J: styling(`${language[current].totalBet}`), K: styling(`${language[current].TotalWater}`), L: styling(`${language[current].totalWin}`), M: styling(`${language[current].totalWin}`), N: styling(`${language[current].Share}`), O: styling(`${language[current].SharesWater}`), P: styling(`${language[current].sharesWin}`), Q: styling(`${language[current].ShareProfit}`), R: styling(`${language[current].ReportUpline}`), S: styling(`${language[current].ReportUplineWater}`), T: styling(`${language[current].uplineWin}`), U: styling(`${language[current].ReportUplineProfit}`), V: styling(`${language[current].ReportWaterProfit}`), W: styling(`${language[current].ReportWinProfit}`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'], skipHeader: true, origin: -1 })

  if (alldata === undefined) {
    alldata = []
  }
  let selfData = alldata.filter(function (el) {
    return el.username === Currentupline
  });
  for (let i = 0; i < selfData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(selfData[i].username, '', '', true), 
      B: styling(selfData[i].Name), 
      C: styling(selfData[i].Lai, 'g', 'n'), 
      D: styling(selfData[i].Eat, 'g', 'n'), 
      E: styling(selfData[i].SelfBet, 'p', 'n'), 
      F: styling(selfData[i].SelfWater, 'p', 'n'),
      G: styling(selfData[i].SelfWin, 'p', 'n'),
      H: styling(selfData[i].DownlineBet, 'b', 'n'),
      I: styling(selfData[i].DownlineWin, 'b', 'n'),
      J: styling(selfData[i].TotalBet, 'gr', 'n'), 
      K: styling(selfData[i].TotalWater, 'gr', 'n'),
      L: styling(selfData[i].TotalWin, 'gr', 'n'),
      M: styling(selfData[i].TotalProfit, 'gr', 'n'),
      N: styling(selfData[i].Shares, 'o', 'n'),
      O: styling(selfData[i].SharesWater, 'o', 'n'),
      P: styling(selfData[i].SharesWin, 'o', 'n'),
      Q: styling(selfData[i].SharesProfit, 'o', 'n'),
      R: styling(selfData[i].Upline, 'pi', 'n'),
      S: styling(selfData[i].UplineWater, 'pi', 'n'),
      T: styling(selfData[i].UplineWin, 'pi', 'n'),
      U: styling(selfData[i].UplineProfit, 'pi', 'n'),
      V: styling(selfData[i].WaterProfit, '', 'n'), 
      W: styling(selfData[i].WinProfit, '', 'n')
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
  }
  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: '', I: '', J: '', K: `${language[current].Downline}`}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'], skipHeader: true, origin: -1 })

  const total = {
    'C': 0, 'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0, 'R': 0, 'S': 0, 'T': 0, 'U': 0, 'V': 0,
  }
  let allData = alldata.filter(function (el) {
    return el.username !== Currentupline
  });
  for (let i = 0; i < allData.length; i += 1) {
    if (Number(allData[i].Lai) === 0) {
      // ignore
    } else {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(allData[i].username, '', ''), 
        B: styling(allData[i].Name), 
        C: styling(allData[i].Lai, 'g', 'n'), 
        D: styling(allData[i].Eat, 'g', 'n'), 
        E: styling(allData[i].SelfBet, 'p', 'n'), 
        F: styling(allData[i].SelfWater, 'p', 'n'), 
        G: styling(allData[i].SelfWin, 'p', 'n'), 
        H: styling(allData[i].DownlineBet, 'b', 'n'),
        I: styling(allData[i].DownlineWin, 'b', 'n'),
        J: styling(allData[i].TotalBet, 'gr', 'n'), 
        K: styling(allData[i].TotalWater, 'gr', 'n'), 
        L: styling(allData[i].TotalWin, 'gr', 'n'), 
        M: styling(allData[i].TotalProfit, 'gr', 'n'), 
        N: styling(allData[i].Shares, 'o', 'n'), 
        O: styling(allData[i].SharesWater, 'o', 'n'), 
        P: styling(allData[i].SharesWin, 'o', 'n'), 
        Q: styling(allData[i].SharesProfit, 'o', 'n'), 
        R: styling(allData[i].Upline, 'pi', 'n'), 
        S: styling(allData[i].UplineWater, 'pi', 'n'), 
        T: styling(allData[i].UplineWin, 'pi', 'n'), 
        U: styling(allData[i].UplineProfit, 'pi', 'n'), 
        V: styling(allData[i].WaterProfit, '', 'n'), 
        W: styling(allData[i].WinProfit, '', 'n')
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })
      total.C += Number(allData[i].Lai)
      total.D += Number(allData[i].Eat)
      total.E += Number(allData[i].SelfBet)
      total.F += Number(allData[i].SelfWater)
      total.G += Number(allData[i].SelfWin)
      total.H += Number(allData[i].DownlineBet)
      total.I += Number(allData[i].DownlineWin)
      total.J += Number(allData[i].TotalBet)
      total.K += Number(allData[i].TotalWater)
      total.L += Number(allData[i].TotalWin)
      total.M += Number(allData[i].TotalProfit)
      total.N += Number(allData[i].Shares)
      total.O += Number(allData[i].SharesWater)
      total.P += Number(allData[i].SharesWin)
      total.Q += Number(allData[i].SharesProfit)
      total.R += Number(allData[i].Upline)
      total.S += Number(allData[i].UplineWater)
      total.T += Number(allData[i].UplineWin)
      total.U += Number(allData[i].UplineProfit)
      total.V += Number(allData[i].WaterProfit)
      total.W += Number(allData[i].WinProfit)
    }
  }

  xlsx.utils.sheet_add_json(ws, [{A: '', B: '', C: '', D: ''}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
  xlsx.utils.sheet_add_json(ws, [{A: styling('Total', 'g'), B: styling('', 'g'), C: styling(total.C.toFixed(2), 'g', 'n'), D: styling(total.D.toFixed(2), 'g', 'n'), E: styling(total.E.toFixed(2), 'g', 'n'), F: styling(total.F.toFixed(2), 'g', 'n'), G: styling(total.G.toFixed(2), 'g', 'n'), H: styling(total.H.toFixed(2), 'g', 'n'), I: styling(total.I.toFixed(2), 'g', 'n'), J: styling(total.J.toFixed(2), 'g', 'n'), K: styling(total.K.toFixed(2), 'g', 'n'), L: styling(total.L.toFixed(2), 'g', 'n'), M: styling(total.M.toFixed(2), 'g', 'n'), N: styling(total.N.toFixed(2), 'g', 'n'), O: styling(total.O.toFixed(2), 'g', 'n'), P: styling(total.P.toFixed(2), 'g', 'n'), Q: styling(total.Q.toFixed(2), 'g', 'n'), R: styling(total.R.toFixed(2), 'g', 'n'), S: styling(total.S.toFixed(2), 'g', 'n'), T: styling(total.T.toFixed(2), 'g', 'n'), U: styling(total.U.toFixed(2), 'g', 'n'), V: styling(total.V.toFixed(2), 'g', 'n'), W: styling(total.V.toFixed(2), 'g', 'n')}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70},{wpx: 70}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Summary`)
  wb.Sheets['Summary'] = ws
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
  const blob = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' });
  return blob;
  // xlsx.writeFile(wb, `BTACE ${moment(startDate).format('DDMM')} - ${moment(endDate).format('DDMM')}.xlsx`)
}

export function ExportProductExcel(ExcelData, PageType) {
  let ws = xlsx.utils.json_to_sheet([{A: PageType }],
    {header: ['A'], skipHeader: true})
  if (PageType === 'Product') {
    xlsx.utils.sheet_add_json(ws, [{A: styling(`ID`), B: styling(`SKUCode`), C: styling(`Description`), D: styling(`OtherDesc`), E: styling(`SKUType`), F: styling(`Category`), G: styling(`Department`), H: styling(`UOM`), I: styling(`Tax`), J: styling(`CreatedBy`), K: styling(`CreatedDate`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'], skipHeader: true, origin: -1 })
    for (var i = 0; i < ExcelData.length; i += 1) {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${ExcelData[i].ID}`), 
        B: styling(`${ExcelData[i].SKUCode}`), 
        C: styling(`${ExcelData[i].Description}`), 
        D: styling(`${ExcelData[i].OtherDesc}`), 
        E: styling(`${ExcelData[i].SKUType}`),
        F: styling(`${ExcelData[i].Category} `),
        G: styling(`${ExcelData[i].Department}`), 
        H: styling(`${ExcelData[i].UOM}`), 
        I: styling(`${ExcelData[i].Tax}`), 
        J: styling(`${ExcelData[i].Createdby}`),
        K: styling(`${ExcelData[i].CreatedDate}`),
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'], skipHeader: true, origin: -1 })
    }
    ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  }
  if (PageType === 'ShelfMaster') {
    xlsx.utils.sheet_add_json(ws, [{A: styling(`Shelf No`), B: styling(`Balance`), C: styling(`Store Code
    `)}], { header: ['A', 'B', 'C'], skipHeader: true, origin: -1 })
    for (var a = 0; a < ExcelData.length; a += 1) {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${ExcelData[a].ShelfNo}`), 
        B: styling(`${ExcelData[a].Balance}`), 
        C: styling(`${ExcelData[a].StoreCode}`),
      }], { header: ['A', 'B', 'C'], skipHeader: true, origin: -1 })
    }
    ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100}];
  }
  if (PageType === 'PriceLevel') {
    xlsx.utils.sheet_add_json(ws, [{A: styling(`Cost Price`), B: styling(`Avg Cost`), C: styling(`Selling Price
    `), D: styling(`Created By`)}], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    for (var c = 0; c < ExcelData.length; c += 1) {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${ExcelData[c].CostPrice}`), 
        B: styling(`${ExcelData[c].AvgCost}`), 
        C: styling(`${ExcelData[c].SellingPrice}`),
        D: styling(`${ExcelData[c].Createdby}`),
      }], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    }
    ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  }
  if (PageType === 'StockInfo') {
    xlsx.utils.sheet_add_json(ws, [{A: styling(`Store Code`), B: styling(`Store Desc`), C: styling(`Balance
    `), D: styling(`L/PurchaseDate`), E: styling(`L/DOModule`), F: styling(`ExpiredDate`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
    for (var d = 0; d < ExcelData.length; d += 1) {
      xlsx.utils.sheet_add_json(ws, [{
        A: styling(`${ExcelData[d].StoreCode}`), 
        B: styling(`${ExcelData[d].StoreDesc}`), 
        C: styling(`${ExcelData[d].Balance}`),
        D: styling(`${ExcelData[d].PurchaseDate}`),
        E: styling(`${ExcelData[d].DoDate}`),
        F: styling(`${ExcelData[d].ExpiredDate}`),
      }], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
    }
    ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`${moment().format('YYYY-MM-DD HH:mm:ss')}`)
  wb.Sheets[`${moment().format('YYYY-MM-DD HH:mm:ss')}`] = ws
  xlsx.writeFile(wb, `${PageType}_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function ExportStore(ExcelData) {
  let ws = xlsx.utils.json_to_sheet([{A: 'Store' }],
    {header: ['A'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: styling(`Store Code`), B: styling(`Store Desc`), C: styling(`Other Desc`), D: styling(`Store Type`), E: styling(`Main Store`), F: styling(`Store Group`), G: styling(`GST No`), H: styling(`Register No`), I: styling(`Address`), J: styling(`Price Level`), K: styling(`Picking List`), L: styling(`Pon/Order Qty`), M: styling(`Status Store`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })
  for (var i = 0; i < ExcelData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(`${ExcelData[i].StoreCode}`), 
      B: styling(`${ExcelData[i].StoreDesc}`), 
      C: styling(`${ExcelData[i].OtherDesc}`), 
      D: styling(`${ExcelData[i].StoreType}`), 
      E: styling(`${ExcelData[i].MainStore}`),
      F: styling(`${ExcelData[i].StoreGroup} `),
      G: styling(`${ExcelData[i].GSTNo}`), 
      H: styling(`${ExcelData[i].RegisterNo}`), 
      I: styling(`${ExcelData[i].Address}`), 
      J: styling(`${ExcelData[i].PriceLevel}`),
      K: styling(`${ExcelData[i].PickingList}`),
      L: styling(`${ExcelData[i].OrderQty}`),
      M: styling(`${ExcelData[i].StatusStore}`),
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'], skipHeader: true, origin: -1 })
  }
  ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 200},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`)
  wb.Sheets[`Total_Lai ${moment().format('YYYY-MM-DD HH:mm:ss')}`] = ws
  xlsx.writeFile(wb, `Store_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function ExportCustomer(ExcelData) {
  let ws = xlsx.utils.json_to_sheet([{A: 'Customer Lists' }],
    {header: ['A'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: styling(`Customer Code`), B: styling(`Customer Company Name`), C: styling(`Tel`), D: styling(`Company Address`), E: styling(`Attn Name`), F: styling(`Payment Term`), G: styling(`Created Date`), H: styling(`Created By`)}], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'], skipHeader: true, origin: -1 })
  for (var i = 0; i < ExcelData.length; i += 1) {
    xlsx.utils.sheet_add_json(ws, [{
      A: styling(`${ExcelData[i].CustomerCode}`), 
      B: styling(`${ExcelData[i].CustomerCompanyName}`),
      C: styling(`${ExcelData[i].Tel}`), 
      D: styling(`${ExcelData[i].CompanyAddress}`), 
      E: styling(`${ExcelData[i].AttnName}`),
      F: styling(`${ExcelData[i].PaymentTerms} `),
      G: styling(`${ExcelData[i].CreatedDate}`), 
      H: styling(`${ExcelData[i].Createdby}`),
    }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G'], skipHeader: true, origin: -1 })
  }
  ws['!cols'] = [{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`CustomerList_ ${moment().format('YYYY-MM-DD HH:mm:ss')}`)
  wb.Sheets[`CustomerList_ ${moment().format('YYYY-MM-DD HH:mm:ss')}`] = ws
  xlsx.writeFile(wb, `CustomerList_${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}
