import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import Sidebar from '../Component/Sidebar';
import IconDetails from '../image/IconDetails.png';
import { Modal, Table, Alert, Form } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class DeliveryModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShowDetail: false,
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
    }
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  EditClicked() {
    console.log('Clicked Edit')
  }

  ReceivedClicked() {
    console.log('Clicked Received')
  }

  DeliveryDetails(DeliveryType) {
    console.log('DeliveryType', DeliveryType)
  }
  
  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <div style={{ width: '100%'}}>
          <Header/>
        </div> */}
        <Sidebar/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: 'white' }}>{'Delivery Order Module'}</h3>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)' }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white' }}>Custom Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 30}}>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>From Date:</div>
                    <div style={{ width: 'calc(100% / 1)'}}><Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/></div>
                  </div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>To Date:</div>
                    <div style={{ width: 'calc(100% / 1)'}}><Form.Control value={this.state.toDate} onChange={(e) => this.setState({ toDate: e.target.value })} type="date"/></div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 30}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }}>Search</div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '98%', marginBottom: 10 }}>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#F8CA5D' }}>
                  <div>Pending Delivery Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Pending')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#FDD56C'}}>{`1000000`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#01BCD6'}}>
                  <div>Delivered incomplete Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Ongoing')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#49C7EA'}}>{`5000`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#8EC555'}}>
                  <div>Completed Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Completed')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#9CCC6A'}}>{`1000000`}</div>
              </div>
            </div>
            {this.state.PODataLists.length > 0 && 
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', marginBottom: 40, padding: 10, minHeight: '60vh', maxHeight: '60vh', backgroundColor: 'rgba(225,225,225, 2)' }}>
              <div style={{ width: '100%', borderBottom: '1px solid #333333', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
                <div style={{ width: 'calc(100%)'}}>Created Date</div>
                <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                <div style={{ width: 'calc(100%)'}}>PO.No</div>
                <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                <div style={{ width: 'calc(100%)'}}>Location</div>
                <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                <div style={{ width: 'calc(100%)'}}>Status</div>
                <div style={{ width: 'calc(100%)'}}>Action</div>
              </div>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: 'auto', overflowX: 'auto'}}>
                <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div>
                <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: 'calc(100%)'}}>Created Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                  <div style={{ width: 'calc(100%)'}}>PO.No</div>
                  <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                  <div style={{ width: 'calc(100%)'}}>Location</div>
                  <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditClicked()}>Edit</div>
                    <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>Received</div>
                  </div>
                </div>
              </div>
              {/* <div className='dcc' style={{ width: '100%', borderTop: '1px solid lightgrey', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>1</div> */}
            </div>
            }
            {this.state.PODataLists.length === 0 && 
            <div style={{ fontWeight: 'bold', padding: 20 }}>No Data</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryModule);
