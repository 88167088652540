import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import { createDepartmentMasterF, UpdateDepartmentFNC } from '../Api';
import { Button, Modal, Form } from 'react-bootstrap';

var timer

class DepartmentMasterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerComponyName: '',
      Tel: '',
      AnntName: '',
      CompanyAddress: '',
      CompanyAddress2: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      CustomerNameLine: false,
      TelLine: false,
      CompanyAddressLine: false,
      PaymentLine: false,
      AnntNameLine: false,
      DeptCode: '',
      DeptDesc: '',
      OtherDesc: '',
      DeptDescBoolean: false,
    }
    this.CreateDepartment = this.CreateDepartment.bind(this);
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditDepartment') {
      // console.log('DepartmentDetails', this.props.DepartmentDetails)
      this.setState({ 
        DeptCode: this.props.DepartmentDetails.DeptCode,
        DeptDesc: this.props.DepartmentDetails.DeptDesc,
        OtherDesc: this.props.DepartmentDetails.OtherDesc,
      })
    }
    console.log('CreatedDate', this.state.CreatedDate)
  }

  async CreateDepartment() {
    const DeptCode = this.state.DeptCode || ''
    const DeptDesc = this.state.DeptDesc || ''
    const OtherDesc = this.state.OtherDesc || ''
    if (this.props.OpenModalType === 'EditDepartment') {
      const object = {
        DeptCode: DeptCode,
        DeptDesc: DeptDesc,
        OtherDesc: OtherDesc,
      }
      const resultupdate = await UpdateDepartmentFNC(object, DeptCode);
      if(resultupdate.message === 'Update success'){
        this.DisplaySuccessMEssage(`Update Success`)
      } else {
        console.log(resultupdate.error)
      }
  
    } else {
      const object = {
        DeptCode: DeptCode,
        DeptDesc: DeptDesc,
        OtherDesc: OtherDesc,
      }
      if (DeptCode === '') {
        this.DisplayErrorMEssage('Please Insert Department Code', 'Department Code')
      } else if (DeptDesc.trim().length === 0) {
        this.DisplayErrorMEssage('Please Insert Department Describe', 'Department Describe')
      } else {
        const resultcreate = await createDepartmentMasterF(this.props.username, [object]);
        // console.log('resultcreate', resultcreate)
        if(resultcreate.message === 'Successful'){
          this.DisplaySuccessMEssage(`Successful Created Department`)
        } else {
          this.DisplayErrorMEssage(resultcreate.error, 'Department Code')
          // console.log(resultcreate.error)
        }
      }
    }
   
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DeptDescBoolean: (message === 'Department Describe') ? true :  false,
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 4000);
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayCusterAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditDepartment') ? `✎ Edit Department` : '👥 Create Department'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
            }
            {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ marginBottom: 10, color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
            } */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Department Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control disabled={(this.props.OpenModalType === 'EditDepartment')} style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.CustomerNameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.DeptCode} onChange={(e) => this.setState({ DeptCode: e.target.value })} placeholder='Department Code'/>
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Deparmentt Describe</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.TelLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Tel} onChange={(e) => this.setState({ Tel: e.target.value })} placeholder='Tel'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Other Describe</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.AnntNameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.AnntName} onChange={(e) => this.setState({ AnntName: e.target.value })} placeholder='Annt Name'/>
              </div>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Department Describe</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <textarea
                  style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.DeptDescBoolean === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  // id="exampleFormControlTextarea1"
                  value={this.state.DeptDesc}
                  onChange={(e) => this.setState({ DeptDesc: e.target.value })}
                  rows="5"
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Other Describe</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <textarea
                  style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: '1px solid lightgrey' }}
                  className="form-control"
                  // id="exampleFormControlTextarea1"
                  value={this.state.OtherDesc}
                  onChange={(e) => this.setState({ OtherDesc: e.target.value })}
                  rows="5"
                />
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)'}}></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)'}} value={this.state.CompanyAddress2} onChange={(e) => this.setState({ CompanyAddress2: e.target.value })} placeholder='Company Address'/>
              </div>
            </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Payment Terms</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.PaymentLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.PaymentTerms} onChange={(e) => this.setState({ PaymentTerms: e.target.value })} placeholder='Payment Terms'/>
              </div>
            </div> */}
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateDepartment()}>{(this.props.OpenModalType === 'EditDepartment') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentMasterModal);
