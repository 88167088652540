import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Alert, Button, Form,Table } from 'react-bootstrap';

var timer

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Table: [],
      DynamicArray1: [],
      options: [{
        'value': '1Utama',
        'label': '1 Utama'
      },
      {
        'value': 'Pavilion',
        'label': 'Pavilion'
      },{
        'value': 'MidValley',
        'label': 'Mid Valley'
      },{
        'value': 'AeonShahAlam',
        'label': 'Aeon ShahAlam'
      },{
        'value': 'Sunway',
        'label': 'Sunway'
      },{
        'value': 'AeonBukittinggi',
        'label': 'Aeon Bukit tinggi'
      }],
      lvloptions: [{
        'value': 'upper',
        'label': 'Upper'
      },
      {
        'value': 'down',
        'label': 'Down'
      }],
      showNotification: false,
      LVL: [],
    }
  }

  async componentDidMount() {
    const copyObject1 = [];
    const copyObject2 = [];
    for (var i=1; i<=240; i++ ) {
      copyObject1.push(i)
    }

    for (var z=0; z<=10; z++ ) {
      copyObject2.push(z)
    }
    this.setState({DynamicArray1: copyObject1, Table: copyObject2})
  }

  logout() {
    window.location.href = '/';
  }

 

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  handleChange = OptionsPackage => {
    console.log('OptionsPackage', OptionsPackage)
  };

  handleChangeoption = OptionsPackage =>{
    console.log('OptionsPackage', OptionsPackage['value'])
    this.setState({LVL: OptionsPackage})
  }
  render() {
    const animatedComponents = makeAnimated();
    return (
      <div fluid >
        <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px' }}>
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{'Dynamic'}</h2>
       
            </div>
            <div className="dcc"  style={{ width: '100%', flex: 1, flexDirection: 'row', flexWrap: 'wrap',  }}>
              <div style={{fontSize: '17px', fontWeight: 'bold'}} >{'LEVEL '}</div>
              <div style={{  width: 150, marginRight: 10, marginLeft: 10}}>
                <Select 
                  options={this.state.lvloptions}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={this.state.LVL}
                  onChange={this.handleChangeoption}
                // placeholder={language[this.props.currentLanguage].SeacrhPackage}
                >
                </Select>
              </div>
            </div>
            <div className="dcc"  style={{ width: '100%', flex: 1, flexDirection: 'row', flexWrap: 'wrap',  }}>
              <Form.Control style={{ width: 'calc(20%)',fontSize: '17px', fontWeight: '500', marginRight: 10}} /> 
              <div style={{fontSize: '17px', fontWeight: 'bold'}} >{' - '}</div>
              <Form.Control style={{ width: 'calc(20%)',fontSize: '17px', fontWeight: '500', marginLeft: 10, marginRight: 10}}  />
              <Button  style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                {'Search'}
              </Button>
            </div>
            <div className=''>
              <Button  style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                {'SAVE'}
              </Button>
            </div>
          </div>
          <div className="dcc" style={{ width: 'calc(100%)', }}>
            <div className="dcc" style={{ width: 'calc(80%)', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div className="dcc" style={{ display: 'flex', flexWrap: 'wrap',flex: "1", border: "1px solid black", padding: 5, borderRadius: 5, flexDirection: 'row'}}>
                  {(this.state.Table.length > 0 )&&<>
                    {this.state.Table.map((ind) => {
                      return(<Table  striped bordered hover responsive style={{ width: 'calc(100%)'}}>
                        <thead>
                          <tr style={{ textAlign: 'center'}}>
                            <th>{'Label'}</th>
                            <th>{'Name'}</th>
                          </tr>
                        </thead>
                        {this.state.DynamicArray1.length > 0 ?
                          <tbody>
                        
                            {this.state.DynamicArray1.map((items, index) => {
                              return(<>
                                {( ind === 0 ? items <= 20 : 
                                  ind === 1 ? (items > 20 && items <= 40) :
                                    ind === 2 ? (items > 40 && items <= 60) :
                                      ind === 3 ? (items > 60 && items <= 80) :
                                        ind === 4 ? (items > 80 && items <= 100) :
                                          ind === 5 ? (items > 120 && items <= 140) :
                                            ind === 6 ? (items > 140 && items <= 160) :
                                              ind === 7 ? (items > 160 && items <= 180) :
                                                ind === 8 ? (items > 180 && items <= 200) :
                                                  ind === 9 ? (items > 200 && items <= 220) :
                                                    (items > 220 && items <= 240) 
                                ) && <tr key={index} style={{ cursor: 'pointer' }}>
                                  <td>{(this.state.LVL['value'] === 'down') ? `AD${items}` : `AU${items}`}</td>
                                  <td>
                                    <div style={{  width: 200}}>
                                      <Select 
                                        options={this.state.options}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        // value={val}
                                        onChange={this.handleChange}
                                        // placeholder={language[this.props.currentLanguage].SeacrhPackage}
                                      >
                                      </Select>
                                    </div>
                                  </td>
                                </tr>}
                              </>
                              )
                            })}
                          </tbody>
                          :
                          <tbody>
                            <tr style={{ backgroundColor: 'lightgray'}}>
                              <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                            </tr>
                          </tbody>}
                      </Table>
                      )})}</>}
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}


export default connect(mapStateToProps, null)(Dynamic);
