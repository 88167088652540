import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import { FaTrash, FaEdit} from 'react-icons/fa';
import Sidebar from '../Component/Sidebar';
import AboutVideo from '../Component/VideoDesign';
import Barchart from '../Component/Chart/Barchart';
import HorizontalBarChart from '../Component/Chart/HorizontalChart';
import Piechart from '../Component/Chart/Piechart';
import LineChart2 from '../Component/Chart/LineChart';
// import BarChart from '../Component/BarChart';
// import PieChart from '../Component/PieChart';
// import LineChart from '../Component/LineChart';
import IconDetails from '../image/IconDetails.png';
// import Walllapercount from '../image/Walllapercount.jpg';
import { getDashbordDataFNC } from '../Api';
import { Alert, Form } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShowDetail: false,
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
      slideIndex: 1,
      SlideShow: [
        {
          src: 'https://images.squarespace-cdn.com/content/v1/573134948259b541884a78c8/1463772347899-WPGX1W4WOLICX0TJJG6G/KAM+%28Services+2%29+-+PNI.jpg?format=1500w',
          MasterName: 'Customer Master',
          Description: 'Customer Master use to manages Clients and Customers',
          Count: 0,
          Page: 'CustomerMaster',
        },
        {
          src: 'https://img.freepik.com/premium-photo/joint-development-new-product-business-technology-internet_161452-207.jpg?w=2000',
          MasterName: 'Product Master',
          Description: 'Product Master is use to manages Product in and out of the store',
          Count: 1,
          Page: 'ProductMaster',
        }, 
        {
          src: 'https://blog.procureport.com/wp-content/uploads/2022/07/category-management-strategy.png',
          MasterName: 'Category Master',
          Description: 'Manage Products Categories',
          Count: 2,
          Page: 'CategoryMaster',
        },
        {
          src: 'https://wallpapercave.com/wp/wp8477835.jpg',
          MasterName: 'Department Master',
          Description: 'Manage Every Department that Company Currently have',
          Count:3,
          Page: 'DepartmentMaster',
        },
        {
          src: 'https://innovations.bizerba.com/wp-content/uploads/2021/03/Smart-Shelf-Management_DIY.jpg',
          MasterName: 'Shelf Master',
          Description: 'Manage all the Shelf on every Store available',
          Count:4,
          Page: 'ShelfMaster',
        },
        {
          src: 'https://bucket-api.commercialrealestate.com.au/v1/bucket/image/15895422_1_1_220520_044212-w1600-h1200',
          MasterName: 'Store Master',
          Description: 'Manage all the Store By ....',
          Count:5,
          Page: 'StoreMaster',
        },
      ],
      SummaryDetails: {
        Product: { count: 0 },
        Customer: { count: 0 },
        Department: { count: 0 },
        Category: { count: 0 },
      },
      CurrentSlideShow: {},
      isActive: false,
      Past60Days: {},
      SampleDataset: [
        {SKUCode: 'sku006', containerCode: '001', Status: 'Active', zoneCode: 'Z', Quantity: '1500' },
        {SKUCode: 'sku050', containerCode: '005', Status: 'Active', zoneCode: 'A', Quantity: '2000' },
        {SKUCode: 'sku010', containerCode: '007', Status: 'Active', zoneCode: 'C', Quantity: '180' },
      ],
    }
    // this.ChangeData = this.ChangeData.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
    console.log('SampleDataset', this.state.SampleDataset)
    window.addEventListener('scroll', this.handleScroll);
    this.timerID = setInterval(
      () => this.ChangeSlide('Next'),
      6000
    );
    this.setState({ CurrentSlideShow: this.state.SlideShow[0] })
    this.GetDashboardReturnData();
    this.generatePast60days();
  }

  generatePast60days() {
    const today = new Date();
    const dateDict = {};
    for (let i = 0; i < 60; i++) {
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() - i);
      const dateKey = currentDate.toISOString().split('T')[0];
      dateDict[dateKey] = Math.floor(Math.random() * 5000) + 1;
    }
    this.setState({ Past60Days: dateDict })
  }

  async GetDashboardReturnData() {
    const DashboardData = await getDashbordDataFNC('admin')
    if (DashboardData) {
      let SummaryDetails = this.state.SummaryDetails
      SummaryDetails['Product']['count'] = 0
      SummaryDetails['Customer']['count'] = DashboardData.DisplaySummary.Customer['Customers']
      SummaryDetails['Department']['count'] = DashboardData.DisplaySummary.Department['Departments']
      SummaryDetails['Category']['count'] = DashboardData.DisplaySummary.Category['Categories']

      this.setState({ SummaryDetails })
    }
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  EditClicked() {
    console.log('Clicked Edit')
  }

  ReceivedClicked() {
    console.log('Clicked Received')
  }

  DeliveryDetails(DeliveryType) {
    console.log('DeliveryType', DeliveryType)
  }

  ChangeSlide(type) {
    const SlideShow = this.state.SlideShow
    const CurrentSlideShow = this.state.CurrentSlideShow
    let getNumber = 0
    if (type === 'Next') {
      getNumber = (CurrentSlideShow.Count + 1 > SlideShow.length - 1) ? 0 : CurrentSlideShow.Count + 1
    } else {
      getNumber = (CurrentSlideShow.Count - 1 < 0) ? SlideShow.length - 1 : CurrentSlideShow.Count - 1
    }
    let FilterSlideImage = SlideShow.filter(function (el) {
      return el.Count === getNumber;
    });
    this.setState({ CurrentSlideShow: FilterSlideImage[0] })
  }

  SelectedImagesSlide(SelectedImage) {
    this.setState({ CurrentSlideShow: SelectedImage })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearInterval(this.timerID);
    // clearInterval(this.interval);
  }

  ChangePage(Page) {
    // window.location.href = Page;
  }

  handleScroll() {
    const element = document.querySelector('.service-slow-pop-up-text');
    if (element) {
      const boundingBox = element.getBoundingClientRect();
      if (boundingBox.top < window.innerHeight / 2 && !this.state.isActive) {
        this.setState({ isActive: true });
        window.removeEventListener('scroll', this.handleScroll);
      }
    }
  }

  AddNewDataLine() {
    let SampleDataset = this.state.SampleDataset
    console.log('SampleDataset', SampleDataset)
    const newObject = { SKUCode: '', containerCode: '', Status: 'Active', zoneCode: '', Quantity: '' };
    this.setState((prevState) => ({
      SampleDataset: [...prevState.SampleDataset, newObject],
    }));
  }

  ChangeData(value, index, type) {
    let SampleDataset = this.state.SampleDataset
    SampleDataset[index][type] = value
    this.setState({ SampleDataset })
  }
  
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        {/* <div style={{ width: '100%'}}>
          <Header/>
        </div> */}
        <Sidebar/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', backgroundColor: 'rgba(0,0,0, .9)', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: 'white', fontWeight: 'bold' }}>{'Dashboard'}</h3><div style={{ color: 'grey'}}>Control panel</div>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', color: 'black' ,marginBottom: 10 }}>
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                <div className='BackgroundImageSlider' style={{ backgroundImage: `url(${this.state.CurrentSlideShow.src})`, display: 'flex', flexDirection: 'column', borderRadius: 5 }}>
                  <div style={{ height: '90vh', display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div className='dcc' style={{ width: 'calc(100%/10)' }}>
                      <div className='prev' onClick={() => this.ChangeSlide('Prev')}>{'⟨'}</div>
                    </div>
                    <div className='dcc' style={{ width: 'calc(100%/1)', color: 'white' }}>
                      <div className='dcc SelectedMaster cannothightlight'
                        style={{ backgroundColor: 'rgba(0,0,0, .5)',  borderRadius: 10, display: 'flex', flexDirection: 'column'}} onClick={() => this.ChangePage(`/${this.state.CurrentSlideShow.Page}`)}>
                        <div>{this.state.CurrentSlideShow.MasterName}</div>
                        <div className='Description'>{this.state.CurrentSlideShow.Description}</div>
                      </div>
                    </div>
                    <div className='dcc' style={{ width: 'calc(100%/10)' }}>
                      <div className='dcc next' onClick={() => this.ChangeSlide('Next')}>{'⟩'}</div>
                    </div>
                  </div>
                  <div className='dcc' style={{ height: '5vh'}}>
                    <div className='dcc' style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                      {this.state.SlideShow.map((images,idxx) => {
                        return(
                          <div key={idxx} className='PointerSliderImage'
                            style={{ backgroundColor: (this.state.CurrentSlideShow.Count === images.Count) ? 'white' : 'lightgrey', border: (this.state.CurrentSlideShow.Count === images.Count) ? '4px solid grey' : 'none' }} 
                            onClick={() => this.SelectedImagesSlide(images)}></div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', width: '98%', marginBottom: 10 }}>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#F8CA5D' }}>
                  <div>Pending Delivery Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Pending')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#FDD56C'}}>{`1000000`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#01BCD6'}}>
                  <div>Delivered incomplete Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Ongoing')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#49C7EA'}}>{`5000`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '30vh', marginRight: 10 }}>
                <div className='dcc SummaryDOContainer' style={{ backgroundColor: '#8EC555'}}>
                  <div>Completed Order</div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Completed')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc SummaryDOAmountContainer' style={{ backgroundColor: '#9CCC6A'}}>{`1000000`}</div>
              </div>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', width: '98%', marginBottom: 10 }}>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '25vh', marginRight: 10 }}>
                <div className='dcc DashBoardContainer' style={{ backgroundColor: '#01B8EF' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='cannothightlight' style={{ fontWeight: 'bold', fontSize: '40px'}}>{this.state.SummaryDetails.Product['count']}</div>
                    <div className='cannothightlight'>Total Products</div>
                  </div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Pending')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc DashBoardMoreinfoContainer cannothightlight' style={{ backgroundColor: '#09A3D3'}}>{`more info 🠞`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '25vh', marginRight: 10 }}>
                <div className='dcc DashBoardContainer' style={{ backgroundColor: '#029C51'}}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='cannothightlight' style={{ fontWeight: 'bold', fontSize: '40px'}}>{this.state.SummaryDetails.Customer['count']}</div>
                    <div className='cannothightlight'>Total Customers</div>
                  </div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Ongoing')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc DashBoardMoreinfoContainer cannothightlight' style={{ backgroundColor: '#098948'}}>{`more info 🠞`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '25vh', marginRight: 10 }}>
                <div className='dcc DashBoardContainer' style={{ backgroundColor: '#F19015'}}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='cannothightlight' style={{ fontWeight: 'bold', fontSize: '40px'}}>{this.state.SummaryDetails.Category['count']}</div>
                    <div className='cannothightlight' >Total Categories</div>
                  </div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Completed')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc DashBoardMoreinfoContainer cannothightlight' style={{ backgroundColor: '#D58013'}}>{`more info ⮊`}</div>
              </div>
              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', minHeight: '25vh', marginRight: 10 }}>
                <div className='dcc DashBoardContainer' style={{ backgroundColor: '#DA4131'}}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='cannothightlight' style={{ fontWeight: 'bold', fontSize: '40px'}}>{this.state.SummaryDetails.Department['count']}</div>
                    <div className='cannothightlight' >Total Departments</div>
                  </div>
                  <div style={{ cursor: 'pointer' }} onClick={() => this.DeliveryDetails('Completed')}><img src={IconDetails} alt="Logo" className='DetailsIcon' /></div>
                </div>
                <div className='dcc DashBoardMoreinfoContainer cannothightlight' style={{ backgroundColor: '#C0392B'}}>{`more info ⮊`}</div>
              </div>
            </div>
            <div style={{ height: '45vh', width: '100%' }}>
              <AboutVideo />
            </div>
            <div style={{ width: '100%', backgroundColor: 'lightgrey', marginTop: 50, maxHeight: '50vh' }}>
              <div>{JSON.stringify(this.state.Past60Days) !== '{}' && <LineChart2 Details={this.state.Past60Days}/>}</div>
            </div>
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50vh', color: 'white', fontFamily:'Impact, Haettenschweiler, Arial Narrow Bold, sans-serif' }}>
              {/* <div className='dcc' style={{ width: 'calc(100%)', cursor: 'pointer' }}><BarChart /></div>
              <div className='App' style={{ width: 'calc(100%)' }}><PieChart /></div>
              <div className='dcc' style={{ width: 'calc(100%)' }}><LineChart /></div> */}
              <div className='dcc' style={{ width: 'calc(100%)', cursor: 'pointer' }}><Barchart /></div>
              <div className='dcc'style={{ width: 'calc(100%)', cursor: 'pointer', maxHeight: '35vh' }}><Piechart Details={this.state.Details} /></div>
              <div className='dcc' style={{ width: 'calc(100%)' }}><HorizontalBarChart Details={this.state.Details}/></div>
            </div>
            {/* <div className='dcc' style={{ minHeight: '40vh', display: 'flex', flexDirection: 'row', width: '100%', height: '100%'}}>
              <iframe style={{ width: '100%', minHeight: '40vh', padding: 5 }} src="https://www.youtube.com/embed/fawHZZMJbCM" title="Shopee 9.9 Super Shopping Day" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <iframe style={{ width: '100%', minHeight: '40vh', padding: 5 }} src="https://www.youtube.com/embed/yQnCdJLotYk" title="Lazada&#39;s 10th EPIC Birthday Sale from March 27-29" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <iframe style={{ width: '100%', minHeight: '40vh', padding: 5 }} src="https://www.youtube.com/embed/ohRwr2Cnj7E" title="정국 (Jung Kook) &#39;GOLDEN&#39; Preview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <iframe style={{ width: '100%', minHeight: '40vh', padding: 5 }} src="https://www.youtube.com/embed/mHNCM-YALSA" title="정국 (Jung Kook) &#39;3D (feat. Jack Harlow)&#39; Official MV" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
            <div style={{ marginTop: 10, width: '98%', backgroundColor: 'rgba(245, 248, 250, .9)', minHeight: '60vh', marginBottom: 10, borderRadius: 10 }}>
              <div className='dcc' style={{ width: '100%', paddingTop: 10 }}>
                <div className={`service-slow-pop-up-text ${this.state.isActive ? 'active' : ''}`} style={{ color: 'grey', fontSize: '30px'}}>Features</div>
              </div>
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: 20, flexWrap: 'wrap'}}>
                {/* <div className='dcc dashboardFeaturesButton dashBShadow' style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div> */}
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
                <div className={`dcc dashboardFeaturesButton ${this.state.isActive ? 'active' : ''}`} style={{ display: 'flex', flexDirection: 'column', width: '10%', borderRadius: 5, minWidth: 400, margin: 10, minHeight: '20vh'}}>
                  <div>xxx</div>
                  <div>xxx</div>
                </div>
              </div>
            </div>
            {/* SampleDataset: [
        {SKUCode: 'sku006', containerCode: '001', Status: 'Active', zoneCode: 'Z', Quantity: '1500' },
        {SKUCode: 'sku050', containerCode: '005', Status: 'Active', zoneCode: 'A', Quantity: '2000' },
        {SKUCode: 'sku010', containerCode: '007', Status: 'Active', zoneCode: 'C', Quantity: '180' },
      ], */}
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: 10, padding: 10 , marginBottom: 50 }}>
              <div style={{ fontSize: '18px', color: 'grey', marginBottom: 5 }}>Sample Data Table</div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '95%', backgroundColor: 'lightgrey', padding: 10, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '14px' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>SKUCode</div>
                  <div style={{ width: 'calc(100%)'}}>containerCode</div>
                  <div style={{ width: 'calc(100%)'}}>zoneCode</div>
                  <div style={{ width: 'calc(100%)'}}>Quantity</div>
                  <div style={{ width: 'calc(100%)'}}>Status</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div>
                  </div>
                </div>
              </div>
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.SampleDataset.map((items, idd) => {
                  return(
                    <div key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '95%', padding: 5 }}>
                      {/* <div style={{ width: 'calc(100%)'}}>{items.SKUCode}</div> */}
                      <div style={{ width: 'calc(100%)'}}>
                        <Form.Control style={{ width: 'calc(100%/1.5)', fontSize: '14px' }} value={items.SKUCode} onChange={(e) => this.ChangeData(e.target.value, idd, 'SKUCode')}/>
                      </div>
                      <div style={{ width: 'calc(100%)'}}><Form.Control style={{ width: 'calc(100%/1.5)', fontSize: '14px' }} value={items.containerCode} onChange={(e) => this.ChangeData(e.target.value, idd, 'containerCode')}/></div>
                      <div style={{ width: 'calc(100%)'}}><Form.Control style={{ width: 'calc(100%/1.5)', fontSize: '14px' }} value={items.zoneCode} onChange={(e) => this.ChangeData(e.target.value, idd, 'zoneCode')}/></div>
                      <div style={{ width: 'calc(100%)'}}><Form.Control style={{ width: 'calc(100%/1.5)', fontSize: '14px' }} value={items.Quantity} onChange={(e) => this.ChangeData(e.target.value, idd, 'Quantity')}/></div>
                      <div style={{ width: 'calc(100%)', display: 'flex', alignItems: 'center'}}>{items.Status}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditStore(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteStore(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div style={{ minWidth: '10vh' }}>Page 1</div>
            </div>
          </div>
          {/* <div className='dcc' style={{ width: '100%', backgroundColor: '#27596E', minHeight: '20vh', color: 'white' }}>
            ss
          </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
