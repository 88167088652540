import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import Sidebar from '../Component/Sidebar';
import { Form } from 'react-bootstrap';
import Successign from '../image/Success1.gif';
import Xls from '../image/xls2.png';
import loading from '../image/loading2.gif';
import * as XLSX from 'xlsx';
import CategoryModal from '../Component/CategoryModal';
import { getCategoryFNC, DeleteDataFNC } from '../Api';
import ConfirmStoreExcelModal from '../Component/ConfirmStoreExcelModal';
import MessageModal from '../Component/MessageModal';
import moment from 'moment-timezone';
import { FaTrash, FaEdit} from 'react-icons/fa';
var timer

class CategoryMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShowDetail: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
      DisplayCateAdd: false,
      CategoryDetails: [],
      OpenModalType: '',
      CategorySearch: '',
      notificationMessage: '',
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      loading: false,
      ExcelUpload: [],
      DisplayExcelConfirm: false,
    }
    this.fileUpload = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.CallApi = this.CallApi.bind(this);
    this.UploadExcelFile = this.UploadExcelFile.bind(this);
    this.DeleteCategory = this.DeleteCategory.bind(this);
  }

  handleClose() {
    this.setState({DisplayCateAdd: false, CategoryDetails: [], OpenModalType: '', DisplayConfirmMessage: false, DisplayExcelConfirm: false, ExcelUpload: [] }, () => {
      this.CallApi();
    })
  }

  async CallApi() {
    this.setState({ loading: true })
    const getCategorys = await getCategoryFNC(this.state.CategorySearch, this.state.page, this.state.countperpage)
    console.log('getCategorys', getCategorys)
    if (getCategorys.message === 'Call success') {
      // console.log('getCategorys', getCategorys)
      this.setState({ CategoryDetails: getCategorys.Categorys, loading: false })
      if (getCategorys.Categorys.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getCategorys.Categorys[0].FullCount / this.state.countperpage,
          ),
        });
      }
    }
  }

  async componentDidMount() {
    this.CallApi();
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  EditCustomerClick(CategoryDetails) {
    let TestingCategoryDetails = {
      CategoryCode: CategoryDetails.CategoryCode,
      CategoryDesc: CategoryDetails.CategoryDesc,
      OtherDesc: CategoryDetails.OtherDesc,
      ID: CategoryDetails.ID,
    }
    this.setState({ CategoryDetails: TestingCategoryDetails, DisplayCateAdd: !this.state.DisplayCateAdd, OpenModalType: 'EditCategory' })
  }

  DeleteUser(SelectedUser) {
    this.setState({ DisplayConfirmMessage: true, CategoryDetails: SelectedUser, OpenModalType: 'Category' })
  }

  async DeleteCategory() {
    const DeleteCustomer = await DeleteDataFNC([this.state.CategoryDetails.ID], 'CategoryMaster');
    if (DeleteCustomer.message === 'Deleted') {
      this.openNotification('Success Deleted User Group');
      this.handleClose();
    }
  }
  
  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.CallApi()
    }
  }

  AddCetogory() {
    const ExcelAddData = this.state.ExcelUpload
    if (ExcelAddData.length > 0) {
      this.setState({ DisplayExcelConfirm: !this.state.DisplayExcelConfirm, OpenModalType: 'AddCategory' })
    } else {
      this.setState({ DisplayCateAdd: !this.state.DisplayCateAdd, OpenModalType: 'AddCategory' })
    }
  }

  UploadExcelFile(e) {
    var files = e.target.files, f = files[0];
    const reader = new FileReader();
    if (files.length > 0) {
      this.fileUpload.current.value = null;
      this.setState({ ExcelUpload: [] })
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.setState({ ExcelUpload: data })
      };
      reader.readAsBinaryString(f);
    }
  }

  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div> */}
        <Sidebar/>
        {this.state.DisplayCateAdd && 
        <CategoryModal DisplayCateAdd={this.state.DisplayCateAdd} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} CategoryDetails={this.state.CategoryDetails} CallApi={this.CallApi}/>}
        {this.state.DisplayExcelConfirm && 
        <ConfirmStoreExcelModal DisplayExcelConfirm={this.state.DisplayExcelConfirm} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} displayExcelData={this.state.ExcelUpload} />
        }
        {this.state.DisplayConfirmMessage && 
        <MessageModal DisplayConfirmMessage={this.state.DisplayConfirmMessage} handleClose={this.handleClose} DeleteUser={this.DeleteCategory} CustomerDetails={this.state.CategoryDetails} OpenModalType={this.state.OpenModalType}/>}
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: "white", display: 'flex', flexDirection: 'row' }}>
                {this.state.notificationMessage === '' && 
                <div>{'Category Master'}</div>
                }
                {this.state.notificationMessage !== '' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
                }
              </h3>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}>Custom Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Customer Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      {/* <Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/> */}
                      <Form.Control style={{ width: 'calc(100%)' }} value={this.state.CategorySearch} onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e) => this.setState({ CategorySearch: e.target.value })} placeholder='Search Category Code'/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15 }}>
                  <label htmlFor="file-Upload" style={{ marginRight: 10}}>
                    <img className='ExcelfileUpload'  style={{ backgroundColor: (this.state.ExcelUpload.length > 0) ? 'green' : '' }} for="file-Upload" src={Xls} alt='' />
                    <input ref={this.fileUpload} type='file' id="file-Upload" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                  </label>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => this.CallApi() }>Search</div>
                  <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.AddCetogory()}>Add Category</div>
                </div>
              </div>
            </div>
            {this.state.PODataLists.length > 0 && 
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Category Code</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>CategoryDesc</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>OtherDesc</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.CategoryDetails.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.CategoryDetails.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CategoryCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CategoryDesc}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.OtherDesc}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditCustomerClick(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteUser(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMaster);
