import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { language } from '../language';
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import React from 'react';
import { TimeZone } from '../Component/TimeZoneKL'
import { connect } from 'react-redux';
import moment from 'moment-timezone'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedExample: this.props.currentLanguage,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.runtime(),
      1000
    );
  }

  runtime() {
    this.setState({
      date: moment(this.state.date).add(1, 's').format('hh:mm:ss a YYYY-MM-DD')
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  setLanguage() {
    if(this.state.SelectedExample === 'cn') {
      this.setState({ SelectedExample: 'en' })
      this.props.setLanguage('en')
    } else {
      this.setState({ SelectedExample: 'cn' })
      this.props.setLanguage('cn')
    }
  }

  LogOutFucntion() {
    const r = (window.confirm(`${language[this.props.currentLanguage].LogoutConfirm}`));
    if (r === true) {
      window.location.href = '/';
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <Navbar sticky="top" expand="lg" variant="dark" className='HeaderBold' style={{ backgroundColor: '#D3D3D3', position: 'fixed', width: '100%', opacity: .9 }}>
          <Container fluid>
            <Navbar.Brand className='non-printable' href="/main">{language[this.props.currentLanguage].HomePage}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/Dynamic">{'Dynamic'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/Set">{'Update'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/Barcode">{'Barcode'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/Dashboard">{'Dashboard'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/POmodule">{'POmodule'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/DeliveryModule">{'DOModule'}</Navbar.Brand>
            <Navbar.Brand className='non-printable' href="/QuotationModule">{'Quotation'}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"/>
              <Nav>
                <Nav.Link href="#" disabled style={{color: 'white'}}>
                </Nav.Link>
                <div className='dcc' style={{ marginRight: 5 }}>{TimeZone()}</div>
                <Button variant="success" style={{fontWeight: 'bold'}} value={this.state.SelectedExample} onClick={() => this.setLanguage()}>{language[this.props.currentLanguage][this.state.SelectedExample]}</Button>
              </Nav>
              <Nav>
                <Nav.Link href="#" disabled style={{color: 'black', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Login}：{'DEMO'}</Nav.Link>
                <Button style={{fontWeight: 'bold'}}  variant="danger" onClick={() => { this.LogOutFucntion() }}>{language[this.props.currentLanguage].LogOut}</Button>
              </Nav>
            </Navbar.Collapse> 
          </Container>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
