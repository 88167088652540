import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import { AddStoreFNC, UpdateStoreFNC } from '../Api';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';

import { Button, Modal, Form } from 'react-bootstrap';
var timer

class CreateStoreMasterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StoreDesc: '',
      PricesLevel: '',
      PickingList: '',
      Address: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      StoreCodeLine: false,
      StoreDescLine: false,
      PricesLevelLine: false,
      PickingListLine: false,
      CustomerNameLine: false,
      RegisterNoLine: false,
      AddressLine: false,
      StoreTypeLine: false,
      MainStoreLine: false,
      OrderLine: false,
      StatusStoreLine: false,
      StoreGLine: false,
      OtherDescLine: false,
      GSTLine: false,
      SelectedID: '',
      StoreCode: '',
      OtherDesc: '',
      StoreType: '',
      MainStore: '',
      GSTNo: 0,
      RegisterNo: '',
      Order: 0,
      StatusStore: '',
      StoreTypeArray: ['consigment', 'outright'],
      StoreGArray: ['lazada', 'shopee', 'tiktok'],
      StatusArray: ['active', 'inactive'],
      StoreG: '',
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditStore') {
      this.setState({ 
        StoreDesc: this.props.StoreDetails.StoreDesc,
        StoreCode: this.props.StoreDetails.StoreCode,
        OtherDesc: this.props.StoreDetails.OtherDesc,
        StoreType: this.props.StoreDetails.StoreType,
        MainStore: this.props.StoreDetails.MainStore,
        GSTNo: this.props.StoreDetails.GSTNo,
        StoreG: this.props.StoreDetails.StoreG,
        RegisterNo: this.props.StoreDetails.RegisterNo,
        Address: this.props.StoreDetails.Address,
        PricesLevel: this.props.StoreDetails.PricesLevel,
        PickingList: this.props.StoreDetails.PickingList,
        OrderQty: this.props.StoreDetails.OrderQty,
        StatusStore: this.props.StoreDetails.StatusStore,
        SelectedID: this.props.StoreDetails.SelectedID,
      })
    }
  }

  async CreateStore(Type) {
    const StoreCode = this.state.StoreCode
    const StoreDesc = this.state.StoreDesc
    const OtherDesc = this.state.OtherDesc
    const StoreType = this.state.StoreType
    const MainStore = this.state.MainStore
    const GSTNo = this.state.GSTNo
    const RegisterNo = this.state.RegisterNo
    const Order = this.state.Order
    const StatusStore = this.state.StatusStore
    const StoreG = this.state.StoreG
    const Address = `${this.state.Address}`
    const PricesLevel = this.state.PricesLevel
    const PickingList = this.state.PickingList

    // PricesLevelLine: false,
    //   PickingListLine: false,
    //   CustomerNameLine: false,
    //   AddressLine: false,
    //   StoreTypeLine: false,
    //   MainStoreLine: false,
    //   OrderLine: false,
    //   StatusStoreLine: false,
    //   StoreGLine: false,

    if (StoreCode === '') {
      this.DisplayErrorMEssage('Please Insert StoreCode', 'StoreCodeLine')
    } else if (StoreDesc === '') {
      this.DisplayErrorMEssage('Please Insert StoreDesc', 'StoreDescLine')
    } else if (OtherDesc === '') {
      this.DisplayErrorMEssage('Please Insert OtherDesc', 'OtherDescLine')
    } else if (StoreType === '') {
      this.DisplayErrorMEssage('Please Select Store Type', 'StoreTypeLine')
    } else if (MainStore === '') {
      this.DisplayErrorMEssage('Please Insert Main Store', 'MainStoreLine')
    } else if (StoreG === '') {
      this.DisplayErrorMEssage('Please Insert Store Group', 'StoreGLine')
    } else if (RegisterNo === '') {
      this.DisplayErrorMEssage('Please Insert RegisterNo', 'RegisterNoLine')
    } else if (Address.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Address', 'AddressLine')
    } else if (PricesLevel === '') {
      this.DisplayErrorMEssage('Please Select Prices Level', 'PricesLevelLine')
    } else if (PickingList === '') {
      this.DisplayErrorMEssage('Please Insert PickingList', 'PickingListLine')
    } 
    // else if (Order === 0) {
    //   this.DisplayErrorMEssage('Please Insert Order Quatity', 'OrderLine')
    // } 
    else if (StatusStore === '') {
      this.DisplayErrorMEssage('Please Select Status', 'StatusStoreLine')
    }
    else {
      let FinalPassValue = {
        StoreCode: StoreCode,
        StoreDesc: StoreDesc,
        OtherDesc: OtherDesc,
        StoreType: StoreType,
        MainStore: MainStore,
        StoreGroup: StoreG,
        GSTNo: GSTNo,
        RegisterNo: RegisterNo,
        Address: Address,
        PriceLevel: PricesLevel,
        PickingList: PickingList,
        OrderQty: Order,
        StatusStore: StatusStore,
      }
      if (Type === 'EditStore') {
        const UpdateStore = await UpdateStoreFNC(FinalPassValue, this.state.SelectedID)
        if (UpdateStore.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Updated User ${StoreCode}`)
        }
      } else {
        const AddStore = await AddStoreFNC([FinalPassValue]);
        if (AddStore.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Store ${StoreCode}`)
        }
        if (AddStore.error === 'Duplicated Store Exist in your input') {
          this.DisplayErrorMEssage(`${AddStore.error}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 3000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
      this.props.CallApi();
    }, 2000);
  }

  SelectedOption(e, type) {
    this.setState({ [type]: e })
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayStoreAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditStore') ? `✎ Edit Store` : 'Create Store'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '60vh', maxHeight: '60vh', overflowX: 'auto' }}>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Store Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.StoreCodeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreCode} onChange={(e) => this.setState({ StoreCode: e.target.value })} placeholder='Store Code'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Store Desc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.StoreDescLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreDesc} onChange={(e) => this.setState({ StoreDesc: e.target.value })} placeholder='Store Desc'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Other Desc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.OtherDescLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.OtherDesc} onChange={(e) => this.setState({ OtherDesc: e.target.value })} placeholder='Other Desc'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Store Type</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              {/* <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.StoreTypeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreType} onChange={(e) => this.setState({ StoreType: e.target.value })} placeholder='Store Type'/>
              </div> */}
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)',  border: (this.state.StoreTypeLine === true) ? '1px solid red' : '1px solid lightgrey' }} 
                  value={this.state.StoreType} onChange={(e) => this.SelectedOption(e.target.value, 'StoreType')}>
                  <option value=''>-</option>
                  {this.state.StoreTypeArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Main Store</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.MainStoreLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.MainStore} onChange={(e) => this.setState({ MainStore: e.target.value })} placeholder='Main Store'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Store Group</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)',  border: (this.state.StoreGLine === true) ? '1px solid red' : '1px solid lightgrey' }} 
                  value={this.state.StoreG} onChange={(e) => this.SelectedOption(e.target.value, 'StoreG')}>
                  <option value=''>-</option>
                  {this.state.StoreGArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>GST No</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.GSTLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.GSTNo} onChange={(e) => this.setState({ GSTNo: e.target.value })} placeholder='GST'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Register No</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.RegisterNoLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.RegisterNo} onChange={(e) => this.setState({ RegisterNo: e.target.value })} placeholder='Register'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Address</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <textarea
                  style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.AddressLine === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  value={this.state.Address}
                  onChange={(e) => this.setState({ Address: e.target.value })}
                  rows="5"
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Price Level</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.PricesLevelLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.PricesLevel} onChange={(e) => this.setState({ PricesLevel: e.target.value })} placeholder='Prices Level'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Picking List</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.PickingListLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.PickingList} onChange={(e) => this.setState({ PickingList: e.target.value })} placeholder='Picking List'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Pon/Order Qty</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.OrderLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Order} onChange={(e) => this.setState({ Order: e.target.value })} placeholder='Order Qty'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Status Store</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)',  border: (this.state.StatusStoreLine === true) ? '1px solid red' : '1px solid lightgrey' }} 
                  value={this.state.StatusStore} onChange={(e) => this.SelectedOption(e.target.value, 'StatusStore')}>
                  <option value=''>-</option>
                  {this.state.StatusArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
                {/* <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.StatusStoreLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StatusStore} onChange={(e) => this.setState({ StatusStore: e.target.value })} placeholder='Status Store'/> */}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateStore()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateStore(this.props.OpenModalType)}>{(this.props.OpenModalType === 'EditStore') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStoreMasterModal);
