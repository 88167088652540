import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import { AddStoreFNC, AddCategoryFNC, createDepartmentMasterF } from '../Api';
import { Modal } from 'react-bootstrap';
var timer

class ConfirmStoreExcelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Name: '',
      Password: '',
      Group: '',
      Status: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      SelectedID: '',
    }
  }

  componentDidMount() {
  }

  async CreateDatas() {
    const OpenModalType = this.props.OpenModalType
    const Datas = this.props.displayExcelData
    if (OpenModalType === 'AddStore') {
      const CreateStore = await AddStoreFNC(Datas)
      if (CreateStore.message === 'Add success') {
        this.DisplaySuccessMEssage('Successful add Stores')
      }
      if (CreateStore.error) {
        this.DisplayErrorMEssage(`${CreateStore.error}`)
      }
    }
    if (OpenModalType === 'AddCategory') {
      const CreateCategory = await AddCategoryFNC(Datas)
      if (CreateCategory.message === 'Add success') {
        this.DisplaySuccessMEssage('Successful add Category')
      }
      if (CreateCategory.error) {
        this.DisplayErrorMEssage(`${CreateCategory.error}`)
      }
    }
    if (OpenModalType === 'AddDepartment') {
      const CreateDepartment = await createDepartmentMasterF(this.props.username, Datas)
      if (CreateDepartment.message === 'Successful') {
        this.DisplaySuccessMEssage('Successful add Department')
      }
      if (CreateDepartment.error) {
        this.DisplayErrorMEssage(`${CreateDepartment.error}`)
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 2000);
  }

  SetActiveUSer(event) {
    this.setState({ isChecked: event.target.checked })
  }

  render() {
    const OpenModalType = this.props.OpenModalType
    return (
      <Modal backdrop="static" centered show={this.props.DisplayExcelConfirm} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{`Review Upload Excel Datas ( ${this.props.displayExcelData.length} Datas )`}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {this.props.OpenModalType === 'AddStore' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '50vh', minHeight: '50vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white', minWidth: 1950 }}>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Store Code</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Store Desc</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Other Desc</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Store Type</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Main Store</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Store Group</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>GST No</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Register No</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Address</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Prices Level</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Picking List</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Order Qty</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Status Store</div>

                </div>
                {this.props.displayExcelData.map((items, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5, minWidth: 1950 }}>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.StoreCode}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.StoreDesc}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.OtherDesc}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.StoreType}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.MainStore}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.StoreGroup}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.GSTNo}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.RegisterNo}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.Address}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.PriceLevel}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.PickingList}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.OrderQty}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.StatusStore}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
            {this.props.OpenModalType === 'AddCategory' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '50vh', minHeight: '50vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white'}}>
                  {/* <div style={{ width: 'calc(100%)', minWidth: 150 }}>ID</div> */}
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Category Code</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>CategoryDesc</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>OtherDesc</div>

                </div>
                {this.props.displayExcelData.map((items, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5 }}>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.CategoryCode}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.CategoryDesc}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.OtherDesc}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            }
            {this.props.OpenModalType === 'AddDepartment' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '50vh', minHeight: '50vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white'}}>
                  {/* <div style={{ width: 'calc(100%)', minWidth: 150 }}>ID</div> */}
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Department Code</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Department Describe</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Other Describe</div>

                </div>
                {this.props.displayExcelData.map((items, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5 }}>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.DeptCode}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.DeptDesc}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.OtherDesc}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            }
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateDatas()}>
              {(OpenModalType === 'AddStore') ? 'Add Stores' : 'Adds'}
            </div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStoreExcelModal);
