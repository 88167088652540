import React, { Component } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
class HorizontalBarChart extends Component {
  render() {
    const { Details } = this.props;
    
    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          display: false,
        },
        title: {
          display: true,
          text: 'Horizontal Chart',
        },
      },
    };
    // const chartData = {
    //   labels: [`${Object.keys(this.props.Details)[0]}`, `${Object.keys(this.props.Details)[1]}`, `${Object.keys(this.props.Details)[2]}`, `${Object.keys(this.props.Details)[3]}`, `${Object.keys(this.props.Details)[4]}`],
    //   datasets: [
    //     {
    //       label: 'Dataset 1',
    //       data: [`${this.props.Details[Object.keys(this.props.Details)[0]]}`, `${this.props.Details[Object.keys(this.props.Details)[1]]}`, `${this.props.Details[Object.keys(this.props.Details)[2]]}`, `${this.props.Details[Object.keys(this.props.Details)[3]]}`, `${this.props.Details[Object.keys(this.props.Details)[4]]}`],
    //       borderColor: `${this.props.BarColor}`,
    //       backgroundColor: `${this.props.BarColor}`,
    //     },
    //   ],
    // };
    const chartData = {
      labels: ['1', '2', '3', '4'],
      datasets: [
        {
          label: 'Dataset 1',
          data: ['100', '1500', '2000', '500'],
          borderColor: `grey`,
          backgroundColor: `lightblue`,
        },
      ],
    };
    
    // return <Line data={chartData} />;
    return <Bar className='HoriChart' data={chartData} options={options} />;
  }
}

export default HorizontalBarChart;
