import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import Sidebar from '../Component/Sidebar';
import Xls from '../image/xls2.png';
import * as XLSX from 'xlsx';
import PrintLogo from '../image/Print.png';
import loading from '../image/loading2.gif';
import { Form } from 'react-bootstrap';
import { getUserManagementFNC, DeleteUserFNC, getUserGroupsFNC, DeleteDataFNC } from '../Api';
import Successign from '../image/Success1.gif';
import ConfirmExcelModal from '../Component/ConfirmExcelModal';
import CreateUserModal from '../Component/CreateUserModal';
import GroupAuthoSetupModal from '../Component/GroupAuthoSetupModal';
// import MessageModal from '../Component/MessageModal';
import moment from 'moment-timezone';
import { FaTrash, FaEyeSlash,  FaEye, FaEdit} from 'react-icons/fa';

var timer

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
      DisplayExcelConfirm: false,
      DisplayCusterAdd: false,
      DisplayUserGroupAdd: false,
      DisplayGroupAuthoAdd: false,
      UsersDetails: [],
      GroupAuthoDetails: [],
      OpenModalType: '',
      UserSearch: '',
      notificationMessage: '',
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      loading: false,
      DisplayConfirmMessage: false,
      CurrentSelectedPage: 'UserManage',
      ExcelAddData: [],
    }
    this.fileUpload = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.UploadExcelFile = this.UploadExcelFile.bind(this);
    // this.CallApi = this.CallApi.bind(this);
    // this.DeleteUsers = this.DeleteUsers.bind(this);
  }

  handleClose() {
    this.setState({
      DisplayCusterAdd: false,
      ExcelAddData: [],
      GroupAuthoDetails: [],
      UsersDetails: [],
      OpenModalType: '',
      DisplayConfirmMessage: false,
      DisplayGroupAuthoAdd: false,
      DisplayUserGroupAdd: false,
      DisplayExcelConfirm: false,
    }, () => {
      this.CallApi();
    })
  }

  async componentDidMount() {
    // const TestingApi = await getUserManagementDetailsHandler('')
    // console.log('TestingApi', TestingApi)
    this.CallApi();
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  // DeleteUser(SelectedUser) {
  //   this.setState({ DisplayConfirmMessage: true, UsersDetails: SelectedUser, OpenModalType: 'UserManagement' }, () => {
  //     console.log('UsersDetails', this.state.UsersDetails)
  //   })
  // }

  async DeleteUser(SelectedUser) {
    const r = (window.confirm(`Confirm Delete Selected User ?`));
    if (r === true) {
      const DeleteCustomer = await DeleteUserFNC([SelectedUser.ID]);
      if (DeleteCustomer.message === 'Deleted') {
        this.openNotification('Deleted Customer Success');
        this.handleClose();
      }
    } else {
      return null;
    }
  }

  async DeleteUserGroup(SelectedUserGroup) {
    const r = (window.confirm(`Confirm Delete Selected UserGroup ?`));
    if (r === true) {
      // const DeleteCustomer = await DeleteUserGroupFNC([SelectedUserGroup.ID]);
      // if (DeleteCustomer.message === 'Deleted') {
      //   this.openNotification('Success Deleted User Group');
      //   this.handleClose();
      // }
      const DeleteCustomer = await DeleteDataFNC([SelectedUserGroup.ID], 'UserGroup');
      if (DeleteCustomer.message === 'Deleted') {
        this.openNotification('Success Deleted User Group');
        this.handleClose();
      }
    } else {
      return null;
    }
  }

  EditCustomerClick(UsersDetails) {
    let TestingCustomerDetails = {
      Username: UsersDetails.Username,
      Name: UsersDetails.Name,
      Password: UsersDetails.Password,
      Group: UsersDetails.Group,
      Status: UsersDetails.Status,
      ID: UsersDetails.ID,
    }
    this.setState({ UsersDetails: TestingCustomerDetails, DisplayCusterAdd: !this.state.DisplayCusterAdd, OpenModalType: 'EditCustomer' })
  }

  EditAuthoGroup(Autho) {
    let GroupAuthoDetails = {
      Authority: {
        UserMaster: (Autho.UserMaster === 1) ? true : false,
        CustomerMaster: (Autho.CustomerMaster) ? true : false,
        ProductMaster: (Autho.ProductMaster) ? true : false,
        CategoryMaster: (Autho.CategoryMaster) ? true : false,
        DepartmentMaster: (Autho.DepartmentMaster) ? true : false,
        StoreMaster: (Autho.StoreMaster) ? true : false,
      },
      Status: (Autho.Active === 1) ? true : false,
      GroupCode: Autho.GroupCode,
      SelectedID: Autho.ID,
      Description: Autho.Description,
    }
    this.setState({ GroupAuthoDetails: GroupAuthoDetails, DisplayGroupAuthoAdd: !this.state.DisplayGroupAuthoAdd, OpenModalType: 'EditAuthoRity' })
  }

  async CallApi() {
    this.setState({ loading: true })
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    if (CurrentSelectedPage === 'UserManage') {
      const SearchUser = await getUserManagementFNC(this.state.UserSearch, this.state.page, this.state.countperpage)
      if (SearchUser.message === 'Call success') {
        this.setState({ UsersDetails: SearchUser.Users, loading: false })
        if (SearchUser.Users.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              SearchUser.Users[0].FullCount / this.state.countperpage,
            ),
          });
        }
      }
    }
    if (CurrentSelectedPage === 'GroupSetup') {
      const getUsergroup = await getUserGroupsFNC(this.state.UserSearch, this.state.page, this.state.countperpage)
      this.setState({ GroupAuthoDetails: getUsergroup.UserGroups, loading: false })
      if (getUsergroup.UserGroups.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getUsergroup.UserGroups[0].FullCount / this.state.countperpage,
          ),
        });
      }
    }
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.CallApi()
    }
  }

  UploadExcelFile(e) {
    // const CurrentSelectedPage = this.state.CurrentSelectedPage
    var files = e.target.files, f = files[0];
    const reader = new FileReader();
    if (files.length > 0) {
      this.fileUpload.current.value = null;
      this.setState({ ExcelAddData: [] })
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        // const data = XLSX.utils.sheet_to_csv(ws, {header:1});
        const data = XLSX.utils.sheet_to_json(ws);

        this.setState({ ExcelAddData: data })
      };
      reader.readAsBinaryString(f);
    }
  }

  async AddUsers() {
    const ExcelAddData = this.state.ExcelAddData
    if (ExcelAddData.length > 0) {
      this.setState({ DisplayExcelConfirm: !this.state.DisplayExcelConfirm, OpenModalType: 'AddUser' })
      // const CreateUsers = await CreateUsersFNC(ExcelAddData)
      // if (CreateUsers.message === 'Add success') {
      //   this.openNotification('Successful add users');
      //   this.setState({ ExcelAddData: [] }, () => {
      //     this.CallApi();
      //   })
      // }
    } else {
      this.setState({ DisplayCusterAdd: !this.state.DisplayCusterAdd, OpenModalType: 'AddCustomer' })
    }
  }

  async AddGroupSetUp() {
    const ExcelAddData = this.state.ExcelAddData
    if (ExcelAddData.length > 0) {
      // call api
    } else {
      this.setState({ DisplayGroupAuthoAdd: !this.state.DisplayGroupAuthoAdd, OpenModalType: 'AddGroupAutho' })
    }
  }

  PrintUsers() {
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    console.log('Print Me', CurrentSelectedPage)
  }
  
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Sidebar/>
        {this.state.DisplayExcelConfirm &&
        <ConfirmExcelModal DisplayExcelConfirm={this.state.DisplayExcelConfirm} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} displayExcelData={this.state.ExcelAddData} />}
        {this.state.DisplayCusterAdd &&
        <CreateUserModal DisplayCusterAdd={this.state.DisplayCusterAdd} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} UsersDetails={this.state.UsersDetails}/>}
        {this.state.DisplayGroupAuthoAdd &&
        <GroupAuthoSetupModal DisplayGroupAuthoAdd={this.state.DisplayGroupAuthoAdd} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} GroupAuthoDetails={this.state.GroupAuthoDetails}/>}
        {/* {this.state.DisplayConfirmMessage &&
        <MessageModal DisplayConfirmMessage={this.state.DisplayConfirmMessage} handleClose={this.handleClose} DeleteUser={this.DeleteUsers} CustomerDetails={this.state.UsersDetails} OpenModalType={this.state.OpenModalType}/>} */}
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: "white" }}>
                {this.state.notificationMessage === '' &&
                <div>{(this.state.CurrentSelectedPage === 'GroupSetup') ? 'User Group Setup' : 'User Management'}</div>
                }
                {this.state.notificationMessage !== '' &&
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
                }
              </h3>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '99%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}>{`Custom Search ${(this.state.CurrentSelectedPage === 'UserManage') ? '(Users)' : (this.state.CurrentSelectedPage === 'UsersGroup') ? '(Users Group)' : '(Group Setup)'}`}</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      {/* <Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/> */}
                      <Form.Control style={{ width: 'calc(100%)' }} onKeyPress={(e) => this.onKeyEnter(e)} value={this.state.UserSearch} onChange={(e) => this.setState({ UserSearch: e.target.value })} placeholder='Search'/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15 }}>
                  {this.state.CurrentSelectedPage === 'UserManage' && 
                  <label htmlFor="file-Upload" style={{ marginRight: 10 }}>
                    <img className='ExcelfileUpload' style={{ backgroundColor: (this.state.ExcelAddData.length > 0) ? 'green' : '' }} for="file-Upload" src={Xls} alt='Excel' />
                    <input ref={this.fileUpload} type='file' id="file-Upload" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                  </label>
                  }
                  <label style={{ marginRight: 10 }}>
                    <img className='ExcelfileUpload' src={PrintLogo} alt='Print' onClick={() => this.PrintUsers()} />
                    {/* <input type='file' id="file-Upload" className="form-control" onChange={() => this.PrintUsers()} /> */}
                  </label>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => this.CallApi()}>Search</div>
                  {this.state.CurrentSelectedPage === 'UserManage' &&
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.AddUsers()}>Add User</div>
                  }
                  {this.state.CurrentSelectedPage === 'GroupSetup' &&
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.AddGroupSetUp()}>Add Group</div>
                  }
                </div>
              </div>
            </div>
            <div style={{ width: '99%', display: 'flex', flexDirection: 'row' }}>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'UserManage') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 150, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'UserManage') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'UserManage', page: 1 }, () => { this.CallApi(); })}>
                {'Users Management'}
              </div>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'GroupSetup') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 150, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'GroupSetup') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'GroupSetup', page: 1 }, () => { this.CallApi(); })}>
                {'User Group Setup'}
              </div>
            </div>
            {this.state.CurrentSelectedPage === 'UserManage' && 
            <div className='dcc' style={{ width: '99%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Username</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 , display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center' }} onClick={() => this.setState({ DisplayPass: !this.state.DisplayPass })}>
                    <div>Password</div>
                    {(this.state.DisplayPass)?
                      <FaEyeSlash/>
                      : 
                      <FaEye/>}
                  </div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>UserGroup</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Status</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.UsersDetails.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.UsersDetails.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Username}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Name}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{(this.state.DisplayPass === true) ? `${items.Password}` : '******'}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Group}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100, display: 'flex' }}>
                        {items.Status === '1' ? (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Active</div>
                        ) : (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#EB921B', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Not Active</div>
                        )}
                      </div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditCustomerClick(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteUser(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
            {this.state.CurrentSelectedPage === 'GroupSetup' && 
            <div className='dcc' style={{ width: '99%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Group Code</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Desc</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Status</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.GroupAuthoDetails.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.GroupAuthoDetails.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.GroupCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Description}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100, display: 'flex' }}>
                        {items.Active === 1 ? (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Active</div>
                        ) : (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#EB921B', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Not Active</div>
                        )}
                      </div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditAuthoGroup(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteUserGroup(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
