import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import { Modal, Table, Alert } from 'react-bootstrap';
import moment from 'moment-timezone';
import Form from 'react-bootstrap/Form'
import Barcode from 'react-barcode';

var timer

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      showNotification: false,
    }
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  handleNumber(e) {
    this.setState({value: e.target.value})
  }


  
  render() {
    return (
      <div>
        <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div className="dcc" style={{ width: 'calc(100%)', }}>
          <div  style={{ width: 'calc(50%)', marginTop: 50,  }}>
            <div   className="dcc" style={{ display: 'flex', width: 'calc(75%)', border: "1px solid black"}}>
              <div  id="appear" className='dcc' style={{ display: 'flex', flexDirection: 'column', width: 'calc(80%)',  justifyContent: 'center'}}>
                <div    style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'  }}>
                  <div style={{  fontWeight: 'bold' }}>{''}</div>
                  <div style={{   fontWeight: 'bold',  fontSize: '26px', marginBottom: 40  }}>{'Sample'}</div>
                  <div style={{  fontWeight: 'bold',  fontSize: '26px', marginBottom: 40}}>{'STA'}</div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <div style={{ width: '40%', fontWeight: 'bold' }}>{'Shipped Date :'}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '20px', fontWeight: '500'}}  value={'03 MAR 2023'} readOnly/>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Order Date :'}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '20px', fontWeight: '500'}}  value={'02 MAR 2023'} readOnly/>
                </div>
                <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <div>
                    <div style={{ width: '35%', fontWeight: 'bold', marginBottom: 20 }}>{'COD '}</div>
                    <div style={{ width: '35%', fontWeight: 'bold' }}>{'_____________________________'}</div>
                    <div style={{ width: '60%', fontSize: 12, fontWeight: 'bold' }}>Receive's Name & Signature</div>
                  </div>
                  <div>
                    <Barcode
                      format="CODE128"
                      value={"1234411111"}
                      height={60}
                    />
                  </div>
                </div>
                <div  style={{ width: '100%', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Outlet '}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500'}}  value={'1 Utama'} readOnly/>
                </div>
                <div  style={{ width: '100%', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Address '}</div>
                  <div style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500', 
                    display: 'block', padding: '0.375rem 0.75rem', border: '1px solid #ced4da',
                    borderRadius: '0.25rem', backgroundColor: '#e9ecef', opacity: 1,
                    overflowWrap: 'break-word'}}> {'F331, 1st Floor, Oval, 1 Utama Shopping Mall, Lebuh Bandar Utama, Bandar Utama, 47800 Petaling Jaya, Selangor'}</div>
                </div>
                <div  style={{ width: '100%', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Box '}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500'}}  value={'1/5'} readOnly/>
                </div>
                <div  style={{ width: '100%', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Quatity'}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500'}}  value={'120'} readOnly/>
                </div>
                <div  style={{ width: '100%', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Shipped thru'}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500'}}  value={'Ninja Van'} readOnly/>
                </div>
                <div  style={{ width: '100%', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                  <div style={{ width: '35%', fontWeight: 'bold' }}>{'Description'}</div>
                  <Form.Control style={{ width: 'calc(60%)',fontSize: '17px', fontWeight: '500'}}  value={'Blue Polo'} readOnly/>
                </div>
                <div className="dcc" style={{ display: 'flex', flexWrap: 'wrap', width: 'calc(80%)' }}>
                  <Barcode
                    format="CODE128"
                    value={"123456789101112"}
                  />
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
