import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form'
import ShowPass from '../image/ShowPass.png';
import HidePass from '../image/HidePass.png';
import Errorsign from '../image/errorsign.gif';
import Container from 'react-bootstrap/Container'
import { loginFNC } from '../Api';

var timer

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Password: '',
      loading: false,
      ShowPassword: false,
      MessageType: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UsernameInput = React.createRef();
    this.PasswordInput = React.createRef();
  }
  async componentDidMount() {
    console.log('xxxx', window.location.protocol)
    this.UsernameInput.focus();
  }


  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.Username === '') {
      alert(`请输入用户名`);
      this.props.setLoginStatus('', '', '');
      this.setState({ loading: false})
    } else if (this.state.Password === '') {
      alert(`请输入密码`);
      this.setState({ loading: false})
      this.props.setLoginStatus('', '', '');
    }  
    else {
      const getLogin = await loginFNC(this.state.Username, this.state.Password)
      console.log('getLogin', getLogin)
      if (getLogin.error === 'Your account and/or password is incorrect, please try again') {
        this.DisplayMEssage(getLogin.error)
      } else {
        this.props.setLoginStatus(
          getLogin[0].Username,
          getLogin[0].Group,
          'asdasdasdads',
        )
        localStorage.setItem("username", getLogin[0].Username);
        localStorage.setItem("role", getLogin[0].Group);
        localStorage.setItem("token", 'asdasdasdads');
        this.props.history.push('/Dashboard')
      }
    }
  }
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  DisplayMEssage(type) {
    this.setState({ MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
      });
    }, 5000);
  }

  render() {
    return (
      <Container className='dcc LoginWallpaper' fluid style={{ height: '100vh', display: 'flex', flexDirection: 'column', fontFamily: 'cursive'}}>
        {/* <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '35px' }}>WMS</div> */}
        <div className='dcc' style={{flexDirection: 'column', width: '100%'}}>
          <div className='dcc LoginContainer' style={{ backgroundColor: 'white'}}>
            <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '35px' }}>EWMS</div>
            {this.state.MessageType !== '' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageType}</div>
            }
            <div className='dcc USernameLogin'>
              <div className='ChangePassName'>用户名</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  value={this.state.Username}
                  ref={(input) => { this.UsernameInput = input; }}
                  type='text' style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ Username: e.target.value })}
                />
              </div>
            </div>
            <div className='dcc ChangePAsswordTextView'>
              <div className='ChangePassName'>密码</div>
              <div className='ChangePassInput'>
                <Form.Control 
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  ref={(input) => { this.PasswordInput = input; }}
                  value={this.state.Password}
                  type={(this.state.DisplayPass === true) ? 'text' : 'password'} style={{ marginRight: 10, width: '100%', backgroundColor: 'white' }}
                  onChange={(e) => this.setState({ Password: e.target.value })}
                />
                <div style={{ width: '5%', cursor: 'pointer' }} onClick={() => this.setState({ DisplayPass: !this.state.DisplayPass })}>
                  {this.state.DisplayPass === true ? (
                    <img src={ShowPass} alt="Logo" className='PasswordEye' />
                  ) : (
                    <img src={HidePass} alt="Logo" className='PasswordEye' />
                  )}
                </div>
              </div>
            </div>
            <button className="pushable" style={{ minWidth: 150, padding: 2 }} onClick={() => this.handleSubmit()}>
              <span className="front" style={{ fontWeight: 'bold' }}>
                登入
              </span>
            </button>
          </div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (username, role, token) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: username,
          role: role,
          token: token,
        },
      });
    },
    setCompanyData: (CompanyData) => {
      dispatch({
        type: 'COMPANY_STATUS',
        payload: {
          CompanyData: CompanyData,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
  