import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import * as XLSX from 'xlsx';
import Xls from '../image/xls2.png';
import loading from '../image/loading2.gif';
import Sidebar from '../Component/Sidebar';
import Successign from '../image/Success1.gif';
import { Form } from 'react-bootstrap';
import CreateStoreMasterModal from '../Component/CreateStoreMasterModal';
import ConfirmStoreExcelModal from '../Component/ConfirmStoreExcelModal';
import { FaTrash, FaEdit} from 'react-icons/fa';
import ExportExcel from '../image/ExportExcel.png';
import { ExportStore } from '../jsonToXlsx';
import { getStoreMasterFNC, DeleteDataFNC, GetExcelDataFNC } from '../Api';
import moment from 'moment-timezone';

var timer

class StoreMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
      DisplayStoreAdd: false,
      StoreDetails: [],
      OpenModalType: '',
      StoreSearch: '',
      TestingStoreDetails: {
        CustomerComponyName: 'Hitachi Sdn Bhd',
        AnntName: 'Testing',
        Tel: '0380655533',
        CompanyAddress: 'Level 38, MYEG Tower, Empire City Damansara, Jalan PJU 8, Damansara Perdana, Damansara Perdana, 47820 Petaling Jaya, Selangor',
      },
      ExcelValues: '',
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      notificationMessage: '',
      DisplayExcelConfirm: false,
      ExcelUpload: [],
    }
    this.fileUpload = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.UploadExcelFile = this.UploadExcelFile.bind(this);
    this.CallApi = this.CallApi.bind(this);
  }

  handleClose() {
    this.setState({DisplayStoreAdd: false, StoreDetails: [], OpenModalType: '', StoreSearch: '', DisplayExcelConfirm: false, ExcelUpload: [] }, () => {
      this.CallApi();
    })
  }

  async CallApi() {
    this.setState({ loading: true })
    const getAllStore = await getStoreMasterFNC(this.state.StoreSearch, this.state.page, this.state.countperpage)
    if (getAllStore.message === 'Call success') {
      this.setState({ 
        StoreDetails: getAllStore.Stores, loading: false,
      })
      if (getAllStore.Stores.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getAllStore.Stores[0].FullCount / this.state.countperpage,
          ),
        });
      }
    }

  }

  async componentDidMount() {
    this.CallApi();
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  EditClicked() {
    console.log('Clicked Edit')
  }

  EditStore(StoreDetails) {
    let StoreDetail = {
      StoreCode: StoreDetails.StoreCode,
      StoreDesc: StoreDetails.StoreDesc,
      OtherDesc: StoreDetails.OtherDesc,
      StoreType: StoreDetails.StoreType,
      MainStore: StoreDetails.MainStore,
      GSTNo: StoreDetails.GSTNo,
      StoreG: StoreDetails.StoreGroup,
      RegisterNo: StoreDetails.RegisterNo,
      Address: StoreDetails.Address,
      PricesLevel: StoreDetails.PriceLevel,
      PickingList: StoreDetails.PickingList,
      OrderQty: StoreDetails.OrderQty,
      StatusStore: StoreDetails.StatusStore,
      SelectedID: StoreDetails.ID,
    }
    this.setState({ StoreDetails: StoreDetail, DisplayStoreAdd: !this.state.DisplayStoreAdd, OpenModalType: 'EditStore' })
  }

  UploadExcelFile(e) {
    var files = e.target.files, f = files[0];
    const reader = new FileReader();
    if (files.length > 0) {
      this.fileUpload.current.value = null;
      this.setState({ ExcelUpload: [] })
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.setState({ ExcelUpload: data })
      };
      reader.readAsBinaryString(f);
    }
  }

  async DeleteStore(SelectedStore) {
    const r = (window.confirm(`Confirm Delete Selected Store ?`));
    if (r === true) {
      const DeleteCustomer = await DeleteDataFNC([SelectedStore.ID], 'StoreMaster');
      if (DeleteCustomer.message === 'Deleted') {
        this.openNotification('Success Deleted Store');
        this.handleClose();
      }
    } else {
      return null;
    }
  }

  AddStore() {
    const ExcelAddData = this.state.ExcelUpload
    if (ExcelAddData.length > 0) {
      this.setState({ DisplayExcelConfirm: !this.state.DisplayExcelConfirm, OpenModalType: 'AddStore' })
    } else {
      this.setState({ DisplayStoreAdd: !this.state.DisplayStoreAdd, OpenModalType: 'AddStore' })}
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.CallApi()
    }
  }

  async ExportExcelFile() {
    let ExcelDisplayData = []
    const getExceldata = await GetExcelDataFNC(this.state.StoreSearch, 'StoreMaster');
    if (getExceldata.message === 'Call success') {
      ExcelDisplayData = getExceldata.ReturnExcelData
      ExportStore(ExcelDisplayData)
    }
  }
  
  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div> */}
        <Sidebar/>
        {this.state.DisplayStoreAdd && 
        <CreateStoreMasterModal DisplayStoreAdd={this.state.DisplayStoreAdd} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} StoreDetails={this.state.StoreDetails} CallApi={this.CallApi}/>}
        {this.state.DisplayExcelConfirm && 
        <ConfirmStoreExcelModal DisplayExcelConfirm={this.state.DisplayExcelConfirm} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} displayExcelData={this.state.ExcelUpload} />
        }
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              {this.state.notificationMessage === '' &&
                <h3 style={{ marginRight: 10, color: "white", fontWeight: 'bold' }}>{'Store Master'}</h3>
              }
              {this.state.notificationMessage !== '' &&
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
              }
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}> Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      <Form.Control style={{ width: 'calc(100%)' }} onKeyPress={(e) => this.onKeyEnter(e)}  value={this.state.StoreSearch} onChange={(e) => this.setState({ StoreSearch: e.target.value })} placeholder='Search'/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15 }}>
                  <label htmlFor="file-Upload" style={{ marginRight: 10}}>
                    <img className='ExcelfileUpload'  style={{ backgroundColor: (this.state.ExcelUpload.length > 0) ? 'green' : '' }} for="file-Upload" src={Xls} alt='' />
                    <input ref={this.fileUpload} type='file' id="file-Upload" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                  </label>
                  <label style={{ marginRight: 10 }}>
                    <img className='ExcelfileUpload' src={ExportExcel} alt='Print' onClick={() => this.ExportExcelFile() } />
                  </label>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => { this.CallApi(); }}>Search</div>
                  <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.AddStore()}>Add Store</div>
                </div>
              </div>
            </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Store Code</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Store Desc</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Store Type</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Main Store</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Store Group</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>GST No</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Register No</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Status Store</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.StoreDetails.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.StoreDetails.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreDesc}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreType}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.MainStore}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreGroup}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.GSTNo}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.RegisterNo}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100, display: 'flex' }}>
                        {items.StatusStore === 'active' ? (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Active</div>
                        ) : (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#EB921B', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Not Active</div>
                        )}
                      </div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditStore(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteStore(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreMaster);
