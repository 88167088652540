import '../App.css';
import React, {Fragment} from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import { language } from '../language';
// import Header from '../Component/Header';
// import Sidebar from './Sidebar';
import { FaTrash, FaFastBackward, FaBackspace } from 'react-icons/fa';
import { Modal, Table, Alert, Form } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class CreateQuotation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Customers: [
        { value: 'Tam Kah Lok', label: 'Tam KAh Lok' },
        { value: 'SongJJ', label: 'SongJJ' },
        { value: 'StephenCurry', label: 'StephenCurry' },
      ],
      WareHouses: [
        { value: 'Warehouse1', label: 'Warehouse 1' },
        { value: 'Warehouse2', label: 'Warehouse 2' },
        { value: 'Warehouse3', label: 'Warehouse 3' },
      ],
      Products: [
        { value: 'IPhone 15', label: 'IPhone 15' },
        { value: 'Honor Magic 5 Pro', label: 'Honor Magic 5 Pro' },
        { value: 'SamSung Galaxy S23 Ultra', label: 'SamSung Galaxy S23 Ultra' }
      ],
      SelectedUserDetails: {
        Name: '',
        Email: '',
        Address: '',
        Contact: '',
      },
      SelectedUser: '',
      SelectedProducts: '',
      ProductDiscount: '',
      SelectedWareHouses: '',
      FinalDiscount: '',
      ProductQuantity: '',
      ItemsPrices: '',
      ItemSalesPrice: '',
      QuotationNote: '',
      ModalShowDetail: false,
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      QuotationTotalItems: [],
      InsertItems: {
        ProductItem: '',
        Quantity: '',
        Price: '',
        Discount: '',
        Total: '',
      },
      QuotationTotal: 0,
      TotalDiscount: 0,
      GrandTotal: 0,
    }
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  EditClicked() {
    console.log('Clicked Edit')
  }

  ReceivedClicked() {
    console.log('Clicked Received')
  }

  SelectedUser(e, type){
    if (e === null) {
      this.setState({ [type]: '' })
      if (type === 'SelectedUser') {
        this.setState({ SelectedUserDetails: {
          Name: '',
          Email: '',
          Address: '',
          Contact: '',
        } })
      }
    } else {
      this.setState({ [type]: e }, () => {
        if (type === 'SelectedUser') {
          this.getUserDetails();
        }
      })
    }
  }

  async SelectedProduct(e, type) {
    if (e === null) {
      this.setState({ [type]: '', ProductDiscount: '', ProductQuantity: '', ItemsPrices: '', ItemSalesPrice: '', QuotationNote: ''})
    } else {
      this.setState({ [type]: e, ItemsPrices: '150', ItemSalesPrice: '150' })
    }
  }

  getUserDetails() {
    let SelectedUserDetails = this.state.SelectedUserDetails
    SelectedUserDetails.Name = 'Stephen Curry'
    SelectedUserDetails.Email = 'Curry@gmail.com'
    SelectedUserDetails.Address = 'America'
    SelectedUserDetails.Contact = '0123456789'
    this.setState({ SelectedUserDetails }, ()=> {
      console.log('SelectedUserDetails', this.state.SelectedUserDetails)
    })
  }

  AddProduct() {
    const ProductDiscount = this.state.ProductDiscount 
    const ProductQuantity = this.state.ProductQuantity
    const ItemSalesPrice = this.state.ItemSalesPrice 
    const SelectedProducts = this.state.SelectedProducts

    let QuotationTotalItems = this.state.QuotationTotalItems
    if(ProductQuantity !== '' && ItemSalesPrice !== '' && ProductDiscount !== '') {
      // calculate Discount 
      const discountedPrice = ItemSalesPrice - (ItemSalesPrice * ProductDiscount) / 100;
      const total = discountedPrice * ProductQuantity;
      const newArrayA = [
        ...QuotationTotalItems,
        {ProductItem: SelectedProducts.value,
          Quantity: ProductQuantity,
          Price: ItemSalesPrice,
          Discount: ProductDiscount,
          Total: total,
          QuoteNote: this.state.QuotationNote
        }
      ];
      this.setState({ QuotationTotalItems: newArrayA }, () => {
        this.AddProducted();
      });
    } 
  }

  RemoveProduct(index) {
    let QuotationTotalItems = this.state.QuotationTotalItems
    // let FilterSlideImage = QuotationTotalItems.filter(function (el) {
    //   return el.ProductItem !== Product;
    // });
    const updatedItems = [...QuotationTotalItems.slice(0, index), ...QuotationTotalItems.slice(index + 1)];
    this.setState({ QuotationTotalItems: updatedItems }, () => {
      this.AddProducted();
    })
  }

  AddProducted() {
    const QuotationTotalItems = this.state.QuotationTotalItems
    const grandTotal = QuotationTotalItems.reduce((total, item) => total + item.Total, 0);
    const totalDiscount = QuotationTotalItems.reduce((total, item) => {
      const discountAmount = (item.Price * item.Quantity * item.Discount) / 100;
      return total + discountAmount;
    }, 0);
    this.setState({ 
      ProductDiscount: '', ProductQuantity: '', ItemsPrices: '', SelectedProducts: '', ItemSalesPrice: '', QuotationNote: '', QuotationTotal: grandTotal, TotalDiscount: totalDiscount,
    })
  }

  async SubmitQuotes() {
    this.props.history.push({
      pathname: '/QuotationModule',
      state: 'Complete Quotes !!',
    })
  }

  BackHome() {
    this.props.history.push({
      pathname: '/QuotationModule',
      state: '',
    })
  }
  
  render() {
    return (
      <div style={{ backgroundColor: '#F1F0F7', minHeight: '95vh', display: 'flex', flexDirection: 'row' }}>
        <Sidebar/>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '12px', width: '100%' }}>
          <div  style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <div style={{ fontSize: '23px', paddingRight: 10, cursor: 'pointer' }} onClick={() => this.BackHome()}><FaFastBackward /></div>
              <h3 style={{ marginRight: 10 }}>{'Create Quotataion'}</h3>
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 20, padding: 25, backgroundColor: 'white' }}>
              {/* <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: 'lightgrey', fontWeight: 'bold' }}>Custom Search</div> */}
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30 }}>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                      <div className='dcc' style={{ width: 40, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, fontSize: '25px' }}>⌕</div>
                      <div style={{ width: '80%' }}>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.Customers}
                          value={this.state.SelectedUser}
                          placeholder={'Search Customer'}
                          onChange={(e) => this.SelectedUser(e, 'SelectedUser')}
                          // defaultValue={this.state.Customers}
                          isClearable={true}
                          isSearchable={true}
                          name="Customer"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ padding : 10 }}></div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                      <div className='dcc' style={{ width: 40, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, fontSize: '23px' }}>🏚</div>
                      <div style={{ width: '80%' }}>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.WareHouses}
                          value={this.state.SelectedWareHouses}
                          placeholder={'Search Warehouse'}
                          onChange={(e) => this.SelectedUser(e, 'SelectedWareHouses')}
                          isClearable={true}
                          isSearchable={true}
                          name="Warehouse"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.SelectedUserDetails.Name !== '' && 
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                      <div style={{ width: '95%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
                          <div style={{ color: '#5DB5DD', fontWeight: 'bold', padding: 5 }}>Name</div>
                          <div style={{ color: '#5DB5DD', fontWeight: 'bold', padding: 5 }}>Email</div>
                          <div style={{ color: '#5DB5DD', fontWeight: 'bold', padding: 5 }}>Address</div>
                          <div style={{ color: '#5DB5DD', fontWeight: 'bold', padding: 5 }}>Contact</div>
                        </div>
                        <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                          <div style={{ fontWeight: 'bold', padding: 5 }}>{this.state.SelectedUserDetails.Name}</div>
                          <div style={{ fontWeight: 'bold', padding: 5 }}>{this.state.SelectedUserDetails.Email}</div>
                          <div style={{ fontWeight: 'bold', padding: 5 }}>{this.state.SelectedUserDetails.Address}</div>
                          <div style={{ fontWeight: 'bold', padding: 5 }}>{this.state.SelectedUserDetails.Contact}</div>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding : 10 }}></div>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}></div>
                  </div>
                }
              </div>
            </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 20, padding: 25, backgroundColor: 'white' }}>
              <div style={{ width: '100%',  padding: 5, color: 'grey', fontSize: '18px', paddingBottom: 20 }}>{`PRODUCT DETAILS (${this.state.QuotationTotalItems.length})`}</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30 }}>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                      <div className='dcc' style={{ width: 40, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, fontSize: '25px' }}>⌕</div>
                      <div style={{ width: '80%' }}>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.Products}
                          value={this.state.SelectedProducts}
                          placeholder={'Search Product Here'}
                          onChange={(e) => this.SelectedProduct(e, 'SelectedProducts')}
                          isClearable={true}
                          isSearchable={true}
                          name="Product"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ padding : 10 }}></div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                      <div className='dcc' style={{ width: 140, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: 'grey', fontSize: '13px', fontWeight: 'bold' }}>Quotation Note</div>
                      <div style={{ width: '65%' }}>
                        <Form.Control value={this.state.QuotationNote} 
                          onChange={(e) => this.setState({ QuotationNote: e.target.value })}/>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.SelectedProducts !== '' && 
                <>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30, paddingTop: 30 }}>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                        <div className='dcc' style={{ width: 40, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: 'grey', fontSize: '14px', fontWeight: 'bold' }}>O</div>
                        <div style={{ width: '80%' }}>
                          <Form.Control disabled value={this.state.SelectedProducts.label}/>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding : 10 }}></div>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                        <div className='dcc' style={{ width: 140, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: 'grey', fontSize: '13px', fontWeight: 'bold' }}>Price</div>
                        <div style={{ width: '65%' }}>
                          <Form.Control value={this.state.ItemsPrices} onChange={(e) => this.setState({ ItemsPrices: e.target.value})} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                        <div className='dcc' style={{ width: 40, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: 'grey', fontSize: '13px', fontWeight: 'bold' }}>%</div>
                        <div style={{ width: '80%' }}>
                          <Form.Control value={this.state.ProductDiscount} onChange={(e) => this.setState({ ProductDiscount: e.target.value})}/>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding : 10 }}></div>
                    <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                        <div className='dcc' style={{ width: 140, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: 'grey', fontSize: '13px', fontWeight: 'bold' }}> Sales Price</div>
                        <div style={{ width: '65%' }}>
                          <Form.Control value={this.state.ItemSalesPrice} onChange={(e) => this.setState({ ItemSalesPrice: e.target.value})} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                      <div className='dcc' style={{ width: 100, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: '#26586D', fontSize: '13px', fontWeight: 'bold' }}>Quantity</div>
                      <div style={{ width: '25%', marginRight: 20 }}>
                        <Form.Control value={this.state.ProductQuantity} onChange={(e) => this.setState({ ProductQuantity: e.target.value})}/>
                      </div>
                    </div>
                    <div style={{ padding : 10 }}></div>
                    <div className='dcc' style={{ width: '100%', flexDirection: 'row', marginRight: 10, display: 'flex', justifyContent: 'flex-end'}}>
                      <div className='dcc ShadowButton' style={{ borderRadius: 10, backgroundColor: '#26586D', color: 'white', padding: 10,  cursor: 'pointer', width: '30%' }} onClick={()=> {this.AddProduct()}}>Add Product</div>
                    </div>
                  </div>
                </>
                }
              </div>
              <div style={{ width: '96%', borderBottom: '1px solid #26586D', padding: 10, backgroundColor: '#26586D', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
                <div style={{ width: 'calc(100%)'}}>Item Name</div>
                <div style={{ width: 'calc(100%)'}}>Quantity</div>
                <div style={{ width: 'calc(100%)'}}>Price</div>
                <div style={{ width: 'calc(100%)'}}>{`Discount(%)`}</div>
                <div style={{ width: 'calc(100%)'}}>Total</div>
              </div>
              {this.state.QuotationTotalItems.length > 0 && 
              <>
                {this.state.QuotationTotalItems.map((items,idn) => {
                  return(
                    <div key={idn} style={{ width: '96%', padding: 10, backgroundColor: 'white', display: 'flex', flexDirection: 'row', color: 'black', borderBottom: '1px solid lightgrey' }}>
                      <div style={{ width: 'calc(100%)'}}>{items.ProductItem}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.Quantity}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.Price}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.Discount}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>{items.Total}</div>
                        <div style={{ cursor: 'pointer' }} onClick={() => this.RemoveProduct(idn)}><FaTrash /></div>
                      </div>
                    </div>
                  );
                })}
              </>
              }
              <div className='dcc' style={{ width: '100%', color: 'lightgrey', marginBottom: 10, justifyContent: 'flex-start' }}>{`___________________________________________________________________________________________`}</div>
              <div style={{ width: '96%', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 10 }}>
                <div style={{ width: '60%'}}>
                  <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                    <div className='dcc' style={{ width: 100, backgroundColor: 'lightgrey', borderRadius: 2, marginRight: 1, color: '#26586D', fontSize: '13px', fontWeight: 'bold' }}>%</div>
                    <div style={{ width: '25%', marginRight: 20 }}>
                      <Form.Control value={this.state.FinalDiscount} 
                        onChange={(e) => this.setState({ FinalDiscount: e.target.value })}/>
                    </div>
                    <div className='dcc ShadowButton' style={{ borderRadius: 10, backgroundColor: '#26586D', color: 'white', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }} onClick={() => this.SubmitQuotes()}>Submit Quotation</div>
                  </div>
                </div>
              </div>
              <div style={{ width: '96%', flex: '50%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <div style={{ marginRight: 20, fontWeight: 'bold', fontSize: '14px', color: 'grey' }}>{'Before Discount:'}</div>
                <div style={{ width: '10%', justifyContent: 'flex-end', display: 'flex', fontSize: '14px', color: 'grey', fontWeight: 'bold' }}>{(this.state.QuotationTotal + this.state.TotalDiscount).toFixed(2)}</div>
              </div>
              <div style={{ width: '96%', flex: '50%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <div style={{ marginRight: 20, fontWeight: 'bold', fontSize: '14px', color: 'grey' }}>{'Total Discount:'}</div>
                <div style={{ width: '10%', justifyContent: 'flex-end', display: 'flex', fontSize: '14px', color: 'grey', fontWeight: 'bold' }}>{this.state.TotalDiscount.toFixed(2)}</div>
              </div>
              <div style={{ width: '96%', flex: '50%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <div style={{ marginRight: 20, fontWeight: 'bold', fontSize: '14px', color: 'grey' }}>{'Quotataion Total:'}</div>
                <div style={{ width: '10%', justifyContent: 'flex-end', display: 'flex', fontSize: '14px', color: 'grey', fontWeight: 'bold' }}>{this.state.QuotationTotal.toFixed(2)}</div>
              </div>
              <div style={{ width: '96%', flex: '50%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                <div style={{ marginRight: 20, fontWeight: 'bold', fontSize: '14px', color: '#26586D' }}>{'Grand Total Total:'}</div>
                <div style={{ width: '10%', justifyContent: 'flex-end', display: 'flex', fontSize: '14px', color: '#26586D', fontWeight: 'bold' }}>{this.state.GrandTotal.toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotation);
