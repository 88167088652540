import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

class DoughnutPieChart extends Component {
  render() {
    const { Details } = this.props;

    // const chartData = {
    //   labels: ['Shopee', 'Lazada'],
    //   datasets: [
    //     {
    //       label: '% of Votes',
    //       data: [Details['Shopee'], Details['Lazada']],
    //       backgroundColor: [
    //         '#F69F3D',
    //         '#F36685',
    //       ],
    //       borderColor: [
    //         '#F69F3D',
    //         '#F36685',
    //       ],
    //       borderWidth: 1,
    //     },
    //   ],
    // };
    const chartData = {
      labels: ['Shopee', 'Lazada'],
      datasets: [
        {
          label: '% of Votes',
          data: ['60', '40'],
          backgroundColor: [
            '#F69F3D',
            '#F36685',
          ],
          borderColor: [
            '#F69F3D',
            '#F36685',
          ],
          borderWidth: 1,
        },
      ],
    };

    return <Doughnut data={chartData} />;
  }
}

export default DoughnutPieChart;