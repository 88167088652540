import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
import { CreateUserGroupFNC, UpdateUserGroupFNC } from '../Api';
import { Modal, Form } from 'react-bootstrap';
var timer

class GroupAuthoSetupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      GroupCode: '',
      GroupAutho: '',
      Group: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      isChecked: false,
      SelectedID: '',
      DescLine: false,
      Desc: '',
      GroupTypeArray: [],
      Authority: {
        UserMaster: false,
        CustomerMaster: false,
        ProductMaster: false,
        CategoryMaster: false,
        DepartmentMaster: false,
        StoreMaster: false,
        Edit: false,
        Delete: false,
        Print: false,
      },
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditAuthoRity') {
      this.setState({
        Authority: this.props.GroupAuthoDetails.Authority,
        isChecked: this.props.GroupAuthoDetails.Status,
        GroupCode: this.props.GroupAuthoDetails.GroupCode,
        SelectedID: this.props.GroupAuthoDetails.SelectedID,
        Desc: this.props.GroupAuthoDetails.Description,
      })
    }
  }

  async CreateGroupAutho() {
    const isChecked = this.state.isChecked
    const GroupCode = this.state.GroupCode
    const Authority = this.state.Authority
    const Desc = this.state.Desc

    if (GroupCode.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert GroupCode', 'GroupCodeLine')
    } else if (Desc.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Desc', 'DescLine')
    } else {
      let GroupDetails = {
        GroupCode: GroupCode,
        Status: isChecked,
        Description: Desc,
        Authority: Authority,
      }
      if (this.props.OpenModalType === 'EditAuthoRity') {
        const AddUser = await UpdateUserGroupFNC(GroupDetails, this.state.SelectedID)
        if (AddUser.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Updated User Group ${GroupCode}`)
        }
      } else {
        const AddUser = await CreateUserGroupFNC([GroupDetails])
        console.log('AddUser', AddUser)
        if (AddUser.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Group ${GroupCode}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 2000);
  }

  SetActiveUSer(event) {
    this.setState({ isChecked: event.target.checked })
  }

  CheckAutho(e, type) {
    console.log('e', e.target.checked)
    let Authority = this.state.Authority
    Authority[type] = e.target.checked
    this.setState({ Authority })
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayGroupAuthoAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditGroupAutho') ? `✎ Edit Group Authority` : 'Create New Group Authority'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: 5 }}>
            <div style={{ width: 'calc(100%/3)', border: '1px solid lightgrey', borderRadius: 10, minHeight: '40vh', marginRight: 5, display: 'flex', flexDirection: 'column', padding: 5 }}>
              <div style={{ display: 'flex', flexDirection: 'column', padding: 5, fontSize: '14px', marginBottom: 5 }}>
                <div style={{ width: 'calc(100%/1)'}}>{`GroupCode`}</div>
                <div style={{ width: 'calc(100%/1)'}}>
                  <Form.Control style={{ border: (this.state.GroupCodeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.GroupCode} onChange={(e) => this.setState({ GroupCode: e.target.value })} placeholder='GroupCode'/>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', padding: 5, fontSize: '14px', marginBottom: 5 }}>
                <div style={{ width: 'calc(100%/1)'}}>{`Desc`}</div>
                <textarea
                  style={{ width: 'calc(100%)', border: (this.state.DescLine === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  value={this.state.Desc}
                  onChange={(e) => this.setState({ Desc: e.target.value })}
                  rows="5"
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', padding: 5, fontSize: '14px' }}>
                <div style={{ width: 'calc(100%/1)'}}>{`Active Group`}</div>
                <div style={{ width: 'calc(100%/1)'}}>
                  <label className="switch">
                    <input type="checkbox" value={this.state.isChecked} checked={this.state.isChecked} onChange={(e) => this.SetActiveUSer(e)} />
                    <div className="slider"></div>
                  </label>
                </div>
              </div>
            </div>
            <div className='scrollhost' style={{ width: 'calc(100%/1)', border: '1px solid lightgrey', borderRadius: 10, minHeight: '40vh', maxHeight: '40vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'center' }}>
              <div style={{ width: '98%', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 10, padding: 5, marginBottom: 10 }}>
                <div style={{ borderBottom: '1px solid lightgrey'}}>User Management</div>
                <div style={{  display: 'flex', flexDirection: 'colum', flexWrap: 'wrap', marginTop: 4 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>User Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.UserMaster} onChange={(e) => this.CheckAutho(e, 'UserMaster')}/></div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Customer Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.CustomerMaster} onChange={(e) => this.CheckAutho(e, 'CustomerMaster')}/></div>
                  </div>
                </div>
              </div>
              <div style={{ width: '98%', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 10, padding: 5, marginBottom: 10  }}>
                <div style={{ borderBottom: '1px solid lightgrey'}}>Product Management</div>
                <div style={{  display: 'flex', flexDirection: 'colum', flexWrap: 'wrap', marginTop: 4 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Product Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.ProductMaster} onChange={(e) => this.CheckAutho(e, 'ProductMaster')}/></div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Category Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.CategoryMaster} onChange={(e) => this.CheckAutho(e, 'CategoryMaster')}/></div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Department Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.DepartmentMaster} onChange={(e) => this.CheckAutho(e, 'DepartmentMaster')}/></div>
                  </div>
                </div>
              </div>
              <div style={{ width: '98%', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 10, padding: 5, marginBottom: 10  }}>
                <div style={{ borderBottom: '1px solid lightgrey'}}>Store Management</div>
                <div style={{  display: 'flex', flexDirection: 'colum', flexWrap: 'wrap', marginTop: 4 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Store Master</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.StoreMaster} onChange={(e) => this.CheckAutho(e, 'StoreMaster')}/></div>
                  </div>
                </div>
              </div>
              <div style={{ width: '98%', flexDirection: 'column', border: '1px solid lightgrey', borderRadius: 10, padding: 5, marginBottom: 10  }}>
                <div style={{ borderBottom: '1px solid lightgrey'}}>Action Allow</div>
                <div style={{  display: 'flex', flexDirection: 'colum', flexWrap: 'wrap', marginTop: 4 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Edit</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.Edit} onChange={(e) => this.CheckAutho(e, 'Edit')}/></div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Delete</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.Delete} onChange={(e) => this.CheckAutho(e, 'Delete')}/></div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                    <div style={{ width: 'calc(100%/1)' }}>Print</div>
                    <div style={{ width: 'calc(100%/5)' }}><input type="checkbox" checked={this.state.Authority.Print} onChange={(e) => this.CheckAutho(e, 'Print')}/></div>
                  </div>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>Store Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>Product Master</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100%/4)'}}>
                <div style={{ width: 'calc(100%/1)' }}>User Management</div>
                <div style={{ width: 'calc(100%/5)' }}><input type="checkbox"/></div>
              </div> */}
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateGroupAutho()}>{(this.props.OpenModalType === 'EditAuthoRity') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupAuthoSetupModal);
