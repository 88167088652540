import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
import profile from '../image/profile.jpg';
import box1 from '../image/box1.png';
import box2 from '../image/box2.png';
import { getBase64 } from './getBase64';
import { AddStockInfoFNC, UpdateStockInfoFNC } from '../Api';
// import Attentions from '../image/Attention2.png';

import { Button, Modal, Form } from 'react-bootstrap';
var timer

class PricelvlModal extends React.Component {
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      Balance: '',
      StoreCode: '',
      StoreDesc: '',
      PurchaseDate: moment(new Date()).format('YYYY-MM-DD'),
      DDate: moment(new Date()).format('YYYY-MM-DD'),
      ExpiredDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      BalanceLine: false,
      StoreCodeLine: false,
      StoreDescLine: false,
      SelectedID: '',
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditStockinfo') {
      this.setState({ 
        StoreCode: this.props.StockInfoDetails.StoreCode,
        StoreDesc: this.props.StockInfoDetails.StoreDesc,
        Balance: this.props.StockInfoDetails.Balance,
        PurchaseDate: this.props.StockInfoDetails.PurchaseDate,
        DoDate: this.props.StockInfoDetails.DoDate,
        ExpiredDate: this.props.StockInfoDetails.ExpiredDate,
        SelectedID: this.props.StockInfoDetails.SelectedID,
      })
    }
  }

  async CreateProduct() {
    const Balance = this.state.Balance
    const StoreCode = this.state.StoreCode
    const StoreDesc = this.state.StoreDesc
    const PurchaseDate = this.state.PurchaseDate
    const DDate = this.state.DDate
    const ExpiredDate = this.state.ExpiredDate

    if (StoreCode.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert StoreCode', 'StoreCodeLine')
    } else if (StoreDesc.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert StoreDesc', 'StoreDescLine')
    } else if (Balance.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Balance', 'BalanceLine')
    } else {
      let StockInfoDetails = {
        StoreCode: StoreCode,
        StoreDesc: StoreDesc,
        Balance: Balance,
        PurchaseDate: PurchaseDate,
        DoDate: DDate,
        ExpiredDate: ExpiredDate,
      }
      if (this.props.OpenModalType === 'EditStockinfo') {
        const EditPriceLevel = await UpdateStockInfoFNC([StockInfoDetails], this.state.SelectedID)
        if (EditPriceLevel.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Update Price Level ${StoreCode}`)
        }
      } else {
        const AddStock = await AddStockInfoFNC([StockInfoDetails])
        if (AddStock.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Stock Info ${StoreCode}`)
        }
      }
      // ${CostPrice}
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
        BalanceLine: false,
        StoreCodeLine: false,
        StoreDescLine: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 3000);
  }

  SelectedOption(e, type) {
    this.setState({ [type]: e })
  }

  render() {
    const StoreCodeLine = this.state.StoreCodeLine
    const StoreDescLine = this.state.StoreDescLine
    const BalanceLine = this.state.BalanceLine

    return (
      <Modal backdrop="static" centered show={this.props.StockInformationModal} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditStockinfo') ? `✎ Edit Stock Information` : `Add Stock info` }</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowX: 'auto' }}>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Store Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (StoreCodeLine) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreCode} onChange={(e) => this.setState({ StoreCode: e.target.value })}  placeholder='Store Code'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Store Desc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (StoreDescLine) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreDesc} onChange={(e) => this.setState({ StoreDesc: e.target.value })} placeholder='Store Desc'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Balance</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (BalanceLine) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Balance} onChange={(e) => this.setState({ Balance: e.target.value })} placeholder='Balance'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Purchase Date</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border:  '1px solid lightgrey'}} value={this.state.PurchaseDate} type='date' onChange={(e) => this.setState({ PurchaseDate: e.target.value })}  placeholder='Purchase Date'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Do Date</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border:  '1px solid lightgrey'}} value={this.state.DDate} type='date' onChange={(e) => this.setState({ DDate: e.target.value })}  placeholder='Do Date'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Expired Date</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: '1px solid lightgrey' }} value={this.state.ExpiredDate} type='date' onChange={(e) => this.setState({ ExpiredDate: e.target.value })}  placeholder='Expired Date'/>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateProduct()}>{(this.props.OpenModalType === 'EditStockinfo') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricelvlModal);
