import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
import { FaTrash } from 'react-icons/fa';
// import Attentions from '../image/Attention2.png';
import { AddProductFNC } from '../Api';
import { Modal } from 'react-bootstrap';
var timer

class ImportExcelProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Name: '',
      Password: '',
      Group: '',
      Status: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      SelectedID: '',
      displayExcelData: [],
    }
  }

  componentDidMount() {
    this.setState({ 
      displayExcelData: this.props.displayExcelData,
    })
  }

  async CreateDatas() {
    const Datas = this.state.displayExcelData
    const FinalArray = Datas.map(item => {
      return {
        ...item,
        Createdby: this.props.username,
        CreatedDate: this.state.CreatedDate,
        UpdatedDate: this.state.CreatedDate,
      };
    });
    console.log('FinalArray', FinalArray)
    const CreateUsers = await AddProductFNC(FinalArray)
    if (CreateUsers.message === 'Add success') {
      this.DisplaySuccessMEssage('Successful add Products')
    }
    if (CreateUsers.error) {
      this.DisplayErrorMEssage(`${CreateUsers.error}`)
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 2000);
  }

  DeleteItems(selectedItem) {
    let displayExcelData = this.state.displayExcelData
    const FilterData = displayExcelData.filter(employee => {
      return employee.SKUCode !== selectedItem.SKUCode;
    })
    this.setState({ displayExcelData: FilterData })
  }

  render() {
    const OpenModalType = this.props.OpenModalType
    return (
      <Modal backdrop="static" centered show={this.props.DisplayExcelConfirm} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{`Review Upload Excel Datas ( ${this.props.displayExcelData.length} Datas )`}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '50vh', minHeight: '50vh', overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white', minWidth: 1950 }}>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>SKU Code</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Description</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>OtherDesc</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>SKUType</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Category</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Department</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>UOM</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Tax</div>
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>PhotoLink</div>
                  {/* <div style={{ width: 'calc(100%)', minWidth: 150 }}>PhotoLink2</div> */}
                  <div style={{ width: 'calc(100%)', minWidth: 150 }}>Action</div>
                </div>
                {this.state.displayExcelData.map((items, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5, minWidth: 1950 }}>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.SKUCode}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.Description}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.OtherDesc}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.SKUType}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.Category}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.Department}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.UOM}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.Tax}</div>
                      <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>{items.PhotoLink}</div>
                      {/* <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }}>
                        <div className="dcc" style={{ width: '100%'}}>
                          <label htmlFor="photo-upload" className="custom-file-upload fas ">
                            <div className="img-wrapforExcelUpload img-uploadExcel">
                              <img style={{ width: '100%'}} for="photo-upload" src={(this.state.file !== '') ? this.state.file : this.state.imagePreviewUrl} />
                            </div>
                            <input id="photo-upload" type="file" accept=".jpg,.png,.jpeg,"  onChange={this.onChangePic} />
                          </label>
                        </div>
                      </div> */}
                      {/* <div style={{ width: 'calc(100%)', minWidth: 150, overflowWrap: 'break-word' }} onClick={() => { this.DeleteItems(items) }}>
                        <FaTrash />
                      </div> */}
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteItems(items)}>
                          {/* 🗑 */}
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateDatas()}>
              {(OpenModalType === 'AddStore') ? 'Add Stores' : 'Adds'}
            </div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportExcelProduct);
