import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import { AddCategoryFNC, UpdateCategoryFNC } from '../Api';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';

import { Modal, Form } from 'react-bootstrap';
var timer

class CategoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CategoryCode: '',
      CategoryDesc: '',
      OtherDesc: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      CategoryCodeLine: false,
      CategoryDescLine: false,
      PaymentLine: false,
      OtherDescLine: false,
      SelectedID: '',
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditCategory') {
      // console.log('CategoryDetails', this.props.CategoryDetails)
      this.setState({ 
        CategoryCode: this.props.CategoryDetails.CategoryCode,
        CategoryDesc: this.props.CategoryDetails.CategoryDesc,
        OtherDesc: this.props.CategoryDetails.OtherDesc,
        SelectedID: this.props.CategoryDetails.ID,
      })
    }
    // console.log('CreatedDate', this.state.CreatedDate)
  }

  async CreateCategory(Type) {
    const CategoryCode = this.state.CategoryCode
    const CategoryDesc = this.state.CategoryDesc
    const OtherDesc = this.state.OtherDesc

    if (CategoryCode === '') {
      this.DisplayErrorMEssage('Please Insert Category Code', 'CategoryCodeLine')
    } else if (CategoryDesc.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert CategoryDesc', 'CategoryDescLine')
    } else if (OtherDesc.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Other Desc', 'OtherDescLine')
    } else {
      if (Type === 'EditCategory') {
        let FinalPassValue = {
          CategoryCode: CategoryCode,
          CategoryDesc: CategoryDesc,
          OtherDesc: OtherDesc,
        }
        const UpdateCustomer = await UpdateCategoryFNC(FinalPassValue, this.state.SelectedID)
        if (UpdateCustomer.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Update Category ${CategoryCode}`)
        }
      } else {
        let FinalPassValue = {
          CategoryCode: CategoryCode,
          CategoryDesc: CategoryDesc,
          OtherDesc: OtherDesc,
          Createdby: this.props.username,
          CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        }
    
        const AddCategory = await AddCategoryFNC([FinalPassValue]);
        if (AddCategory.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Category ${CategoryCode}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 3000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
      this.props.CallApi();
    }, 2000);
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayCateAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditCategory') ? `✎ Edit Cetogory` : 'Add Category'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
            }
            {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ marginBottom: 10, color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
            } */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Category Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.CategoryCodeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CategoryCode} onChange={(e) => this.setState({ CategoryCode: e.target.value })} placeholder='Category Code'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Category Desc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.CategoryDescLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CategoryDesc} onChange={(e) => this.setState({ CategoryDesc: e.target.value })} placeholder='Category Desc'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Other Desc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.OtherDescLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.OtherDesc} onChange={(e) => this.setState({ OtherDesc: e.target.value })} placeholder='Other Desc'/>
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Company Address</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <textarea
                  style={{ width: 'calc(100%/1.5)', border: (this.state.CompanyAddressLine === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  value={this.state.CompanyAddress}
                  onChange={(e) => this.setState({ CompanyAddress: e.target.value })}
                  rows="5"
                />
              </div>
            </div> */}
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateCategory(this.props.OpenModalType)}>{(this.props.OpenModalType === 'EditCategory') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
