import '../App.css';
import React from 'react';
import KFCVideo from '../image/KFC.mp4';
  
function VideoDesign() {
//   return (
//     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//       <video className='video-bg' autoPlay="autoplay" loop="loop" id="video">
//         {/* <source src='https://www.w3schools.com/tags/movie.mp4' type="video/mp4" /> */}
//         <source src={KFCVideo} type="video/mp4" />
//         <div className='bg-overlay'>
//           <div>halo 123</div>
//           <div>halo 123</div>
//         </div>
//       </video>
//     </div>
//   );
  return (
    <div className='bgContainer'>
      <div className='overplay'>
        <video className='dcc videoplay' src={KFCVideo} autoPlay loop muted />
        <div className='bg-overlay'>
          <div>Testing KFC Video</div>
          {/* <div>i Like To Eat KFC and McDonald, McDonald is better than KFC</div> */}
        </div>
      </div>
    </div>
  );
}

export default VideoDesign;
