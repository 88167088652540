import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
// import Select from 'react-select';
import {  Alert, Button, Form, Modal, Table } from 'react-bootstrap';
// import moment from 'moment-timezone';

var timer

class Set extends React.Component {

  fileObj = [];
  fileArray = [];
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      DynamicArray1: [],
      showNotification: false,
      ModalShowUpdate: false,
      DeleteBoolean: false,
      AddBoolean: false,
      UpdateOptions: [{
        'value': '1Utama',
        'label': '1 Utama'
      },
      {
        'value': 'Pavilion',
        'label': 'Pavilion'
      },{
        'value': 'MidValley',
        'label': 'Mid Valley'
      },{
        'value': 'AeonShahAlam',
        'label': 'Aeon ShahAlam'
      },{
        'value': 'Sunway',
        'label': 'Sunway'
      },{
        'value': 'AeonBukittinggi',
        'label': 'Aeon Bukit tinggi'
      }],
      UpdateOptionsBackUp: [{
        'value': '1Utama',
        'label': '1 Utama'
      },
      {
        'value': 'Pavilion',
        'label': 'Pavilion'
      },{
        'value': 'MidValley',
        'label': 'Mid Valley'
      },{
        'value': 'AeonShahAlam',
        'label': 'Aeon ShahAlam'
      },{
        'value': 'Sunway',
        'label': 'Sunway'
      },{
        'value': 'AeonBukittinggi',
        'label': 'Aeon Bukit tinggi'
      }],
    }
    this.openNotification = this.openNotification.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handle = this.handle.bind(this);
  }

  handleClose() {
    if(this.state.AddBoolean) {
      this.handleconfirm()
    }
    this.setState({
      ModalShowUpdate: false, 
      UpdateOptions: this.state.UpdateOptionsBackUp,
      DeleteBoolean: false,
      AddBoolean: false})
  }


  async componentDidMount() {
    const copyObject1 = [];
    for (var i=1; i<=500; i++ ) {
      copyObject1.push(i)
    }
    
    this.setState({DynamicArray1: copyObject1,})
  }


  logout() {
    window.location.href = '/';
  }



  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }


  
  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  handleAdd(){
    const addnew = {
      'value': '',
      'label': '',
      'New': true,
    }
    const copy = this.state.UpdateOptions
    copy.push(addnew)
    this.setState({UpdateOptions: copy, AddBoolean: true})
  }

  handle(index, status, value){
    const copy = this.state.UpdateOptions
    copy[index][status] = value;
    this.setState({UpdateOptions: copy,})
  }

  handleconfirm(){
    const copy = this.state.UpdateOptions
    if(copy[this.state.UpdateOptions.length - 1]['value'] === '' && copy[this.state.UpdateOptions.length - 1]['label'] === '') {
      delete copy[this.state.UpdateOptions.length - 1] 
      var filtered = copy.filter(function (el) {
        return el != null;
      });
      this.setState({ UpdateOptions: filtered, UpdateOptionsBackUp: filtered,AddBoolean: false})
    } else {
      copy[this.state.UpdateOptions.length - 1]['New'] = false;
      this.setState({ UpdateOptions: copy, UpdateOptionsBackUp: copy,AddBoolean: false})
    }
    
   
  }

  handleDeleted() {
    this.setState({ DeleteBoolean: true})
  }

  handleReset(){
    this.setState({ UpdateOptions: this.state.UpdateOptionsBackUp })
  }

  handleremove(index){
    let copy = JSON.parse(JSON.stringify(this.state.UpdateOptions))
    delete copy[index] 
    var filtered = copy.filter(function (el) {
      return el != null;
    });
    this.setState({UpdateOptions: filtered})
  }
  render() {
    return (
      <div fluid >
        <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px' }}>
          <div onScroll={(e) => this.handleScroll(e)} id={'Top'} style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{'Set'}</h2>
            </div>
          </div>
          <div className="dcc" style={{ width: 'calc(100%)', }}>
            <div className="dcc" style={{ width: 'calc(80%)', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: 'calc(100%)'}}>
                <div  style={{ flex: "1", border: "1px solid black", padding: 5, borderRadius: 5}}>
                  <Fragment>
                    <Table striped bordered hover responsive style={{ width: '100%'}}>
                      <thead>
                        <tr style={{ textAlign: 'center'}}>
                          <th>{'No.'}</th>
                          <th>{'Label'}</th>
                          <th>{'Name'}</th>
                          {(this.state.DeleteBoolean)&&<th>{''}</th>}
                        </tr>
                      </thead>
                      {Object.keys(this.state.UpdateOptions).length > 0 ?
                        <tbody>
                          {Object.keys(this.state.UpdateOptions).map((items, index) => {
                            return(
                              <tr key={index} style={{ cursor: 'pointer' }}>
                                { (this.state.UpdateOptions[items]['New'] ) ? <>
                                  <td>{index + 1}.</td>
                                  <td>
                                    <Form.Control value={this.state.UpdateOptions[items]['label']} 
                                      onChange={(e) => this.handle(items, 'label', e.target.value)}/>
                                  </td>
                                  <td>
                                    <Form.Control value={this.state.UpdateOptions[items]['value']} 
                                      onChange={(e) => this.handle(items, 'value', e.target.value)}/>
                                  </td>
                              
                                </> : <>
                                  <td>{index + 1}.</td>
                                  <td>{this.state.UpdateOptions[items]['label']}</td>
                                  <td>{this.state.UpdateOptions[items]['value']}</td>
                                  {(this.state.DeleteBoolean)&&<td>
                                    <Button   onClick={()=> this.handleremove(items)} style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                                      {'Remove'}
                                    </Button>
                                  </td>}
                                </>
                                }
                              </tr>
                            )
                          })}
                        </tbody>
                        :
                        <tbody>
                          <tr style={{ backgroundColor: 'lightgray'}}>
                            <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                          </tr>
                        </tbody>}
                    </Table>
                    <div className="dcc"  style={{ width: '100%', }}>
                      <div className="dcc"  style={{ width: '80%', }}>
                        {(this.state.AddBoolean) ? <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%',}}>
                          <Button   onClick={()=>  
                            (window.confirm('Are you sure, you want to delete?')) ?
                              this.handleconfirm()
                              :
                              null
                      
                          } style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                            {'Confirm'}
                          </Button>
                        </div> : (this.state.DeleteBoolean) ? <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%',}}>
                          <Button   onClick={()=>   
                            (window.confirm('Are you sure, you want to delete?')) ?
                              this.setState({ DeleteBoolean: false})
                              :
                              this.setState({ UpdateOptions: this.state.UpdateOptionsBackUp })
                      
                          } style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                            {'Confirm'}
                          </Button>
                          <Button   onClick={()=>  this.handleReset()} style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="warning" >
                            {'Reset'}
                          </Button>
                          <Button   onClick={()=>  this.setState({ DeleteBoolean: false})} style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="danger" >
                            {'Cancel'}
                          </Button>
                        </div> :
                          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%',}}>
                            <Button   onClick={()=> this.handleAdd()} style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}} variant="primary" >
                              {'Add'}
                            </Button>
                            <Button  variant="danger"  onClick={()=>  this.handleDeleted()} style={{  display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}  >
                              {'Delete'}
                            </Button>
                      
                          </div>}
                      </div>
                    </div>
                  </Fragment>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}


export default connect(mapStateToProps, null)(Set);
