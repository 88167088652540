import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import profile from '../image/profile.jpg';
// import box1 from '../image/box1.png';
import box2 from '../image/box2.png';
import { AddProductFNC, UpdateProductMasterFNC, getDataFromFNC } from '../Api';
import { getBase64 } from '../Component/getBase64';
// import Attentions from '../image/Attention2.png';

import { Modal, Form } from 'react-bootstrap';
var timer

class AddProductModal extends React.Component {
  Base64 = []
  constructor(props) {
    super(props);
    this.state = {
      Description: '',
      OtherDesc: '',
      SKUType: '',
      Category: '',
      Department: '',
      UOM: '',
      Tax: '',
      PhotoLink: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      UpdatedDate: '',
      MessageType: '',
      MessageShow: '',
      DescriptionLine: false,
      OtherDescLine: false,
      CategoryLine: false,
      DepartmentLine: false,
      UOMLine: false,
      TaxLine: false,
      SKUTypeLine: false,
      SKULine: false,
      file: "",
      imagePreviewUrl: box2,
      name: "",
      status: "",
      active: "edit",
      fileObjbase64: [],
      SKUTypeyArray: ['normal', 'non-inventory', 'package(bundle)'],
      CategoryArray: [],
      DepartmentArray: [],
      // SKUTypevalue: '',
      UOMTypeyArray: ['PCS', 'C+N',],
      // UOMvalue: '',
      SKUCode: '',
      EditID: '',
    }
    this.onChangePic = this.onChangePic.bind(this);
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditProduct') {
      this.setState({
        EditID: this.props.ProductDetails.EditID,
        SKUCode: this.props.ProductDetails.SKUCode,
        Description: this.props.ProductDetails.Description,
        OtherDesc: this.props.ProductDetails.OtherDesc,
        SKUType: this.props.ProductDetails.SKUType,
        Category: this.props.ProductDetails.Category,
        Department: this.props.ProductDetails.Department,
        UOM: this.props.ProductDetails.UOM,
        file: this.props.ProductDetails.PhotoLink,
        Tax: this.props.ProductDetails.Tax,
        CreatedBy: this.props.ProductDetails.CreatedBy,
        CreatedDate: this.props.ProductDetails.CreatedDate,
        UpdatedDate: moment(new Date()).format('YYYY-MM-DD'),
      })
    }
    this.getArrayDetails();
  }

  async getArrayDetails() {
    const getCategory = await getDataFromFNC('CategoryMaster');
    const getDepart = await getDataFromFNC('DepartmentMaster');
    if (getCategory.message === 'Call success') {
      this.setState({ CategoryArray: getCategory.Groups })
    }
    if (getDepart.message === 'Call success') {
      this.setState({ DepartmentArray: getDepart.Groups })
    }
  }

  async CreateProduct() {
    console.log('fileObjbase64', this.state.fileObjbase64)
    const Description = this.state.Description
    const OtherDesc = this.state.OtherDesc
    const SKUType = this.state.SKUType
    const Category = `${this.state.Category}`
    const UOM = this.state.UOM
    const Department = this.state.Department
    const Tax = this.state.Tax
    const SKUCode = this.state.SKUCode
    if (SKUCode === '') {
      this.DisplayErrorMEssage('Please Insert SKUode', 'SKULine')
    }  else if (Description === '') {
      this.DisplayErrorMEssage('Please Insert Description', 'DescriptionLine')
    } else if (OtherDesc.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert OtherDesc', 'OtherDescLine')
    } else if (SKUType.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert SKUType', 'SKUTypeLine')
    } else if (Category.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Category', 'CategoryLine')
    } else if (Department.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Department', 'DepartmentLine')
    } else if (UOM.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert UOM', 'UOMLine')
    } else if (Tax.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Tax', 'TaxLine')
    } else {
      const ProductDetails = []
      const object = {
        SKUCode: SKUCode,
        Description: Description,
        OtherDesc: OtherDesc,
        SKUType: SKUType,
        Category: Category,
        Department: Department,
        UOM: UOM,
        Tax: Tax,
        PhotoLink: this.state.fileObjbase64,
        Createdby: this.props.username,
        CreatedDate: this.state.CreatedDate,
        UpdatedDate: this.state.UpdatedDate,
      }
      ProductDetails.push(object)
      if (this.props.OpenModalType === 'EditProduct') {
        const UpdateProduct = await UpdateProductMasterFNC(ProductDetails ,this.state.EditID)
        console.log('UpdateProduct', UpdateProduct)
        if (UpdateProduct.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Update Product ${SKUCode}`)
        }
      } else {
        const AddProduct = await AddProductFNC(ProductDetails);
        console.log('AddProduct', AddProduct)
        if (AddProduct.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Product ${SKUCode}`)
        }
        if (AddProduct.error) {
          this.DisplayErrorMEssage(`${AddProduct.error}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 4000);
  }

  onChangePic(e) {
    if (e.target.files.length > 0) {
      getBase64(e.target.files[0]).then(
        Base64data =>  {
        // fileObjbase64.push(Base64data)
          this.setState({fileObjbase64: [Base64data] })
        }
      )
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: URL.createObjectURL(file),
          imagePreviewUrl: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  SelectedOption(e, type) {
    this.setState({ [type]: e })
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.AddProductModal} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditProduct') ? `✎ Edit Product` : '+ Add Product'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '60vh', maxHeight: '60vh', overflowX: 'auto' }}>
            <div className="dcc" style={{ width: '100%'}}>
              <label htmlFor="photo-upload" className="custom-file-upload fas ">
                <div className="img-wrap img-upload">
                  <img style={{ width: '100%'}} alt="" for="photo-upload" src={(this.state.file !== '') ? this.state.file : this.state.imagePreviewUrl} />
                </div>
                <input id="photo-upload" type="file" accept=".jpg,.png,.jpeg,"  onChange={this.onChangePic} />
              </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>SKUCode</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.SKULine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.SKUCode}  onChange={(e) => this.setState({ SKUCode: e.target.value })} placeholder='SKUCode'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Description</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.DescriptionLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Description} onChange={(e) => this.setState({ Description: e.target.value })} placeholder='Description'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>OtherDesc</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.OtherDescLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.OtherDesc} onChange={(e) => this.setState({ OtherDesc: e.target.value })} placeholder='OtherDesc'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>SKUType</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.SKUTypeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.SKUType} onChange={(e) => this.setState({ SKUType: e.target.value })} placeholder='SKUType'/> */}
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)' }} value={this.state.SKUType} onChange={(e) => this.SelectedOption(e.target.value, 'SKUType')}>
                  <option value=''>-</option>
                  {this.state.SKUTypeyArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Category</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              {/* <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.CategoryLine === true) ? '1px solid red' : '1px solid lightgrey'}} value={this.state.Category} onChange={(e) => this.setState({ Category: e.target.value })} placeholder='Category'/>
              </div> */}
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.SKUTypeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.SKUType} onChange={(e) => this.setState({ SKUType: e.target.value })} placeholder='SKUType'/> */}
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)' }} value={this.state.Category} onChange={(e) => this.SelectedOption(e.target.value, 'Category')}>
                  <option value=''>-</option>
                  {this.state.CategoryArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Department</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              {/* <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.DepartmentLine === true) ? '1px solid red' : '1px solid lightgrey'}} value={this.state.Department} onChange={(e) => this.setState({ Department: e.target.value })} placeholder='Department'/>
              </div> */}
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.SKUTypeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.SKUType} onChange={(e) => this.setState({ SKUType: e.target.value })} placeholder='SKUType'/> */}
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)'  }} value={this.state.Department} onChange={(e) => this.SelectedOption(e.target.value, 'Department')}>
                  <option value=''>-</option>
                  {this.state.DepartmentArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>UOM</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.UOMLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.UOM} onChange={(e) => this.setState({ UOM: e.target.value })} placeholder='UOM'/> */}
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)' }} value={this.state.UOM} onChange={(e) => this.SelectedOption(e.target.value, 'UOM')}>
                  <option value=''>-</option>
                  {this.state.UOMTypeyArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Tax</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.TaxLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Tax} onChange={(e) => this.setState({ Tax: e.target.value })} placeholder='Tax'/>
              </div>
            </div>
            {(this.props.OpenModalType === 'EditProduct')&&<>
              <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
                <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>CreatedBy</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
                <div style={{ width: 'calc(100%/1)'}}>
                  <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: '1px solid lightgrey' }} disabled={true} value={this.state.CreatedBy}  placeholder='CreatedBy'/>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
                <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>CreatedDate</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
                <div style={{ width: 'calc(100%/1)'}}>
                  <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: '1px solid lightgrey' }} disabled={true}  placeholder={this.state.CreatedDate}/>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
                <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>UpdatedDate</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
                <div style={{ width: 'calc(100%/1)'}}>
                  <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: '1px solid lightgrey' }} disabled={true} value={this.state.UpdatedDate}  placeholder='UpdatedDate'/>
                </div>
              </div>
            </>}
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateProduct()}>{(this.props.OpenModalType === 'EditProduct') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductModal);
