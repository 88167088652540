import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import { CreateUsersFNC } from '../Api';
import { Button, Modal, Form } from 'react-bootstrap';
var timer

class ConfirmExcelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Name: '',
      Password: '',
      Group: '',
      Status: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      SelectedID: '',
    }
  }

  componentDidMount() {
    console.log('displayExcelData', this.props.displayExcelData)
  }

  async CreateDatas() {
    const OpenModalType = this.props.OpenModalType
    const Datas = this.props.displayExcelData
    if (OpenModalType === 'AddUser') {
      const CreateUsers = await CreateUsersFNC(Datas)
      if (CreateUsers.message === 'Add success') {
        this.DisplaySuccessMEssage('Successful add users')
      }
    }
    if (OpenModalType === 'AddUserGroup') {

    }
    if (OpenModalType === 'AddGroupAutho') {

    }
    console.log('Datas', Datas)
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 2000);
  }

  SetActiveUSer(event) {
    this.setState({ isChecked: event.target.checked })
  }

  render() {
    const OpenModalType = this.props.OpenModalType
    return (
      <Modal backdrop="static" centered show={this.props.DisplayExcelConfirm} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{`Review Upload Excel Datas ( ${this.props.displayExcelData.length} Datas )`}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {this.props.OpenModalType === 'AddUser' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white' }}>
                <div style={{ width: 'calc(100%)'}}>Username</div>
                <div style={{ width: 'calc(100%)'}}>Name</div>
                <div style={{ width: 'calc(100%)'}}>Password</div>
                <div style={{ width: 'calc(100%)'}}>UserGroup</div>
                <div style={{ width: 'calc(100%)'}}>Status</div>
              </div>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '45vh', minHeight: '45vh', overflowY: 'auto' }}>
                {this.props.displayExcelData.map((usersitems, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Username}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Name}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Password}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Group}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Status}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
            {this.props.OpenModalType === 'AddUserGroup' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white' }}>
                <div style={{ width: 'calc(100%)'}}>User Group Code</div>
                <div style={{ width: 'calc(100%)'}}>User Group Name</div>
                <div style={{ width: 'calc(100%)'}}>Status</div>
              </div>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '45vh', minHeight: '45vh', overflowY: 'auto' }}>
                {this.props.displayExcelData.map((usersitems, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.UserGroupCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.UserGroupName}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Status}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
            {this.props.OpenModalType === 'AddGroupAutho' && 
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'black', padding: 5, color: 'white' }}>
                <div style={{ width: 'calc(100%)'}}>Group Code</div>
                <div style={{ width: 'calc(100%)'}}>Group Name</div>
                <div style={{ width: 'calc(100%)'}}>Status</div>
              </div>
              <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '45vh', minHeight: '45vh', overflowY: 'auto' }}>
                {this.props.displayExcelData.map((usersitems, ind) => {
                  return(
                    <div key={ind} style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightgrey', padding: 5 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.GroupCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.GroupName}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word'}}>{usersitems.Status}</div>
                    </div>
                  );
                })}
              </div>
            </div>}
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateDatas()}>
              {(OpenModalType === 'AddUser') ? 'Add Users' : 'Adds'}
            </div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmExcelModal);
