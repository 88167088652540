import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Header from '../Component/Header';
import { Modal, Table, Alert } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedingstationArray: {
        'F1': 10,
        'F2': 15,
        'F3': 33,
        'F4': 50,
        'F5': 41,
        'F6': 77,
        'F7': 10,
        'F8': 20,
        'F9': 41,
        'F10': 77,
        'F11': 4,
        'F12': 0,
      },
      TimeData: {
        0:{
          'Time': '2023-05-13 03:54:01 am',
          'Feeder': '1',
          'Destination': '5',
        },
        1:{
          'Time': '2023-05-13 03:54:01 am',
          'Feeder': '2',
          'Destination': '6',
        },
      },
      ModalShowDetail: false,
      showNotification: false,
    }
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };


  
  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'}>
        <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div>
        <Alert className='AlertPage' style={{ zIndex: 99, position: 'fixed', right: 0, top: 80 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        {/* onHide={this.setState({ModalShowDetail: false})} */}
        <Modal centered show={this.state.ModalShowDetail} size="lg" onHide={()=> this.handleClose()} className='printable' style={{ fontSize: '12px' }} >
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {'Detail'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Table striped bordered hover responsive style={{ width: '90%'}}>
                  <thead>
                    <tr style={{ textAlign: 'center'}}>
                      <th>{'Time'}</th>
                      <th>{'Feeder'}</th>
                      <th>{'Destination/Chutes'}</th>
                    </tr>
                  </thead>
                  {Object.keys(this.state.TimeData).length > 0 ?
                    <tbody>
                      {Object.keys(this.state.TimeData).map((items, index) => {
                        return(
                          <tr key={index} style={{ cursor: 'pointer' }}>
                            <td>{this.state.TimeData[items]['Time']}</td>
                            <td>{this.state.TimeData[items]['Feeder']}</td>
                            <td>{this.state.TimeData[items]['Destination']}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                    :
                    <tbody>
                      <tr style={{ backgroundColor: 'lightgray'}}>
                        <td colSpan="8" style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{language[this.props.currentLanguage].NoData}</td>
                      </tr>
                    </tbody>}
                </Table>
              </>
            </Modal.Body>
          </>
        </Modal>
        <div style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px' }}>
          <div  style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h2 style={{ marginRight: 10 }}>{language[this.props.currentLanguage].HomePage}</h2>
       
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', }}>
            <div className="dcc" style={{ width: '80%', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", border: "1px solid lightgray", borderRadius: 5, padding: 5}}>
                  <Fragment>
                    <div>
                      {Object.keys(this.state.feedingstationArray).length > 0 && 
              <>
                <div className="dcc" style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width:   '100%', marginBottom:  30, justifyContent: null, paddingTop: 10  }}>
                  {Object.keys(this.state.feedingstationArray).map((item,)=>(
                    <div className="button3" key={item.PackageID} 
                      style={{  border: "1px solid lightgray", borderRadius: 5, padding: 35,
                        margin: 5, display: 'flex', justifyContent: 'center', width: 'calc(100% / 4)' }} 
                      variant="primary" >
                      <div className="wrap" style={{ display: 'flex', flexDirection: 'column'}}>
                        <div className="wrap" style={{ fontWeight: 'bold', fontSize: 20}}>
                          {item}
                        </div>
                        <div className="wrap" style={{ fontWeight: 'bold', fontSize: 20,textAlign: 'center'}}>
                          {this.state.feedingstationArray[item]}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              </>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
