import React, { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class LineChart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLabels: [],
      DisplayData: [],
    }
  }

  componentDidMount() {
    this.GenerateLabelsandData();
  }

  GenerateLabelsandData() {
    const Details = this.props.Details
    let showLabels = this.state.showLabels
    let DisplayData = this.state.DisplayData
    Object.keys(Details).forEach((items) => {
      showLabels.push(items)
      DisplayData.push(Details[items])
    })
    this.setState({ showLabels, DisplayData })
  }

  render() {
    const { Details } = this.props;

    const chartData = {
      labels: this.state.showLabels,
      datasets: [
        {
          label: 'Dataset 1',
          data: this.state.DisplayData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'pink',
        },
      ],
    };

    return <Line style={{ width: 'auto', maxHeight: '45vh' }} data={chartData} />;
  }
}

export default LineChart2;