import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
import loading from '../image/loading2.gif';
import Successign from '../image/Success1.gif';
import Sidebar from '../Component/Sidebar';
import { Modal, Table, Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class QuotationModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state,
      ModalShowDetail: false,
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}, {}],
      notificationMessage: '',
      loading: false,
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
    }
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
    console.log('data', this.state.data)
    if (this.state.data === 'Complete Quotes !!') {
      this.openNotification('Created Quotation');
    }
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  async ReceivedClicked() {
    console.log('Clicked Received')
  }

  OpenCreateQoute() {
    this.props.history.push('/CreateQuotation')
  }

  ViewClicked() {
    console.log('View')
    this.setState({ ModalShowDetail: true })
  }
  
  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row'}}>
        <Sidebar/>
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '12px', width: '100%' }}>
          {this.state.ModalShowDetail === true && 
          <Modal centered show={this.state.ModalShowDetail} size="lg" onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Quotation Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 50 }}>
                  <div style={{ width: '50%'}}></div>
                  <div style={{ width: '50%', display: 'flex', flexDirection: 'row'}}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column'}}>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', marginBottom: 10 }}>Quotation number :</div>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', marginBottom: 10 }}>Invoiced date :</div>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold' }}>Customer :</div>
                    </div>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column'}}>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>628</div>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>21-10-2023</div>
                      <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex' }}>Stephen Curry</div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', color: '#259CD6', fontWeight: 'bold', marginBottom: 5 }}>
                    <div style={{ width: 'calc(100% / 1)'}}>Product</div>
                    <div style={{ width: 'calc(100% / 3)'}}>Qty</div>
                    <div style={{ width: 'calc(100% / 3)'}}>Price</div>
                    <div style={{ width: 'calc(100% / 3)'}}>Total</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: 'bold'}}>
                    <div style={{ width: 'calc(100% / 1)'}}>Bio Clean</div>
                    <div style={{ width: 'calc(100% / 3)'}}>40</div>
                    <div style={{ width: 'calc(100% / 3)'}}>120.00</div>
                    <div style={{ width: 'calc(100% / 3)'}}>4800.00</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: 'bold'}}>
                    <div style={{ width: 'calc(100% / 1)'}}>Clear</div>
                    <div style={{ width: 'calc(100% / 3)'}}>10</div>
                    <div style={{ width: 'calc(100% / 3)'}}>100.00</div>
                    <div style={{ width: 'calc(100% / 3)'}}>1000.00</div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                  <div style={{ width: 'calc(100% / 2)' }}></div>
                  <div style={{ width: 'calc(100% / 1)', display: 'flex', flexDirection: 'column'}}>
                    <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', marginBottom: 10 }}>Number of Products :</div>
                    <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', marginBottom: 10 }}>Subtotal :</div>
                    <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', marginBottom: 10 }}>{`Discount total (Rs) :`}</div>
                    <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', fontSize: '25px' }}>{`Quotation Total :`}</div>
                  </div>
                </div>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column'}}>
                  <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>1</div>
                  <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex', marginBottom: 10 }}>4800.00</div>
                  <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex' }}>480.00</div>
                  <div style={{ width: 'calc(100%)', padding: 5, color: 'grey', fontWeight: 'bold', justifyContent: 'flex-end', display: 'flex', fontSize: '25px' }}>4320.00</div>
                </div>
              </div>
            </Modal.Footer>
            <Modal.Footer>
              <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
              <Button variant="success" onClick={() => this.handleSubmit()}>Print Quotation</Button>
              <Button variant="success" onClick={() => this.handleSubmit()}>提交</Button>
            </Modal.Footer>
          </Modal>
          }
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              {this.state.notificationMessage === '' &&
                <h3 style={{ marginRight: 10, color: "white" }}>{'Quotation Module'}</h3>
              }
              {this.state.notificationMessage !== '' &&
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
              }
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}> Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      <Form.Control style={{ width: 'calc(100%)' }} onKeyPress={(e) => this.onKeyEnter(e)}  value={this.state.StoreSearch} onChange={(e) => this.setState({ StoreSearch: e.target.value })} placeholder='Search'/>
                    </div>
                  </div>
                  {/* <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Search SKU:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      <Form.Control style={{ width: 'calc(100%)' }} onKeyPress={(e) => this.onKeyEnter(e)}  value={this.state.StoreSearch} onChange={(e) => this.setState({ StoreSearch: e.target.value })} placeholder='Search'/>
                    </div>
                  </div> */}
                </div>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', paddingLeft: 15, paddingRight: 15 }}>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>From Date:</div>
                    <div style={{ width: 'calc(100% / 1)'}}><Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/></div>
                  </div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>To Date:</div>
                    <div style={{ width: 'calc(100% / 1)'}}><Form.Control value={this.state.toDate} onChange={(e) => this.setState({ toDate: e.target.value })} type="date"/></div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => { this.CallApi(); }}>Search</div>
                  <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.OpenCreateQoute()}>Create Quotation</div>
                </div>
              </div>
            </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Created Date</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Quote.Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Quote.No</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Customer Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Amount</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Status</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.PODataLists.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100, display: 'flex' }}>
                        {items.StatusStore === 'active' ? (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Active</div>
                        ) : (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#EB921B', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Not Active</div>
                        )}
                      </div>
                      <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ViewClicked()}>View</div>
                        <div className='EditWord' style={{ width: 'c alc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>
                          Download Quote
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationModule);
