import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './Page/Login';
import Main from './Page/Main';
import Setting from './Page/Set';
import Dynamic from './Page/Dynamic';
import Barcode from './Page/Barcode';
import POmodule from './Page/POmodule';
import ProductMaster from './Page/ProductMaster';
import DeliveryModule from './Page/DeliveryModule';
import QuotationModule from './Page/QuotationModule';
import UserManagement from './Page/UserManagement';
import CustomerMaster from './Page/CustomerMaster';
import CreateQuotation from './Component/CreateQuotation';
import CategoryMaster from './Page/CategoryMaster';
import DepartmentMaster from './Page/DepartmentMaster';
import OrderManagement from './Page/OrderManagement';
// import Pricelvl from './Page/Pricelvl';
// import StockInformation from './Page/StockInformation';
import Dashboard from './Page/Dashboard';
import StoreMaster from './Page/StoreMaster';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducers from './reducers/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Header from './Component/Header';
import Sidebar from './Component/Sidebar';

function App() {
  const middlewares = applyMiddleware(thunk);

  const store = createStore(
    Reducers, {
      username: localStorage.getItem('username') || '',
      role: localStorage.getItem('role') || '',
      token: localStorage.getItem('token') || '',
      currentLanguage: localStorage.getItem('currentLanguage') || 'cn',
    },
    middlewares,
  )
  return (
    <Provider store={store}>
      <BrowserRouter style={{ position: 'relative' }}>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/Header" component={Header}/>
          <Route exact path="/Sidebar" component={Sidebar}/>
          <Route exact path="/main" component={Main} />
          <Route exact path="/Set" component={Setting} />
          <Route exact path="/Dynamic" component={Dynamic} />
          <Route exact path="/Barcode" component={Barcode} />
          <Route exact path="/POmodule" component={POmodule} />
          <Route exact path="/ProductMaster" component={ProductMaster} />
          <Route exact path="/DeliveryModule" component={DeliveryModule} />
          <Route exact path="/QuotationModule" component={QuotationModule} />
          <Route exact path="/CustomerMaster" component={CustomerMaster} />
          <Route exact path="/CategoryMaster" component={CategoryMaster} />
          <Route exact path="/DepartmentMaster" component={DepartmentMaster} />
          <Route exact path="/CreateQuotation" component={CreateQuotation} />
          <Route exact path="/OrderManagement" component={OrderManagement} />
          <Route exact path="/Dashboard" component={Dashboard} />
          <Route exact path="/StoreMaster" component={StoreMaster} />
          <Route exact path="/UserManagement" component={UserManagement} />
          <Route exact path="/OrderManagement" component={OrderManagement} />
          {/* <Route exact path="/Pricelvl" component={Pricelvl} /> */}
          {/* <Route exact path="/StockInformation" component={StockInformation} /> */}
          
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
