import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import { AddPriceLevelFNC, UpdatePriceLevelFNC } from '../Api';
import { Modal, Form } from 'react-bootstrap';
var timer

class PriceLevelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CostPrice: '',
      AvgCost: '',
      SellingPrice: '',
      UpdatedDate: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      CostPriceLine: false,
      AvgCostLine: false,
      SellingPriceLine: false,
      CreatedByLine: false,
      UpdatedDateLine: false,
      SelectedID: '',
    }
  }

  componentDidMount() {
    this.GetProductGroup();
    if (this.props.OpenModalType === 'EditPriceLevel') {
      this.setState({ 
        CostPrice: this.props.PriceLevelDetails.CostPrice,
        AvgCost: this.props.PriceLevelDetails.AvgCost,
        SellingPrice: this.props.PriceLevelDetails.SellingPrice,
        UpdatedDate: this.props.PriceLevelDetails.UpdatedDate,
        CreatedBy: this.props.PriceLevelDetails.Createdby,
        SelectedID: this.props.PriceLevelDetails.SelectedID,
      })
    } else {
      this.setState({ 
        UpdatedDate: this.state.CreatedDate,
        CreatedBy: this.props.username,
      })
    }
  }

  async GetProductGroup() {
    console.log('GetProductGroup')
    // const getUserGroups = await callUserGroup();
  }

  async CreatePriceLevel() {
    const AvgCost = this.state.AvgCost
    const CostPrice = this.state.CostPrice
    const SellingPrice = this.state.SellingPrice
    const UpdatedDate = this.state.UpdatedDate
    // const PaymentTerms = this.state.PaymentTerms
    const CreatedBy = this.state.CreatedBy

    if (CostPrice.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert CostPrice', 'CostPriceLine')
    } else if (AvgCost === '') {
      this.DisplayErrorMEssage('Please Insert AvgCost', 'AvgCostLine')
    } else if (SellingPrice.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert SellingPrice', 'SellingPriceLine')
    } else if (UpdatedDate.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert UpdatedDate', 'UpdatedDateLine')
    } else if (CreatedBy.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert CreatedBy', 'CreatedByLine')
    } else {
      let PriceLevelDetails = {
        CostPrice: CostPrice,
        AvgCost: AvgCost,
        SellingPrice: SellingPrice,
        UpdatedDate: this.state.CreatedDate,
        Createdby: CreatedBy,
      }
      if (this.props.OpenModalType === 'EditPriceLevel') {
        const EditPriceLevel = await UpdatePriceLevelFNC([PriceLevelDetails], this.state.SelectedID)
        if (EditPriceLevel.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Update Price Level ${CostPrice}`)
        }
      } else {
        const AddPriceLevel = await AddPriceLevelFNC([PriceLevelDetails])
        if (AddPriceLevel.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created Price Level ${CostPrice}`)
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        CostPriceLine: false,
        AvgCostLine: false,
        SellingPriceLine: false,
        CreatedByLine: false,
        UpdatedDateLine: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 3000);
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.AddPriceLevelModal} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditPriceLevel') ? `✎ Edit Price Level` : 'Create Price Level'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Cost Price</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.CostPriceLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CostPrice} onChange={(e) => this.setState({ CostPrice: e.target.value })} placeholder='CostPrice'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Avg Cost</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.AvgCostLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.AvgCost} onChange={(e) => this.setState({ AvgCost: e.target.value })} placeholder='Avg Cost'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Selling Price</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.SellingPriceLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.SellingPrice} onChange={(e) => this.setState({ SellingPrice: e.target.value })} placeholder='Selling Price'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Updated Date</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control readOnly style={{ width: 'calc(100%/1.5)', border: (this.state.UpdatedDateLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.UpdatedDate} onChange={(e) => this.setState({ UpdatedDate: e.target.value })}/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>CreatedBy</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control readOnly style={{ width: 'calc(100%/1.5)', border: (this.state.CreatedByLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.CreatedBy} onChange={(e) => this.setState({ CreatedBy: e.target.value })}/>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreatePriceLevel()}>{(this.props.OpenModalType === 'EditPriceLevel') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceLevelModal);
