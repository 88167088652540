import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import loading from '../image/loading2.gif';
import Successign from '../image/Success1.gif';
import Sidebar from '../Component/Sidebar';
import { Modal, Table, Alert, Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

var timer

class OrderManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state,
      ModalShowDetail: false,
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      notificationMessage: '',
      loading: false,
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      SearchOrderStatus: 'All',
      SearchOrderType: 'All',
      SelectedCheckOrderType: [],
    }
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ModalShowDetail: false})
  }

  async componentDidMount() {
    console.log('data', this.state.data)
    if (this.state.data === 'Complete Quotes !!') {
      this.openNotification('Created Quotation');
    }
  }


  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  ReceivedClicked() {
    console.log('Clicked Received')
  }

  OpenCreateQoute() {
    this.props.history.push('/CreateQuotation')
  }

  ViewClicked() {
    console.log('View')
    this.setState({ ModalShowDetail: true })
  }

  onValueChange(SelectedValue, SelectedType) {
    this.setState({ [SelectedType]: SelectedValue })
  }

  CheckboxSelected(values) {
    let SelectedCheckOrderType = this.state.SelectedCheckOrderType
  }
  
  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row'}}>
        <Sidebar/>
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '12px', width: '100%' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              {this.state.notificationMessage === '' &&
                <h3 style={{ marginRight: 10, color: "white" }}>{'Order Process'}</h3>
              }
              {this.state.notificationMessage !== '' &&
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
              }
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'row', width: '98%' }}>
                <div>sss</div>
                <div>AAAA</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10}}>
                <div style={{ border: '1px solid lightgrey', width: 'calc(100%)', minHeight: '20vh', marginRight: 5, display: 'flex', flexDirection: 'column', padding: 5 }}>
                  <div style={{ fontWeight: 'bold', marginTop: -15, width: 'calc(100%/4)', backgroundColor: 'rgba(225,225,225, 2)' }}>Date Filter</div>
                  <div className='dcc' style={{ display: 'flex', flexDirection: 'column', width: 'calc(100%)', paddingTop: 15 }}>
                    <div>Date</div>
                    <div style={{ width: 'calc(80%)'}}><Form.Control style={{ fontSize: '13px' }} value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/></div>
                    <div>To</div>
                    <div style={{ width: 'calc(80%)'}}><Form.Control style={{ fontSize: '13px' }} value={this.state.toDate} onChange={(e) => this.setState({ toDate: e.target.value })} type="date"/></div>
                  </div>
                </div>
                <div style={{ border: '1px solid lightgrey', width: 'calc(100%)', minHeight: '20vh', marginRight: 5, display: 'flex', flexDirection: 'column', padding: 5 }}>
                  <div style={{ fontWeight: 'bold', marginTop: -15, width: 'calc(100%/3)', backgroundColor: 'rgba(225,225,225, 2)' }}>Order Status</div>
                  <div style={{ paddingTop: 2, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column'}}>
                      {['All', 'Pending', 'Printed', 'Picking', 'In Que', 'Packed'].map((items, index) => {
                        return(
                          <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', width: 'calc(100%)', minHeight: 25 }} key={index} onClick={() => this.onValueChange(items, 'SearchOrderStatus')}>
                            <input type='radio'
                              style={{ marginRight: 5 }}
                              value={items}
                              checked={this.state.SearchOrderStatus === items}
                              onChange={(e) => this.onValueChange(e.value, 'SearchOrderStatus')}>
                            </input>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100%)'}}>
                              <div>{items}</div>
                              {items !== 'All' && 
                                <div>
                                  <div style={{ borderRadius: 5, backgroundColor: (items === 'Pending') ? 'lightgreen' : (items === 'Printed') ? 'yellow' : (items === 'Picking') ? 'lightblue' : (items === 'In Que') ? 'pink' : 'orange', border: '2px solid white', width: 40, height: 15}}></div>
                                </div>}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column', marginLeft: 5}}>
                      <div style={{ minHeight: 20}}></div>
                      <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', width: 'calc(100%)', minHeight: 25 }} onClick={() => this.onValueChange('Ready to Ship', 'SearchOrderStatus')}>
                        <input type='radio'
                          style={{ marginRight: 5 }}
                          value={'Ready to Ship'}
                          checked={this.state.SearchOrderStatus === 'Ready to Ship'}
                          onChange={(e) => this.onValueChange(e.value, 'SearchOrderStatus')}>
                        </input>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100%)'}}>
                          <div>{'Ready to Ship'}</div>
                          <div>
                            <div style={{ borderRadius: 5, backgroundColor: 'red', border: '2px solid white', width: 40, height: 15}}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ border: '1px solid lightgrey', width: 'calc(100%)', minHeight: '20vh', marginRight: 5, display: 'flex', flexDirection: 'column', padding: 5 }}>
                  <div style={{ fontWeight: 'bold', marginTop: -15, width: 'calc(100%/2)', backgroundColor: 'rgba(225,225,225, 2)' }}>Filter Order Type</div>
                  <div style={{ display: 'flex', flexDirection: 'row'}}>
                    {['All', 'Single', 'Same', 'Mix'].map((items, idd) => {
                      return(
                        <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', width: 'calc(100%)', minHeight: 25, alignItems: 'center' }} key={idd} onClick={() => this.onValueChange(items, 'SearchOrderType')}>
                          <input type='radio'
                            style={{ marginRight: 5 }}
                            value={items}
                            checked={this.state.SearchOrderType === items}
                            onChange={(e) => this.onValueChange(e.value, 'SearchOrderType')}>
                          </input>
                          {items}
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 15 }}>
                    <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'column' }}>
                      <div style={{ minHeight: 35, padding: 5 }}>
                        <div onClick={() => this.CheckboxSelected('S Tick')}><input type="checkbox" style={{ marginRight: 10 }} checked={this.state.SelectedCheckOrderType.includes('Sort Shelf')} onChange={() => this.CheckboxSelected('Sort Shelf')}/>Sort Shelf</div>
                      </div>
                      <div style={{ minHeight: 35, padding: 5 }}>
                        <div onClick={() => this.CheckboxSelected('S Tick')}><input type="checkbox" style={{ marginRight: 10 }} checked={this.state.SelectedCheckOrderType.includes('SLA Bridge')} onChange={() => this.CheckboxSelected('SLA Bridge')}/>SLA Bridge</div>
                      </div>
                      <div style={{ minHeight: 35, padding: 5 }}>
                        <div onClick={() => this.CheckboxSelected('S Tick')}><input type="checkbox" style={{ marginRight: 10 }} checked={this.state.SelectedCheckOrderType.includes('S Tick')} onChange={() => this.CheckboxSelected('S Tick')}/>S Tick</div>
                      </div>
                    </div>
                    <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'column' }}>
                      <div style={{ minHeight: 70 }}></div>
                      <div className='dcc' style={{ minHeight: 35 }}><div style={{ border: '1px solid lightgrey', borderRadius: 5, padding: 10, background: 'white', cursor: 'pointer', paddingLeft: 15, paddingRight: 15 }}>Search</div></div>
                    </div>
                  </div>
                </div>
                <div style={{ border: '1px solid lightgrey', width: 'calc(100%/2.5)', minHeight: '20vh' }}></div>
              </div>
            </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirectioxn: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Created Date</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Quote.Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Quote.No</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Customer Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Amount</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Status</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.PODataLists.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 5, paddingBottom: 5 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing1</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing2</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing3</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing4</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>Testing5</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100, display: 'flex' }}>
                        {items.StatusStore === 'active' ? (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Active</div>
                        ) : (
                          <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#EB921B', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Not Active</div>
                        )}
                      </div>
                      <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ViewClicked()}>View</div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ReceivedClicked()}>
                          Download Quote
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderManagement);
