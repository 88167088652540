import React from 'react';
import { connect } from 'react-redux';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBBtn,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { FaRegListAlt, FaBox, FaUserAlt, FaStore, FaSitemap, FaUsersCog, FaIdCardAlt, FaFileInvoiceDollar, FaFile } from 'react-icons/fa';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

class sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedExample: this.props.currentLanguage,
    }
  }

  componentDidMount() {
    // this.timerID = setInterval(
    //   () => this.runtime(),
    //   1000
    // );
  }

  //   runtime() {
  //     this.setState({
  //       date: moment(this.state.date).add(1, 's').format('hh:mm:ss a YYYY-MM-DD')
  //     });
  //   }

  //   componentWillUnmount() {
  //     clearInterval(this.timerID);
  //   }

  //   setLanguage() {
  //     if(this.state.SelectedExample === 'cn') {
  //       this.setState({ SelectedExample: 'en' })
  //       this.props.setLanguage('en')
  //     } else {
  //       this.setState({ SelectedExample: 'cn' })
  //       this.props.setLanguage('cn')
  //     }
  //   }

  LogOutFucntion() {
    const r = (window.confirm(`Confirm Logout ?`));
    if (r === true) {
      this.props.setLoginStatus('', '', '');
      window.location.href = '/';
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className='scrollhost' style={{ display: 'flex', minHeight: '100vh' }}>
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <span className="cannothightlight" style={{ display: 'flex', flexDirection: 'column'}}>
              <span>{`EWMS MENU `}</span>
              <span>{`(${this.props.username})`}</span>
            </span>
          </CDBSidebarHeader>
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              {/* <NavLink exact to="/Barcode" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="columns">Barcode</CDBSidebarMenuItem>
              </NavLink> */}
              <NavLink exact to="/Dashboard" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="th-large">Dashboard</CDBSidebarMenuItem>
                
              </NavLink>
              <Menu menuItemStyles={{
                button: {
                  [`&.active`]: {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                },
              }}>
                <SubMenu icon={<FaRegListAlt  />} style={{ backgroundColor: '#333333' }} label="Stock Manage">
                  <MenuItem className="ProductMasterSelect" icon={<FaBox />} component={<NavLink to="/ProductMaster" />}>Product</MenuItem>
                  {/* <MenuItem className="ProductMasterSelect" icon={<FaBook />} component={<NavLink to="/Pricelvl" />}>Price level</MenuItem> */}
                  {/* <MenuItem className="ProductMasterSelect" icon={<FaBoxOpen />} component={<NavLink to="/StockInformation" />}>Stock Information</MenuItem> */}
                  <MenuItem className="CategoryMasterSelect" icon={<FaSitemap />} component={<NavLink to="/CategoryMaster" />}>Category</MenuItem>
                  <MenuItem className="DepartmentMasterSelect" icon={<FaIdCardAlt />} component={<NavLink to="/DepartmentMaster" />}>Department Master</MenuItem>
                </SubMenu>
              </Menu>
              <Menu menuItemStyles={{
                button: {
                  [`&.active`]: {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                },
              }}>
                <SubMenu icon={<FaStore  />} style={{ backgroundColor: '#333333' }} label="Store">
                  <MenuItem className="StoreMasterSelect" icon={<FaStore />} component={<NavLink to="/StoreMaster" />}>Store</MenuItem>
                </SubMenu>
              </Menu>
              <Menu menuItemStyles={{
                button: {
                  [`&.active`]: {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                },
              }}>
                <SubMenu icon={<FaUsersCog  />} style={{ backgroundColor: '#333333' }} label="Users Management">
                  <MenuItem className="UserManagementSelect" icon={<FaUsersCog />} component={<NavLink to="/UserManagement" />}>User Master</MenuItem>
                  <MenuItem className="CustomerMasterSelect" icon={<FaUserAlt />} component={<NavLink to="/CustomerMaster" />}>Customer Master</MenuItem>
                </SubMenu>
                <SubMenu icon={<FaFileInvoiceDollar  />} style={{ backgroundColor: '#333333' }} label="Quotation Module">
                  <MenuItem className="UserManagementSelect" icon={<FaFileInvoiceDollar />} component={<NavLink to="/QuotationModule" />}>Quotation Module</MenuItem>
                </SubMenu>
                <SubMenu icon={<FaFile  />} style={{ backgroundColor: '#333333' }} label="Order Management">
                  <MenuItem className="UserManagementSelect" icon={<FaFile />} component={<NavLink to="/OrderManagement" />}>Order Management</MenuItem>
                </SubMenu>
              </Menu>
              {/* <NavLink exact to="/ProductMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="shopping-bag">Product Master</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/CustomerMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">Customer Master</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/StoreMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="building">Store Master</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/CategoryMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="car">Category Master</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/DepartmentMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="book">Department Master</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/UserManagement" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="user">User Management</CDBSidebarMenuItem>
              </NavLink> */}
              {/* <NavLink exact to="/DepartmentMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="envelope">New Message</CDBSidebarMenuItem>
              </NavLink> */}
              {/* <NavLink exact to="/DepartmentMaster" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="setting-icon">Settings</CDBSidebarMenuItem>
              </NavLink> */}
              {/* <NavLink exact to="/POmodule" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="table">POmodule</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/DeliveryModule" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="sticky-note">DOModule</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/QuotationModule" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="chart-line">Quotation</CDBSidebarMenuItem>
              </NavLink> */}
              <NavLink exact to="/hero404" target="_blank" activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="exclamation-circle">404 page</CDBSidebarMenuItem>
              </NavLink>
              <div className='dcc' style={{ width: '100%', marginTop: 20 }}>
                {/* <div className='dcc LogoutPoint' style={{ borderRadius: 50, width: '80%', cursor: 'pointer', padding: 5 }} onClick={()=> this.LogOutFucntion()}> Logout</div> */}
                <CDBBtn style={{ width: '80%',  }} circle color="danger"  onClick={()=> this.LogOutFucntion()}>Logout
                </CDBBtn>
              </div>
              {/* <CDBBtn color="info">Info</CDBBtn> */}
            </CDBSidebarMenu>
          </CDBSidebarContent>
          <CDBSidebarFooter style={{ textAlign: 'center' }}>
            <div
              style={{
                padding: '20px 5px',
              }}
            >
              Sidebar Footer
            </div>
          </CDBSidebarFooter>
        </CDBSidebar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(sidebar);
