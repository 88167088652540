import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import Sidebar from '../Component/Sidebar';
import { Form } from 'react-bootstrap';
// import Xls from '../image/xls2.png';
import ExportExcel from '../image/ExportExcel.png';
import loading from '../image/loading2.gif';
import Successign from '../image/Success1.gif';
import CreateNewCustomalModal from '../Component/CreateNewCustomalModal';
import { ExportCustomer } from '../jsonToXlsx';
import { getCustomerDetailsFNC, DeleteDataFNC, GetExcelDataFNC } from '../Api';
import MessageModal from '../Component/MessageModal';
import moment from 'moment-timezone';
import { FaTrash,FaEdit } from 'react-icons/fa';

var timer

class CustomerMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalShowDetail: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [{}],
      DisplayCusterAdd: false,
      CustomerDetails: [],
      OpenModalType: '',
      CustomerSearch: '',
      notificationMessage: '',
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      loading: false,
    }
    this.handleClose = this.handleClose.bind(this);
    this.CallApi = this.CallApi.bind(this);
    this.DeleteUsers = this.DeleteUsers.bind(this);
  }

  handleClose() {
    this.setState({ DisplayCusterAdd: false, CustomerDetails: [], OpenModalType: '', DisplayConfirmMessage: false }, () => {
      this.CallApi();
    })
  }

  async CallApi() {
    const getCustomers = await getCustomerDetailsFNC(this.state.CustomerSearch, this.state.page, this.state.countperpage)
    if (getCustomers.message === 'Call success') {
      this.setState({ CustomerDetails: getCustomers.Customers })
      if (getCustomers.Customers.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getCustomers.Customers[0].FullCount / this.state.countperpage,
          ),
        });
      }
    }
  }

  async componentDidMount() {
    this.CallApi();
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message) {
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        notificationMessage: ''
      });
    }, 3000);
  };

  EditCustomerClick(CustomerDetails) {
    let TestingCustomerDetails = {
      CustomerCode: CustomerDetails.CustomerCode,
      CustomerComponyName: CustomerDetails.CustomerCompanyName,
      AttnName: CustomerDetails.AttnName,
      Tel: CustomerDetails.Tel,
      CompanyAddress: CustomerDetails.CompanyAddress,
      PaymentTerms: CustomerDetails.PaymentTerms,
      ID: CustomerDetails.ID,
      Createdby: CustomerDetails.Createdby,
      CreatedDate: CustomerDetails.CreatedDate,
    }
    this.setState({ CustomerDetails: TestingCustomerDetails, DisplayCusterAdd: !this.state.DisplayCusterAdd, OpenModalType: 'EditCustomer' })
  }

  DeleteUser(SelectedUser) {
    this.setState({ DisplayConfirmMessage: true, CustomerDetails: SelectedUser, OpenModalType: 'Customer' })
  }

  async DeleteUsers() {
    // const DeleteCustomer = await DeleteCustomerFNC([this.state.CustomerDetails.ID]);
    // if (DeleteCustomer.message === 'Deleted') {
    //   this.openNotification('Deleted Customer Success');
    //   this.handleClose();
    // }
    const DeleteCustomer = await DeleteDataFNC([this.state.CustomerDetails.ID], 'CustomerMaster');
    if (DeleteCustomer.message === 'Deleted') {
      this.openNotification('Success Deleted User Group');
      this.handleClose();
    }
  }
  
  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.CallApi()
    }
  }

  async ExportExcelFile() {
    let ExcelDisplayData = []
    // const getCustomerdata = await GetExcelDataFNC(this.state.CustomerSearch, 'CustomerMaster');
    // if (getCustomerdata.message === 'Call success') {
    //   ExcelDisplayData = getCustomerdata.ReturnExcelData
    //   ExportCustomer(ExcelDisplayData)
    // }
  }

  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div> */}
        <Sidebar/>
        {this.state.DisplayCusterAdd && 
        <CreateNewCustomalModal DisplayCusterAdd={this.state.DisplayCusterAdd} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} CustomerDetails={this.state.CustomerDetails} CallApi={this.CallApi}/>}
        {this.state.DisplayConfirmMessage && 
        <MessageModal DisplayConfirmMessage={this.state.DisplayConfirmMessage} handleClose={this.handleClose} DeleteUser={this.DeleteUsers} CustomerDetails={this.state.CustomerDetails} OpenModalType={this.state.OpenModalType}/>}
        <div className='DashboardBackground' style={{ paddingLeft: 10, paddingRight: 10, fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-between' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: "white", display: 'flex', flexDirection: 'row' }}>
                {this.state.notificationMessage === '' &&  <div>{'Customer Master'}</div>}
                {this.state.notificationMessage !== '' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
                }
              </h3>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid lightgrey', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}>Custom Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Customer Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      {/* <Form.Control value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: e.target.value })} type="date"/> */}
                      <Form.Control onKeyPress={(e) => this.onKeyEnter(e)} style={{ width: 'calc(100%)' }} value={this.state.CustomerSearch} onChange={(e) => this.setState({ CustomerSearch: e.target.value })} placeholder='Search Company Name / Customer Code'/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15 }}>
                  {/* <label htmlFor="file-Upload" style={{ marginRight: 10}}>
                    <img className='ExcelfileUpload'  style={{ backgroundColor: (this.state.ExcelUpload.length > 0) ? 'green' : '' }} for="file-Upload" src={Xls} alt='' />
                    <input type='file' id="file-Upload" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                  </label> */}
                  <label style={{ marginRight: 10 }}>
                    <img className='ExcelfileUpload' src={ExportExcel} alt='Print' onClick={() => this.ExportExcelFile() } />
                  </label>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13}}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => this.CallApi() }>Search</div>
                  <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.setState({ DisplayCusterAdd: !this.state.DisplayCusterAdd, OpenModalType: 'AddCustomer' })}>Add Customer</div>
                </div>
              </div>
            </div>
            {this.state.PODataLists.length > 0 &&
            // <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 40, padding: 10, minHeight: '60vh', maxHeight: '60vh', backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
            //   <div style={{ width: '100%', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
            //     <div style={{ width: 'calc(100%)'}}>Customer Code</div>
            //     <div style={{ width: 'calc(100%)'}}>Customer Company Name</div>
            //     <div style={{ width: 'calc(100%)'}}>Tel</div>
            //     <div style={{ width: 'calc(100%)'}}>Company Address</div>
            //     <div style={{ width: 'calc(100%)'}}>Attn Name</div>
            //     <div style={{ width: 'calc(100%)'}}>Payment Term</div>
            //     <div style={{ width: 'calc(100%)'}}>Created Date</div>
            //     <div style={{ width: 'calc(100%)'}}>Created By</div>
            //     <div style={{ width: 'calc(100%)'}}>Action</div>
            //   </div>
            //   <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: 'auto', overflowX: 'auto'}}>
            //     {(this.state.CustomerDetails.length > 0 && this.state.loading === false) && 
            //     <>
            //       {this.state.CustomerDetails.map((items, index) => {
            //         return(
            //           <div key={index} style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey' }}>
            //             <div style={{ width: 'calc(100%)'}}>{items.CustomerCode}</div>
            //             <div style={{ width: 'calc(100%)'}}>{items.CustomerCompanyName}</div>
            //             <div style={{ width: 'calc(100%)'}}>{items.Tel}</div>
            //             <div style={{ width: 'calc(100%)'}}>{items.CompanyAddress}</div>
            //             <div style={{ width: 'calc(100%)', display: 'flex'}}>
            //               {items.AttnName}
            //               {/* <div style={{ width: 'auto', borderRadius: 5, backgroundColor: '#029C51', color: 'white', fontWeight: 'bold', paddingLeft: 7, paddingRight: 7 }}>Paid</div> */}
            //             </div>
            //             <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
            //               <div style={{ width: 'calc(100%)' }}>{items.PaymentTerms}</div>
            //             </div>
            //             <div style={{ width: 'calc(100%)'}}>{items.CreatedDate}</div>
            //             <div style={{ width: 'calc(100%)'}}>{items.Createdby}</div>
            //             <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
            //               {/* <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer', color: 'blue' }} onClick={() => this.EditCustomerClick(items)}>Edit Users</div> */}
            //               <div  className="EditWord" style={{ width: 'calc(100%)', cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.EditCustomerClick(items)}>
            //                 <FaEdit/>
            //               </div>
            //               <div className='TongRubbish' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteUser(items)}>
            //                 <FaTrash/>
            //               </div>
            //             </div>
            //           </div>
            //         );
            //       })}
            //       {(this.state.CustomerDetails.length === 0 && this.state.loading === false) && 
            // <div className='dcc' style={{ fontWeight: 'bold', padding: 20 }}>No Data</div>
            //       }
            //     </>}
            //   </div>
            //   <div className='dcc' style={{ width: '100%', borderTop: '1px solid lightgrey', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
            //     {this.state.page > this.state.countpagemin ? (
            //       <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
            //         this.CallApi();
            //       })}><div className="button-30" role="button">{'<<'}</div></div>
            //     ) : (
            //       <div style={{ width: 100 }}/>
            //     )}
            //     <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
            //     {this.state.page < this.state.countpagemax ? (
            //       <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
            //         this.CallApi();
            //       })}><div className="button-30" role="button">{'>>'}</div></div>
            //     ) : (
            //       <div style={{ width: 100 }} />
            //     )}
            //   </div>
            // </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Customer Code</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Customer Company Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Tel</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Company Address</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Attn Name</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Payment Term</div>
                  {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Created Date</div> */}
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.CustomerDetails.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.CustomerDetails.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CustomerCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CustomerCompanyName}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Tel}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CompanyAddress}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.AttnName}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.PaymentTerms}</div>
                      {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CreatedDate}</div> */}
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditCustomerClick(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteUser(items)}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMaster);
