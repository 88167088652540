import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Attentions from '../image/Attention2.png';
import { Button, Modal } from 'react-bootstrap';

class MessageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Modal centered show={this.props.DisplayConfirmMessage} size="modal-sm" onHide={() => this.props.handleClose()}>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px', backgroundImage: 'linear-gradient(to right, #815334, #8e6448, #A87453, #6f4e38)'}}>
          <div id="appear" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 , alignItems: 'center', color: 'white' }}>
            <img src={Attentions} alt="Logo" className='AttentionsLogo' style={{ marginTop: 10, marginBottom: 10 }} />
            <div style={{ fontSize: '17px'}}>
              <div>{`Are you Sure you want to Delete :`}</div>
              <div className='dcc' style={{ marginLeft: 5, marginRight: 5, fontFamily: 'cursive', color: 'black'}}>{`${this.props.DepartmentDetails.DeptCode}`}</div>
              {/* <div>{`Packages`}</div> */}
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <Button variant="danger" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.props.DeleteUser()}>{`Delete User`}</Button>
            <Button variant="success" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.props.handleClose()}>{`No`}</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
