import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import Select from 'react-select';
import { CreateUsersFNC, UpdateUsersFNC, getUserCreateGroupFNC } from '../Api';
import { Button, Modal, Form } from 'react-bootstrap';
var timer

class CreateUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Name: '',
      Password: '',
      Group: '',
      Status: '',
      PaymentTerms: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      UsernameLine: false,
      NameLine: false,
      PasswordLine: false,
      StatusLine: false,
      PaymentLine: false,
      GroupLine: false,
      isChecked: true,
      SelectedID: '',
      GroupTypeArray: [],
    }
  }

  componentDidMount() {
    this.GetUserGroup();
    if (this.props.OpenModalType === 'EditCustomer') {
      const SelectedSatus = (this.props.UsersDetails.Status === '1') ? true : false
      this.setState({ 
        Username: this.props.UsersDetails.Username,
        Name: this.props.UsersDetails.Name,
        Password: this.props.UsersDetails.Password,
        Group: this.props.UsersDetails.Group,
        isChecked: SelectedSatus,
        SelectedID: this.props.UsersDetails.ID,
      })
    }
  }

  async GetUserGroup() {
    const getUserGroups = await getUserCreateGroupFNC();
    let tempObject = {}
    let tempArray = []
    if (getUserGroups.message === 'Call success') {
      for (var i = 0; i < getUserGroups.Groups.length; i++ ) {
        tempObject = {
          value: getUserGroups.Groups[i],
          label: getUserGroups.Groups[i],
        }
        tempArray.push(tempObject)
      }
      this.setState({
        GroupTypeArray: getUserGroups.Groups
      })
    }
  }

  async CreateUser() {
    const Name = this.state.Name
    const Username = this.state.Username
    const Password = this.state.Password
    const Group = this.state.Group
    // const PaymentTerms = this.state.PaymentTerms
    const isChecked = this.state.isChecked

    if (Username.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Username', 'UsernameLine')
    } else if (Name === '') {
      this.DisplayErrorMEssage('Please Insert Name', 'NameLine')
    } else if (Password.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Password', 'PasswordLine')
    } else if (Group.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert Annt Name', 'GroupLine')
    } else {
      let UserDetails = {
        Username: Username,
        Name: Name,
        Password: Password,
        Group: Group,
        Status: isChecked,
      }
      if (this.props.OpenModalType === 'EditCustomer') {
        const AddUser = await UpdateUsersFNC(UserDetails, this.state.SelectedID)
      
        if (AddUser.error === 'Mandarin Not Allow') {
          this.DisplayErrorMEssage('Mandarin Not Allow', 'error')
        }
        if (AddUser.error === 'New Password Match with Current Password!') {
          this.DisplayErrorMEssage('New Password Match with Current Password!', 'PasswordLine')
        }
        if (AddUser.message === 'Update success') {
          this.DisplaySuccessMEssage(`Successful Updated User ${Name}`)
        }
      } else {
        const AddUser = await CreateUsersFNC([UserDetails])
        if (AddUser.message === 'Add success') {
          this.DisplaySuccessMEssage(`Successful Created User ${Name}`)
        }
        if (AddUser.error === 'Duplicated Username Exist in your input') {
          this.DisplayErrorMEssage(`${AddUser.error}`, 'error')
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        [WhichOne]: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 3000);
  }

  SetActiveUSer(event) {
    this.setState({ isChecked: event.target.checked })
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.DisplayCusterAdd} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditCustomer') ? `✎ Edit User` : '👥 Create New User'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
            }
            {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ marginBottom: 10, color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
            } */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>User Name</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.UsernameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Username} onChange={(e) => this.setState({ Username: e.target.value })} placeholder='Username'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Name</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.NameLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Name} onChange={(e) => this.setState({ Name: e.target.value })} placeholder='Name'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Password</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.PasswordLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} placeholder='Password'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>User group</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              {/* <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ width: 'calc(100%/1.5)', border: (this.state.GroupLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Group} onChange={(e) => this.setState({ Group: e.target.value })} placeholder='Group'/>
              </div> */}
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Select style={{ fontSize: '13px', width: 'calc(100%/1.5)',  border: (this.state.GroupLine === true) ? '1px solid red' : '1px solid lightgrey' }} 
                  value={this.state.Group} onChange={(e) => this.setState({ Group: e.target.value })}>
                  <option value=''>-</option>
                  {this.state.GroupTypeArray.map((item, index) => 
                    <option key={index} value={item}>{item.toUpperCase()}</option>
                  )}
                </Form.Select>
                {/* <div style={{ width: 'calc(100%/1.5)',  border: (this.state.GroupLine === true) ? '1px solid red' : '1px solid lightgrey', borderRadius: 5 }}>
                  <Select
                    options={this.state.GroupTypeArray}
                    value={this.state.SelectedGroup}
                    onChange={(e) => this.setState({ Group: e.value, SelectedGroup: e })}
                  >
                  </Select>
                </div> */}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div>Status</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                {/* <textarea
                  style={{ width: 'calc(100%/1.5)', border: (this.state.StatusLine === true) ? '1px solid red' : '1px solid lightgrey' }}
                  className="form-control"
                  value={this.state.Status}
                  onChange={(e) => this.setState({ Status: e.target.value })}
                  rows="5"
                /> */}
                {/* <label class="switch">
                  <input type="checkbox" onChange={this.handleChange} value={this.state.isChecked} />
                  <div class="slider"></div>
                </label> */}
                <label className="switch">
                  <input type="checkbox" value={this.state.isChecked} checked={this.state.isChecked} onChange={(e) => this.SetActiveUSer(e)} />
                  <div className="slider"></div>
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditCustomer') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal);
