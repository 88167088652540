import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Errorsign from '../image/errorsign.gif';
import Successign from '../image/Success1.gif';
// import Attentions from '../image/Attention2.png';
import { AddShelfFNC, UpdateShelfFNC } from '../Api';
import { Modal, Form } from 'react-bootstrap';
var timer

class ShelfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShelfNo: '',
      Balance: '',
      StoreCode: '',
      CreatedBy: '',
      CreatedDate: moment(new Date()).format('YYYY-MM-DD'),
      MessageType: '',
      MessageShow: '',
      ShelfNoLine: false,
      BalanceLine: false,
      StoreCodeLine: false,
      SelectedID: '',
      GroupTypeArray: [],
    }
  }

  componentDidMount() {
    if (this.props.OpenModalType === 'EditShelf') {
      this.setState({ 
        ShelfNo: this.props.ShelfDetails.ShelfNo,
        Balance: this.props.ShelfDetails.Balance,
        StoreCode: this.props.ShelfDetails.StoreCode,
        SelectedID: this.props.ShelfDetails.SelectedID,
      })
    }
  }

  async ShelfFunctions() {
    const Balance = this.state.Balance
    const ShelfNo = this.state.ShelfNo
    const StoreCode = this.state.StoreCode

    if (ShelfNo.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert ShelfNo', 'ShelfNoLine')
    } else if (Balance === '') {
      this.DisplayErrorMEssage('Please Insert Balance', 'BalanceLine')
    } else if (StoreCode.trim().length === 0) {
      this.DisplayErrorMEssage('Please Insert StoreCode', 'StoreCodeLine')
    } else {
      let UserDetails = {
        ShelfNo: ShelfNo,
        Balance: Balance,
        StoreCode: StoreCode,
      }
      if (this.props.OpenModalType === 'EditShelf') {
        console.log('EfitDate')
        const EfitDate = await UpdateShelfFNC(UserDetails, this.state.SelectedID)
        if (EfitDate.message === 'Update success') {
          this.DisplaySuccessMEssage(EfitDate.message);
        }
      } else {
        const SaveData = await AddShelfFNC([UserDetails])
        if (SaveData.error === 'Duplicated Shelf Exist in your input') {
          this.DisplayErrorMEssage(SaveData.error)
        }
        if (SaveData.message === 'Add success') {
          this.DisplaySuccessMEssage(SaveData.message);
        }
      }
    }
  }

  DisplayErrorMEssage(message, WhichOne) {
    this.setState({ MessageType: 'Error', MessageShow: message, [WhichOne]: true })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
        ShelfNoLine: false,
        BalanceLine: false,
        StoreCodeLine: false,
      });
    }, 5000);
  }

  DisplaySuccessMEssage(message) {
    this.setState({ MessageType: 'Success', MessageShow: message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        MessageType: '',
        MessageShow: '',
      });
      this.props.handleClose()
    }, 3000);
  }

  SetActiveUSer(event) {
    this.setState({ isChecked: event.target.checked })
  }

  render() {
    return (
      <Modal backdrop="static" centered show={this.props.ShelfModal} size="xl" onHide={() => this.props.handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === '' && 
                <div className='dcc'>{(this.props.OpenModalType === 'EditShelf') ? `✎ Edit Shelf` : '👥 Create New Shelf'}</div>
              }
              {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
              }
              {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '13px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* {this.state.MessageType === 'Error' && 
              <div className='dcc' style={{ marginBottom: 10, color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={Errorsign} alt="Logo" className='ErrorSign' />{this.state.MessageShow}</div>
            }
            {this.state.MessageType === 'Success' && 
              <div className='dcc' style={{ marginBottom: 10, color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.MessageShow}</div>
            } */}
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Shelf No.</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.ShelfNoLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.ShelfNo} onChange={(e) => this.setState({ ShelfNo: e.target.value })} placeholder='ShelfNo'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Balance</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.BalanceLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.Balance} onChange={(e) => this.setState({ Balance: e.target.value })} placeholder='Balance'/>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 10}}>
              <div style={{ width: 'calc(100%/3)', display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div>Store Code</div><div style={{ color: 'red', fontWeight: 'bold', marginLeft: 5 }}>*</div></div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control style={{ fontSize: '13px', width: 'calc(100%/1.5)', border: (this.state.StoreCodeLine === true) ? '1px solid red' : '1px solid lightgrey' }} value={this.state.StoreCode} onChange={(e) => this.setState({ StoreCode: e.target.value })} placeholder='Status Code'/>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
            {/* <div class="button-55" role="button">{(this.props.OpenModalType === 'EditShelf') ? 'Edit' : `Create`}</div> */}
            {/* <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.CreateUser()}>{(this.props.OpenModalType === 'EditShelf') ? 'Edit' : `Create`}</Button> */}
            <div className="button-86" style={{ marginRight: 20 }} role="button" onClick={() => this.ShelfFunctions()}>{(this.props.OpenModalType === 'EditShelf') ? 'Edit' : `Create`}</div>
            <div className="button-86" role="button" onClick={() => this.props.handleClose()}>{`X Close`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShelfModal);
