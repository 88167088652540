import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
// import { language } from '../language';
// import Header from '../Component/Header';
import AddProductModal from '../Component/AddProductModal';
import PriceLevelModal from '../Component/PriceLevelModal';
import ShelfModal from '../Component/ShelfModal';
import ImportExcelProduct from '../Component/ImportExcelProduct';
import Sidebar from '../Component/Sidebar';
import loading from '../image/loading2.gif';
import { Form } from 'react-bootstrap';
import PrintLogo from '../image/Print.png';
import ExportExcel from '../image/ExportExcel.png';
import moment from 'moment-timezone';
import Successign from '../image/Success1.gif';
import { FaTrash, FaEdit} from 'react-icons/fa';
import { ExportProductExcel } from '../jsonToXlsx';
import { getProductMasterFNC, DeleteDataFNC, getShelfMasterFNC, GetExcelDataFNC, getPriceLevelFNC, getStockInfoFNC } from '../Api';
// import PricelvlModal from '../Component/PricelvlModal';
import StockInformationModal from '../Component/StockInfModal';
import * as XLSX from 'xlsx';
import Xls from '../image/xls2.png';

var timer

class ProductMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      PODataLists: [],
      loading: false,
      AddProductModal: false,
      AddPriceLevelModal: false,
      StockInformationModal: false,
      CurrentSelectedPage: 'PM',
      StockSearch: '',
      ExcelValues: [],
      ProductDetails: {},
      ShelfDetails: {},
      StockInfoDetails: {},
      PriceLevelDetails: {},
      StockInformationDetails: {},
      ShelfModal: false,
      page: 1,
      countperpage: 15,
      countpagemax: 1,
      countpagemin: 1,
      UserSearch: '',
      notificationMessage: '',
      DisplayExcelConfirm: false,
      ExcelUpload: [],
    }
    this.handleClose = this.handleClose.bind(this);
    this.UploadExcelFile = this.UploadExcelFile.bind(this);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
  }

  handleClose() {
    this.setState({
      AddProductModal: false,
      StockInformationModal: false,
      OpenModalType: '',
      ShelfModal: false,
      AddPriceLevelModal: false,
      ProductDetails: {},
      ShelfDetails: {},
      PriceLevelDetails: {},
      StockInformationDetails: {},
      UserSearch: '',
      DisplayExcelConfirm: false, ExcelUpload: [],
    }, () => {
      this.CallApi();
    })
  }

  async componentDidMount() {
    this.CallApi();
  }

  logout() {
    window.location.href = '/';
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 4000);
  };

  EditClicked() {
    console.log('Clicked Edit')
  }

  ReceivedClicked() {
    console.log('Clicked Received')
  }
  
  EditProductClick(ProductDetails) {
    let ProductDetail = {
      EditID: ProductDetails.ID,
      SKUCode: ProductDetails.SKUCode,
      Description: ProductDetails.Description,
      OtherDesc: ProductDetails.OtherDesc,
      SKUType: ProductDetails.SKUType,
      Category: ProductDetails.Category,
      Department: ProductDetails.Department,
      PhotoLink: ProductDetails.PhotoLink,
      UOM: ProductDetails.UOM,
      Tax: ProductDetails.Tax,
      CreatedBy: ProductDetails.Createdby,
      CreatedDate: ProductDetails.CreatedDate,
    }
    this.setState({  AddProductModal: !this.state.AddProductModal, OpenModalType: 'EditProduct', ProductDetails: ProductDetail })
  }

  PriceLevelEdit(PriceLevelDetails) {
    console.log('PriceLevelDetails', PriceLevelDetails)
    let PriceLevelDetail = {
      CostPrice: PriceLevelDetails.CostPrice,
      AvgCost: PriceLevelDetails.AvgCost,
      SellingPrice: PriceLevelDetails.SellingPrice,
      UpdatedDate: PriceLevelDetails.UpdatedDate,
      Createdby: PriceLevelDetails.Createdby,
      SelectedID: PriceLevelDetails.ID,
    }
    this.setState({  AddPriceLevelModal: !this.state.AddPriceLevelModal, OpenModalType: 'EditPriceLevel', PriceLevelDetails: PriceLevelDetail })
  }

  ShelfDataEdit(SelectedItems) {
    let ShelfDetails = {
      SelectedID: SelectedItems.ID,
      Balance: SelectedItems.Balance,
      StoreCode: SelectedItems.StoreCode,
      ShelfNo: SelectedItems.ShelfNo,
    }
    this.setState({  ShelfModal: !this.state.ShelfModal, OpenModalType: 'EditShelf', ShelfDetails: ShelfDetails })
  }

  StockInfoEdit(SelectedItems) {
    let StockInfoDetails = {
      SelectedID: SelectedItems.ID,
      Balance: SelectedItems.Balance,
      StoreCode: SelectedItems.StoreCode,
      StoreDesc: SelectedItems.StoreDesc,
      PurchaseDate: SelectedItems.PurchaseDate,
      DoDate: SelectedItems.DoDate,
      ExpiredDate: SelectedItems.ExpiredDate,
    }
    this.setState({  StockInformationModal: !this.state.StockInformationModal, OpenModalType: 'EditStockinfo', StockInfoDetails: StockInfoDetails })
  }

  // EditProductClickStock(CustomerDetails) {
  //   this.setState({  StockInformationModal: !this.state.AddProductModal, OpenModalType: 'EditStockinfo' })
  // }

  // EditProductClickPLVL(CustomerDetails) {
  //   this.setState({  AddPriceLevelModal: !this.state.AddPriceLevelModal, OpenModalType: 'EditPriceLevel' })
  // }
  
  async CallApi() {
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    // console.log('CurrentSelectedPage', CurrentSelectedPage)
    this.setState({ loading: true })
    if (CurrentSelectedPage === 'PM') {
      const getProductMaster = await getProductMasterFNC(this.state.UserSearch, this.state.page, this.state.countperpage);
      if (getProductMaster.message === 'Call success') {
        this.setState({ PODataLists: getProductMaster.Products, loading: false })
        if (getProductMaster.Products.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              getProductMaster.Products[0].FullCount / this.state.countperpage,
            ),
          });
        }
      }
    }
    if (CurrentSelectedPage === 'PL') {
      const getPricelevel = await getPriceLevelFNC(this.state.UserSearch, this.state.page, this.state.countperpage)
      if (getPricelevel.message === 'Call success') {
        this.setState({ PODataLists: getPricelevel.PriceLevel, loading: false })
        if (getPricelevel.PriceLevel.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              getPricelevel.PriceLevel[0].FullCount / this.state.countperpage,
            ),
          });
        }
      }
    }
    if (CurrentSelectedPage === 'SI') {
      const getStockInfoData = await getStockInfoFNC(this.state.UserSearch, this.state.page, this.state.countperpage)
      if (getStockInfoData.message === 'Call success') {
        this.setState({ PODataLists: getStockInfoData.StockInfo, loading: false })
        if (getStockInfoData.StockInfo.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              getStockInfoData.StockInfo[0].FullCount / this.state.countperpage,
            ),
          });
        }
      }
    }
    if (CurrentSelectedPage === 'SM') {
      const getShelfData = await getShelfMasterFNC(this.state.UserSearch, this.state.page, this.state.countperpage)
      if (getShelfData.message === 'Call success') {
        this.setState({ PODataLists: getShelfData.Shelf, loading: false })
        if (getShelfData.Shelf.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              getShelfData.Shelf[0].FullCount / this.state.countperpage,
            ),
          });
        }
      }
    }
  }

  onKeyEnter(e) {
    if (e.charCode === 13) {
      this.CallApi()
    }
  }

  UploadExcelFile = (e) => {
    console.log('hi')
    var files = e.target.files, f = files[0];
    const reader = new FileReader();
    if (files.length > 0) {
      this.fileUpload.current.value = null;
      this.setState({ ExcelUpload: [] })
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.setState({ ExcelUpload: data }, () => {
          console.log('ExcelUpload', this.state.ExcelUpload)
        })
      };
      reader.readAsBinaryString(f);
    }
  }

  showFileUpload() {
    this.fileUpload.current.click();
  }

  PrintUsers() {
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    console.log('Print Me', CurrentSelectedPage)
  }

  // PrintUsers(Base64) {
  //   const byteCharacters = atob(Base64);
  //   const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], { type: 'application/pdf' });
  //   const pdfUrl = URL.createObjectURL(blob);

  //   const printWindow = window.open(pdfUrl, '_blank', "height=900,width=800");
  //   printWindow.onload = () => {
  //     printWindow.print();
  //     URL.revokeObjectURL(pdfUrl); // Cleanup: Revoke the object URL after printing is done
  //   };
  //   this.UpdatePrinted();
  // }


  // printWindow() {
  //   const printableContent = document.getElementById('printablediv').innerHTML;
  //   const popupWindow = window.open('', '_blank', 'width=1000,height=800');
  //   popupWindow.document.open();
  //   popupWindow.document.write(`
  //   <html>
  //   <head>
  //     <title>Print</title>
  //     <style>
  //     @media print {
  //       .ReceiptContainer {
  //         margin-bottom: 20px;
  //         flex-direction: row;
  //         display: flex;
  //       }
  //       .CashoutReceiptTitle {
  //         font-weight: bold;
  //         font-size: 17px;
  //       }                
  //       @page {
  //         size: A4;
  //         margin-top: 0;
  //         margin-bottom: 0;
  //       }
  //       .pagebreak {
  //         clear: both;
  //         page-break-after: always;
  //     }
  //       .template { width: auto; left:0; top:0; }
      
  //       li { margin: 0 0 10px 20px !important;} 
  //       .modal-dialog { max-width: 100%; width: 100%;  }
  //       .modal-body {
  //         overflow: visible !important;
  //       }
  //       .non-printable { display: none; }
  //       .CssButtonP { display: none;}
  //       .CssButtonRed { display: none;}
  //       .printable { display: block; }
  //       .button { display: none; }
  //       .breaksss { page-break-after: always; }
  //       .SetHtml { margin-top: 50; }
  //     }
  //     </style>
  //   </head>
  //   <body>
  //     ${printableContent}
  //   </body>
  // </html>
  //   `);
  //   popupWindow.document.close();

  //   popupWindow.addEventListener('afterprint', function() {
  //     popupWindow.close();
  //   });

  //   popupWindow.print();

  // }

  async DeleteData(SelectedData, SelectedtPage) {
    const r = (window.confirm(`Confirm Delete Selected Data ?`));
    if (r === true) {
      const DeleteSelectedData = await DeleteDataFNC([SelectedData.ID], SelectedtPage)
      if (DeleteSelectedData.message === 'Deleted') {
        this.openNotification('Success Deleted Selected Data');
        this.handleClose();
      }
    } else {
      return null;
    }
  }

  AddProduct() {
    const ExcelAddData = this.state.ExcelUpload
    if (ExcelAddData.length > 0) {
      this.setState({ DisplayExcelConfirm: !this.state.DisplayExcelConfirm, OpenModalType: 'AddProduct' })
    } else {
      this.setState({ AddProductModal: !this.state.AddProductModal })}
  }

  async ExportExcelFile() {
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    let SelectedDatabase = ''
    let ExcelDisplayData = []
    if (CurrentSelectedPage === 'PM') {
      SelectedDatabase = 'ProductMaster'
    }
    if (CurrentSelectedPage === 'SM') {
      SelectedDatabase = 'ShelfMaster'
    }
    if (CurrentSelectedPage === 'PL') {
      SelectedDatabase = 'PriceLevel'
    }
    if (CurrentSelectedPage === 'SI') {
      SelectedDatabase = 'StockInformation'
    }
    const getExceldata = await GetExcelDataFNC(this.state.UserSearch, SelectedDatabase);
    if (getExceldata.message === 'Call success') {
      ExcelDisplayData = getExceldata.ReturnExcelData
      this.DownloadExcelFile(ExcelDisplayData);
    }
  }

  DownloadExcelFile(ExcelDisplayData) {
    const CurrentSelectedPage = this.state.CurrentSelectedPage
    let SelectedPage = ''
    if (CurrentSelectedPage === 'PM') {
      SelectedPage = 'Product'
    }
    if (CurrentSelectedPage === 'PL') {
      SelectedPage = 'PriceLevel'
    }
    if (CurrentSelectedPage === 'SI') {
      SelectedPage = 'StockInfo'
    }
    if (CurrentSelectedPage === 'SM') {
      SelectedPage = 'ShelfMaster'
    }
    ExportProductExcel(ExcelDisplayData, SelectedPage)
  }

  render() {
    return (
      <div fluid className={(this.state.ViewDetails === true) ? 'non-printable' : 'printable'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* <div style={{ width: '100%', marginBottom: 50}}>
          <Header/>
        </div> */}
        <Sidebar/>
        {this.state.AddProductModal && 
        <AddProductModal AddProductModal={this.state.AddProductModal} OpenModalType={this.state.OpenModalType} handleClose={this.handleClose} ProductDetails={this.state.ProductDetails}/>}
        {this.state.AddPriceLevelModal && 
        <PriceLevelModal AddPriceLevelModal={this.state.AddPriceLevelModal} OpenModalType={this.state.OpenModalType} PriceLevelDetails={this.state.PriceLevelDetails} handleClose={this.handleClose}/>}
        {this.state.StockInformationModal && 
        <StockInformationModal StockInformationModal={this.state.StockInformationModal} OpenModalType={this.state.OpenModalType} StockInfoDetails={this.state.StockInfoDetails} handleClose={this.handleClose}/>}
        {this.state.DisplayExcelConfirm && 
        <ImportExcelProduct DisplayExcelConfirm={this.state.DisplayExcelConfirm} handleClose={this.handleClose} OpenModalType={this.state.OpenModalType} displayExcelData={this.state.ExcelUpload} />
        }
        {this.state.ShelfModal && 
        <ShelfModal handleClose={this.handleClose} ShelfModal={this.state.ShelfModal} OpenModalType={this.state.OpenModalType} ShelfDetails={this.state.ShelfDetails}/>}
        <div className='DashboardBackground' style={{  fontSize: '13px', width: '100%', minHeight: '100vh' }}>
          <div  style={{ display: 'flex', paddingLeft: 10, justifyContent: 'space-betwseen' }}>
            <div className='MainPageDateContainer'>
              <h3 style={{ marginRight: 10, color: "white" }}>
                {this.state.notificationMessage === '' &&
                <div>{(this.state.CurrentSelectedPage === 'PM') ? 'Product Master' : (this.state.CurrentSelectedPage === 'PL') ? 'Price Level' : (this.state.CurrentSelectedPage === 'SI') ? 'Stock Information' : 'Shelf Master'  }</div>
                }
                {this.state.notificationMessage !== '' &&
              <div className='dcc' style={{ color: '#77B43F', fontSize: '16px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.notificationMessage}</div>
                }
              </h3>
            </div>
          </div>
          <div className="dcc" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 10, padding: 10, backgroundColor: 'rgba(225,225,225, 2)', borderRadius: 3 }}>
              <div style={{ width: '100%', borderBottom: '1px solid #333333', padding: 5, backgroundColor: '#333333', fontWeight: 'bold', color: 'white', borderRadius: 5 }}>Custom Search</div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', padding: 15}}>
                  <div className='dcc' style={{ width: 'calc(100%/2)', display: 'flex', flexDirection: 'row', marginRight: 10 }}>
                    <div style={{ width: 'calc(100% / 3)', fontWeight: 'bold'}}>Search:</div>
                    <div style={{ width: 'calc(100% / 1)'}}>
                      <Form.Control style={{ width: 'calc(100%)' }} onKeyPress={(e) => this.onKeyEnter(e)} value={this.state.UserSearch} onChange={(e) => this.setState({ UserSearch: e.target.value })} placeholder='Search'/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 15 }}>
                  {this.state.CurrentSelectedPage === 'PM' && <>
                    <label htmlFor="file-Upload" style={{ marginRight: 10 }}>
                      <img className='ExcelfileUpload' style={{ backgroundColor: (this.state.ExcelUpload.length > 0) ? 'green' : '' }} for="file-Upload" src={Xls} alt='Excel' />
                      <input ref={this.fileUpload} type='file' id="file-Upload" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                    </label>
                    <label style={{ marginRight: 10 }}>
                      <img className='ExcelfileUpload' src={PrintLogo} alt='Print' onClick={() => console.log('print')} />
                    </label>
                  </>}
                  <label style={{ marginRight: 10 }}>
                    <img className='ExcelfileUpload' src={ExportExcel} alt='Print' onClick={() => this.ExportExcelFile() } />
                  </label>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 13 }}>
                  <div className='POButton' style={{ backgroundColor: 'Orange' }} onClick={() => this.CallApi()}>Search</div>
                  {/* <div className="form-group"> */}
                  {this.state.CurrentSelectedPage === 'PM' && 
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.AddProduct()}>Add Product</div>
                  }
                  {this.state.CurrentSelectedPage === 'PL' && 
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.setState({ AddPriceLevelModal: !this.state.AddPriceLevelModal })}>Add PriceLevel</div>
                  }
                  {this.state.CurrentSelectedPage === 'SI' && 
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.setState({ StockInformationModal: !this.state.StockInformationModal })}>Add StockInformation</div>
                  }
                  {this.state.CurrentSelectedPage === 'SM' && 
                    <div className='POButton' style={{ backgroundColor: '#0CA3E1' }} onClick={() => this.setState({ ShelfModal: !this.state.ShelfModal })}>Add Shelf</div>
                  }
                </div>
              </div>
            </div>
            <div style={{ width: '98%', display: 'flex', flexDirection: 'row' }}>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'PM') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 130, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'PM') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'PM', page: 1, countperpage: 15, countpagemax: 1, countpagemin: 1 }, () => { this.CallApi(); })}>
                {'Product Master'}
              </div>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'PL') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 130, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'PL') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'PL', page: 1, countperpage: 15, countpagemax: 1, countpagemin: 1  }, () => { this.CallApi(); })}>
                {'Price Level'}
              </div>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'SI') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 130, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'SI') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'SI', page: 1, countperpage: 15, countpagemax: 1, countpagemin: 1 }, () => { this.CallApi(); })}>
                {'Stock Information'}
              </div>
              <div className='dcc'
                style={{ backgroundColor: (this.state.CurrentSelectedPage === 'SM') ? 'rgba(225,225,225, 2)' : '#333333', padding: 4, minWidth: 130, borderRadius: 5, cursor: 'pointer', color: (this.state.CurrentSelectedPage === 'SM') ? 'black' : 'white' }} onClick={() => this.setState({ CurrentSelectedPage: 'SM', page: 1, countperpage: 15, countpagemax: 1, countpagemin: 1  }, () => { this.CallApi(); })}>
                {'Shelf Master'}
              </div>
            </div>
            {/* Product Master*/}
            {this.state.CurrentSelectedPage === 'PM' && 
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>SKU Code</div>
                  {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Description</div> */}
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>SKUType</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Category</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Department</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>UOM</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Tax</div>
                  {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Createdby</div> */}
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>CreatedDate</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.PODataLists.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.SKUCode}</div>
                      {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Description}</div> */}
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.SKUType}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Category}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Department}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.UOM}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Tax}</div>
                      {/* <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Createdby}</div> */}
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.CreatedDate}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.EditProductClick(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteData(items, 'ProductMaster')}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }

            {this.state.CurrentSelectedPage === 'PL' && 
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Cost Price</div>
                  <div style={{ width: 'calc(100%)'}}>Avg Cost</div>
                  <div style={{ width: 'calc(100%)'}}>Selling Price</div>
                  <div style={{ width: 'calc(100%)'}}>Updated Date</div>
                  <div style={{ width: 'calc(100%)'}}>Created By</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.PODataLists.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)'}}>{items.CostPrice}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.AvgCost}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.SellingPrice}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.UpdatedDate}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.Createdby}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.PriceLevelEdit(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteData(items, 'PriceLevel')}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
            {this.state.CurrentSelectedPage === 'SI' && 
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '99%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Store Code</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Store Desc</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>Balance</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>L/PurchaseDate</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>L/DOModule</div>
                  <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100}}>ExpiredDate</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              {this.state.loading === true &&  <> <div style={{ minHeight: '50vh', maxHeight: '40vh', alignItems: 'center' }}><img src={loading} alt="Logo" className="dcc loadingimages" /></div> </>}
              {(this.state.PODataLists.length > 0 && this.state.loading === false) && 
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '99%', paddingTop: 10, paddingBottom: 10 }}>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreCode}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.StoreDesc}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.Balance}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.PurchaseDate}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.DoDate}</div>
                      <div style={{ width: 'calc(100%)', overflowWrap: 'break-word', minWidth: 100 }}>{items.ExpiredDate}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.StockInfoEdit(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteData(items, 'StockInformation')}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              }
              <div className='dcc' style={{ minWidth: '10vh', display: 'flex', flexDirection: 'row' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
            {this.state.CurrentSelectedPage === 'SM' && 
            //     <div className='ContainerShadow' style={{ width: '98%', display: 'flex', flexDirection: 'column', marginBottom: 40, padding: 10, minHeight: '60vh', maxHeight: '60vh', backgroundColor: 'rgba(225,225,225, 2)' }}>
            //       <div style={{ width: '100%', paddingTop: 6, paddingBottom: 6, backgroundColor: '#333333', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
            //         <div style={{ width: 'calc(100%)'}}>Shelf No</div>
            //         <div style={{ width: 'calc(100%)'}}>Balance</div>
            //         <div style={{ width: 'calc(100%)'}}>Store Code</div>
            //         <div style={{ width: 'calc(100%)'}}>Action</div>
            //       </div>
            //       <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: 'auto', overflowX: 'auto'}}>
            //         {this.state.loading === true && 
            //         <>
            //           <div className='dcc'><img src={loading} alt="Logo" className="dcc loadingimages" /></div>
            //         </>}
            //         {(this.state.PODataLists.length > 0 && this.state.loading === false) &&
            //   <>
            //     {this.state.PODataLists.map((items,index) => {
            //       return(
            //         <div key={index} style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey' }}>
            //           <div style={{ width: 'calc(100%)'}}>{items.ShelfNo}</div>
            //           <div style={{ width: 'calc(100%)'}}>{items.Balance}</div>
            //           <div style={{ width: 'calc(100%)'}}>{items.StoreCode}</div>
            //           <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
            //             <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ShelfDataEdit(items)}><FaEdit/></div>
            //             <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteData(items, 'ShelfMaster')}>
            //               {/* 🗑 */}
            //               <FaTrash/>
            //             </div>
            //           </div>
            //         </div>
            //       );
            //     })}
            //   </>
            //         }
            //         {(this.state.PODataLists.length === 0 && this.state.loading === false) && 
            // <div className='dcc' style={{ fontWeight: 'bold', padding: 20 }}>No Data</div>
            //         }
            //       </div>
            //       <div className='dcc' style={{ width: '100%', borderTop: '1px solid lightgrey', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
            //         {this.state.page > this.state.countpagemin ? (
            //           <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
            //             this.CallApi();
            //           })}><div className="button-30" role="button">{'<<'}</div></div>
            //         ) : (
            //           <div style={{ width: 100 }}/>
            //         )}
            //         <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
            //         {this.state.page < this.state.countpagemax ? (
            //           <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
            //             this.CallApi();
            //           })}><div className="button-30" role="button">{'>>'}</div></div>
            //         ) : (
            //           <div style={{ width: 100 }} />
            //         )}
            //       </div>
            //     </div>
            <div className='dcc' style={{ width: '98%', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225,225,225, 2)', padding: 10 , marginBottom: 50, borderRadius: 3 }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '98%', backgroundColor: '#333333', padding: 5, borderRadius: 5 }}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                  <div style={{ width: 'calc(100%)', minWidth: 100 }}>Shelf No</div>
                  <div style={{ width: 'calc(100%)'}}>Balance</div>
                  <div style={{ width: 'calc(100%)'}}>Store Code</div>
                  <div className='dcc' style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div>Action</div>
                    {/* <div style={{ padding: 5, borderRadius: 10, backgroundColor: 'white', cursor: 'pointer'}} onClick={() => this.AddNewDataLine() }>+</div> */}
                  </div>
                </div>
              </div>
              <div className='scrollhost' style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh', maxHeight: '40vh', width: '100%', alignItems: 'center', overflowX: 'auto' }}>
                {this.state.PODataLists.map((items, idd) => {
                  return(
                    <div className='dcc' key={idd} style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey', width: '98%', padding: 10 }}>
                      <div style={{ width: 'calc(100%)'}}>{items.ShelfNo}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.Balance}</div>
                      <div style={{ width: 'calc(100%)'}}>{items.StoreCode}</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row' }}>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.ShelfDataEdit(items)}><FaEdit/></div>
                        <div className='EditWord' style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DeleteData(items, 'ShelfMaster')}>
                          <FaTrash/>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div style={{ minWidth: '10vh' }}>
                {this.state.page > this.state.countpagemin ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page - 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'<<'}</div></div>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <div className='NextPreButton' style={{ cursor: 'pointer' }} onClick={() => this.setState({ page: this.state.page + 1 }, () => {
                    this.CallApi();
                  })}><div className="button-30" role="button">{'>>'}</div></div>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMaster);
