const language = {
  en: {
    HomePage: 'Home',
    Login: 'Login',
    LogOut: 'LogOut',
    cn: 'CHINESE',
    en: 'ENGLISH',
    LogoutConfirm: 'Are you Sure want to Logout ?',
  },
  cn: {
    HomePage: '主页',
    Login: '登入',
    LogOut: '登出',
    cn: '华语',
    en: '英语',
    LogoutConfirm: '确定要退出吗?',
  },
}
  
exports.language = language
  